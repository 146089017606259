import Echo from "laravel-echo";
window.Pusher = require("pusher-js");

export default new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: false,
  wsHost: process.env.VUE_APP_PUSHER_HOST,
  wsPort: process.env.VUE_APP_PUSHER_PORT,
  disableStats: true,
  encrypted: false,
  enabledTransports: ["ws", "wss"],
});
