import apiService from "@p/apiService";
import snackbars from "@p/events/snackbars";

export const Users = {
  namespaced: true,
  state: {
    records: [],
    recordsLoaded: false,
    record: {},
    recordLoaded: false,
    filters: null,
    metadata: {},
    tableHeaders: [
      { text: "Email", value: "email" },
      { text: "First Name", value: "firstName" },
      { text: "Last Name", value: "lastName" },
      { text: "Global Role", value: "globalRole" },
      { text: "Teams", value: "teams" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  },
  getters: {
    all: (state) => state.records,
    byId: (state) => (id) => state.records.find((record) => record.id === id),
    tableHeaders: (state) => state.tableHeaders,
    filters: (state) => state.filters,
    record: (state) => state.record,
    metadata: (state) => state.metadata,
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadRecords");
      dispatch("resetFilters");
    },
    loadRecords({ commit }, filters = {}) {
      apiService.callApi("user", "getAllUsers", filters).then((res) => {
        commit("SAVE_RECORDS", res.data);
        commit("SAVE_METADATA", res.meta);
      });
    },
    loadById({ commit }, uuid) {
      apiService.callApi("user", "getById", uuid).then((res) => {
        commit("SET_SELECTED", res.data);
      });
    },
    setSelected({ commit }, record) {
      commit("SET_SELECTED", record);
    },
    resetSelected({ commit }) {
      commit("RESET_SELECTED");
    },
    setFilter({ commit }, data) {
      commit("SET_FILTER", data);
    },
    resetFilter({ commit }, filter) {
      commit("RESET_FILTER", filter);
    },
    setFilters({ commit }, filters) {
      commit("SET_FILTERS", filters);
    },
    resetFilters({ commit }) {
      commit("RESET_FILTERS");
    },
    copyRecord({ commit }, record) {
      commit("ADD_RECORD", record);
    },
    update({ dispatch }, recordData) {
      apiService.callApi("user", "updateUser", recordData).then(() => {
        snackbars.$emit("snackbar", {
          message: "User updated.",
        });
        dispatch("loadById", recordData.uuid);
      });
    },
  },
  mutations: {
    SAVE_RECORDS(state, records) {
      state.records = records;
    },
    SAVE_METADATA(state, metadata) {
      state.metadata = metadata;
    },
    ADD_RECORD(state, record) {
      state.records.push(record);
    },
    SET_SELECTED(state, record) {
      state.record = record;
    },
    RESET_SELECTED(state) {
      state.record = {};
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    RESET_FILTER(state, { filter }) {
      state.filters[filter] = null;
    },
    RESET_FILTERS(state) {
      state.filters = {};
    },
    UPDATE_RECORD(state, recordData) {
      state.record = recordData;
    },
  },
  modules: {},
};
