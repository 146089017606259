const navigationTabs = [
  {
    tabText: "Orders",
    tabIcon: "fas fa-shopping-cart",
    tabHref: "/TEAM_CODE/orders",
    requiredPermission: "",
  },
  {
    tabText: "Products",
    tabIcon: "fas fa-tags",
    tabHref: "/TEAM_CODE/products",
    requiredPermission: "",
  },
  // {
  //     "tabText": "Reports",
  //     "tabIcon": "fas fa-chart-bar",
  //     "tabHref": "/reports",
  //     "requiredPermission": ""
  // },
  {
    tabText: "Settings",
    tabIcon: "fas fa-cog",
    tabHref: "/TEAM_CODE/settings",
    requiredPermission: "",
  },
  {
    tabText: "Admin",
    tabIcon: "fas fa-user-shield",
    tabHref: "/admin",
    requiredPermission: "viewAdmin",
  },
];

export default navigationTabs;
