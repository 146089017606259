import Vue from "vue";
import VueRouter from "vue-router";

import OrdersRoutes from "@orders/routes";
import ProductsRoutes from "@products/routes";
import SettingsRoutes from "@settings/routes";
import AdminRoutes from "@admin/routes";
import ProfileRoutes from "@users/routes/profile";
import ReportsRoutes from "./reports";

import NavigationTabs from "./navigation/tabs";
import CreateMenuItems from "./navigation/items/createMenu";
import ProfileMenuItems from "./navigation/items/profileMenu";
import OrdersNavItems from "./navigation/items/orders";
import ProductsNavItems from "./navigation/items/products";
import ReportsNavItems from "./navigation/items/reports";
import SettingsNavItems from "./navigation/items/settings";
import AdminNavItems from "./navigation/items/admin";

import AdminSidebarItems from "./sidebar/admin";
import ProfileSidebarItems from "./sidebar/profile";
import TeamSettingsSidebarItems from "./sidebar/teamSettings";

import Store from "@s";

const Testing = () => import("@v/Testing");
const Invitation = () => import("@v/Invitation");
const NoAssignedTeam = () => import("@v/NoAssignedTeam");
const Unauthenticated = () => import("@v/Unauthenticated");
const AuthoriseApp = () => import("@v/oauth/AuthoriseApp");
const Error401 = () => import("@/components/Error401.vue");
const Error404 = () => import("@/components/Error404.vue");

Vue.use(VueRouter);

const routes = [
  // Base URL

  {
    name: "Testing",
    path: "/test",
    component: Testing,
  },

  {
    name: "oauth",
    path: "/authorise-oauth",
    component: AuthoriseApp,
  },

  {
    name: "invite",
    path: "/invite/:token",
    component: Invitation,
  },

  // ERRORS

  {
    path: "/unauthenticated",
    name: "Unauthenticated",
    component: Unauthenticated,
  },

  {
    path: "/no-team-assigned",
    name: "NoAssignedTeam",
    component: NoAssignedTeam,
  },

  {
    path: "/not-found",
    name: "error404",
    component: Error404,
  },

  {
    path: "/not-authorised",
    name: "error401",
    component: Error401,
  },

  ...AdminRoutes,
  ...ProfileRoutes,

  {
    name: "base",
    path: "/:team/",
    redirect: { name: "orders" },
    component: {
      render(component) {
        return component("router-view");
      },
    },
    children: [
      ...OrdersRoutes,
      ...ProductsRoutes,
      ...ReportsRoutes,
      ...SettingsRoutes,
    ],
  },

  // Keep this one at the bottom.
  {
    path: "*",
    name: "fallback",
    component: Error404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export const navigationTabs = NavigationTabs;
export const navigationItems = {
  createRoutes: CreateMenuItems,
  Orders: OrdersNavItems,
  Products: ProductsNavItems,
  Reports: ReportsNavItems,
  Settings: SettingsNavItems,
  Admin: AdminNavItems,
  Profile: ProfileMenuItems,
};

export const adminSidebarItems = AdminSidebarItems;
export const profileSidebarItems = ProfileSidebarItems;
export const teamSettingsSidebarItems = TeamSettingsSidebarItems;

router.getNavbarTabs = () => navigationTabs;
router.getNavbarItems = () => navigationItems;
router.getAdminSidebarItems = () => adminSidebarItems;
router.getProfileSidebarItems = () => profileSidebarItems;
router.getTeamSettingsSidebarItems = () => teamSettingsSidebarItems;

function awaitUser(to, from, next) {
  if (to.path.startsWith("/invite") || to.path.startsWith("/profile")) {
    handleRouting(to, from, next);
  }

  let user = Store.getters["user/user"];

  if (JSON.stringify(user) === "{}") {
    setTimeout(() => {
      awaitUser(to, from, next);
    }, 100);
  } else {
    handleRouting(to, from, next, user);
  }
}

function handleRouting(to, from, next, user) {
  if (to.path.startsWith("/admin")) {
    if (user.hasRole(["admin", "staff"])) {
      next();
    } else {
      console.log(user.roles, user.hasRole(["admin", "staff"]));
      next({ name: "error404" });
    }
  }

  if (to.meta && to.meta.requiredPermission) {
    if (!user.hasPermissionTo(to.meta.requiredPermission)) {
      next({ name: "error404" });
    } else {
      next();
    }
  } else {
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.title);
    const previousNearestWithMeta = from.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags);

    if (nearestWithTitle) {
      if (
        process.env.VUE_APP_ENV !== "prod" &&
        process.env.VUE_APP_ENV !== "production"
      ) {
        document.title = "PT STAGING | " + nearestWithTitle.meta.title;
      } else {
        document.title = "Print Trail | " + nearestWithTitle.meta.title;
      }
    } else if (previousNearestWithMeta) {
      if (
        process.env.VUE_APP_ENV !== "prod" &&
        process.env.VUE_APP_ENV !== "production"
      ) {
        document.title = "PT STAGING | " + previousNearestWithMeta.meta.title;
      } else {
        document.title = "Print Trail | " + previousNearestWithMeta.meta.title;
      }
    }

    next();
  }
}

router.beforeEach((to, from, next) => {
  awaitUser(to, from, next);
});

export default router;
