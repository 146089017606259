import apiService from "@p/apiService";
import snackbars from "@p/events/snackbars";
import orderEvents from "@orders/events";

export const Basket = {
  namespaced: true,
  state: {
    items: [],
    address: {},
    addressIsInvalid: true,
    orderData: null,
    poNumber: null,
    taxCustoms: {},

    tableHeaders: [
      { text: "Product Title", value: "product.name" },
      { text: "SKU", value: "product.sku" },
      { text: "Quantity", value: "quantity" },
      { text: "", value: "actions", sortable: false },
    ],
  },
  getters: {
    items: state => state.items,
    tableHeaders: state => state.tableHeaders,
    address: state => state.address,
    orderData: state => state.orderData,
    addressIsInvalid: state => state.addressIsInvalid,
    poNumber: state => state.poNumber,
    taxCustoms: state => state.taxCustoms,
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("resetStore");
    },
    addItem({ commit }, item) {
      commit("ADD_ITEM", item);
    },
    removeItem({ commit }, item) {
      commit("REMOVE_ITEM", item);
    },
    increaseQuantity({commit}, item) {
      commit("INCREASE_ITEM_QUANTITY", item);
    },
    decreaseQuantity({commit}, item) {
      commit("DECREASE_ITEM_QUANTITY", item);
    },
    setAddress({ commit }, addressData) {
      commit("SET_ADDRESS", addressData);
    },
    setTaxCustoms({ commit }, taxCustomsData) {
      commit("SET_TAX_CUSTOMS", taxCustomsData);
    },
    resetStore({ commit }) {
      commit("RESET_STORE");
    },
    addressIsInvalid({ commit }, invalid) {
      commit("SET_ADDRESS_INVALID", invalid);
    },

    setTestAddressData({ commit }) {
      commit("SET_TEST_ADDRESS");
    },

    createOrder({ commit }) {
      let data = {
        products: Basket.state.items,
        shipping: Basket.state.address,
        poNumber: Basket.state.poNumber,
        phone: Basket.state.address.phone,
        taxCustoms: Basket.state.taxCustoms
      };

      apiService
        .callApi("orders", "createOrder", data)
        .then((response) => {
          commit("SET_ORDER_DATA", response.data);
          snackbars.$emit("snackbar", {
            message: "Draft order created",
          });
        })
        .catch((err) => {
          let errors = err.response.body.errors ?? {};
          orderEvents.$emit(
            "order-create-failed",
            Object.values(errors).flat()
          );
        });
    },
    updateOrder({ commit }) {
      let taxCustoms = Object.values(Basket.state.orderData.taxCustoms).filter(n => n).length ? 
                              Basket.state.orderData.taxCustoms :
                              Basket.state.taxCustoms;

      let data = {
        products: Basket.state.items,
        shipping: Basket.state.address,
        poNumber: Basket.state.orderData.poNumber,
        phone: Basket.state.address.phone,
        taxCustoms: taxCustoms
      };

      let uuid = Basket.state.orderData.uuid;

      commit("RESET_ORDER_DATA");

      apiService
        .callApi("orders", "updateOrder", uuid, data)
        .then((response) => {
          commit("SET_ORDER_DATA", response.data);
        });
    },
    loadOrderForEditing({ commit }, uuid) {
      commit("RESET_STORE");
      apiService.callApi("orders", "getOrder", uuid).then((response) => {
        let data = response.data;
        for (let product of data.orderLines) {
          commit("ADD_ITEM", product);
        }
        data.products = data.orderLines;
        commit("SET_ADDRESS", data.shipping);
        commit("SET_ORDER_DATA", data);
        commit("SET_PO_NUMBER", data.poNumber);
        commit("SET_TAX_CUSTOMS", data.taxCustoms);
      });
    },
    setPoNumber({commit}, poNumber) {
      commit("SET_PO_NUMBER", poNumber);
    }
  },
  mutations: {
    SAVE_RECORDS(state, records) {
      state.items = records;
    },
    ADD_ITEM(state, item) {
      let uuids = state.items.map(basketItem => basketItem.product.uuid);
      let indexOfItem = uuids.indexOf(item.product.uuid);

      if (indexOfItem > -1) {
        let items = Object.assign([], state.items);
        items[indexOfItem].quantity += item.quantity;
        state.items = items;
      } else {
        let items = Object.assign([], state.items);
        items.push(item);
        state.items = items;
      }
    },
    REMOVE_ITEM(state, item) {
      let uuids = state.items.map(basketItem => basketItem.product.uuid);
      let indexOfItem = uuids.indexOf(item.product.uuid);
      if (indexOfItem > -1) {
        let items = Object.assign([], state.items);
        items.splice(indexOfItem, 1);
        state.items = items;
      }
    },
    INCREASE_ITEM_QUANTITY(state, item) {
      let uuids = state.items.map(basketItem => basketItem.product.uuid);
      let indexOfItem = uuids.indexOf(item.product.uuid);
      if (indexOfItem > -1) {
        let items = Object.assign([], state.items);
        ++items[indexOfItem].quantity;
        state.items = items;
      }
    },
    DECREASE_ITEM_QUANTITY(state, item) {
      let uuids = state.items.map(basketItem => basketItem.product.uuid);
      let indexOfItem = uuids.indexOf(item.product.uuid);
      if (indexOfItem > -1) {
        let items = Object.assign([], state.items);
        --items[indexOfItem].quantity;
        state.items = items;
      }
    },
    SET_ADDRESS(state, addressData) {
      state.address = addressData;
    },
    SET_TAX_CUSTOMS(state, taxCustomsData) {
      state.taxCustoms = taxCustomsData;
    },
    RESET_STORE(state) {
      orderEvents.$emit('set-create-step', 1);
      state.poNumber = null;
      state.items = [];
      state.orderData = null;
      state.addressIsInvalid = true;
      state.address = {
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        phone: "",
        country: {},
        postcode: "",
        address1: "",
        address2: "",
        city: "",
        state: {},
        note: "",
      };
    },
    SET_ORDER_DATA(state, data) {
      state.orderData = data;
    },
    RESET_ORDER_DATA(state) {
      state.orderData = null;
    },
    SET_ADDRESS_INVALID(state, invalid) {
      state.addressIsInvalid = invalid;
    },
    SET_TEST_ADDRESS(state) {
      state.address = {
        firstName: "Consil",
        lastName: "Testuser",
        companyName: "Consilience",
        email: "testuser@consil.co.uk",
        phone: "+447123123123",
        country: {
          code: "GB"
        },
        postcode: "NE26 2HU",
        address1: "Unit 5, First Floor",
        address2: "305 Whitley Road",
        city: "Whitley Bay",
        state: {
          name: "Tyne & Wear"
        },
        note: "None",
      };
    },
    SET_PO_NUMBER(state, poNumber) {
      state.poNumber = poNumber;
    }
  },
  modules: {},
};
