const ordersNavItems = [
  {
    text: "All Orders",
    route: "/TEAM_CODE/orders/",
    icon: "fas fa-shopping-cart",
    requiredPermission: "",
  },
  // {
  //     "text": "Fulfilments",
  //     "route": "/orders/fulfilments",
  //     "icon": "fas fa-cube",
  //     "requiredPermission": "viewFulfilments"
  // },
  {
    text: "Create Order",
    route: "/TEAM_CODE/orders/create",
    icon: "fas fa-plus",
    requiredPermission: "createOrders",
  },
  {
    text: "Import Orders",
    route: "/TEAM_CODE/orders/import",
    icon: "fas fa-cloud-upload-alt",
    requiredPermission: "createOrders",
  },
];

export default ordersNavItems;
