import apiService from "@p/apiService";

export const Countries = {
  namespaced: true,
  state: {
    records: [],
    recordsLoading: true,
  },
  getters: {
    all: (state) => {
      return state.records;
    },
    byCode: (state) => (code) => {
      return state.records.find((record) => record.code === code);
    },
    loadStatus: (state) => {
      return state.recordsLoading;
    },
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadRecords");
    },
    loadRecords({ commit }) {
      apiService.callApi("general", "getCountries").then((response) => {
        commit("SAVE_RECORDS", response.data);
      });
    },
  },
  mutations: {
    SAVE_RECORDS(state, records) {
      if (records.length > 0) {
        state.records = records.map((country) => {
          if (country.name.startsWith("United Kingdom")) {
            country.name = "United Kingdom";
          }

          return country;
        });
      }
      state.recordsLoading = false;
    },
  },
};
