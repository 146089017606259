import Oidc from "oidc-client";
import { sha256 } from "js-sha256";
import authEvents from "@p/events/auth";

let client = new Oidc.UserManager({
  userStore: new Oidc.WebStorageStateStore(new Oidc.InMemoryWebStorage()),
  authority: `${process.env.VUE_APP_KEYCLOAK_BASE_URL}/${process.env.VUE_APP_KEYCLOAK_REALM}`,
  client_id: `${process.env.VUE_APP_KEYCLOAK_CLIENT}`,
  redirect_uri: window.location.origin + "/callback.html",
  post_logout_redirect_uri: window.location.href,
  response_type: "code",
  response_mode: "query",
  scope: "openid profile offline_access",
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
});

export default class AuthenticationService {
  // Attempt authorisation with KeyCloak "behind the scenes".
  signInSilent() {
    return client.signinSilent();
  }

  // Only call this to renew the token manually. (Used by listeners to renew already-expired tokens).
  renewToken() {
    return new Promise((resolve, reject) => {
      client
        .signinSilent()
        .then((user) => {
          if (user) {
            return resolve(user);
          }

          return reject("Not authenticated.");
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  // Get the profile of the logged in user.
  getUser() {
    return new Promise((resolve, reject) => {
      client
        .getUser()
        .then((user) => {
          if (user) {
            return resolve(user);
          }

          return reject("Not authenticated.");
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  // Check if the user is logged in.
  getSignedIn() {
    return new Promise((resolve, reject) => {
      client
        .getUser()
        .then((user) => {
          if (user) {
            return resolve(true);
          }

          return reject("Not authenticated.");
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  // Redirect of the current window to the authorization endpoint.
  signIn() {
    return client.signinRedirect();
  }

  // Redirect of the current window to the end session endpoint.
  signOut() {
    return client.signoutRedirect();
  }

  // Get the profile of the user logged in
  getProfile() {
    return new Promise((resolve, reject) => {
      client
        .getUser()
        .then((user) => {
          if (user) {
            return resolve(user.profile);
          }

          return reject("Not authenticated.");
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  // Get the token id
  getIdToken() {
    return new Promise((resolve, reject) => {
      client
        .getUser()
        .then((user) => {
          if (user) {
            return resolve(user.id_token);
          }

          return reject("Not authenticated");
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  // Get the session state
  getSessionState() {
    return new Promise((resolve, reject) => {
      client
        .getUser()
        .then((user) => {
          if (user) {
            return resolve(user.session_state);
          }

          return reject("Not authenticated");
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  // Get the access token of the logged in user
  getAcessToken() {
    return new Promise((resolve, reject) => {
      client
        .getUser()
        .then((user) => {
          if (user) {
            return resolve(user.access_token);
          }

          return reject("Not authenticated");
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  // Gets the scopes of the logged in user.
  getScopes() {
    return new Promise((resolve, reject) => {
      client
        .getUser()
        .then((user) => {
          if (user) {
            return resolve(user.scopes);
          }

          return reject("Not authenticated");
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  // Get roles of the logged in user.
  getRoles() {
    return new Promise((resolve, reject) => {
      client
        .getUser()
        .then((user) => {
          if (user) {
            let access_token = this.parseJwt(user.access_token);
            let roles = access_token.realm_access.roles;
            authEvents.$emit("keycloak-roles", roles);
            return resolve(roles);
          }

          return reject("Not authenticated");
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  setUpEventHook() {
    let self = this;
    client.events.addAccessTokenExpired(function() {
      self.renewToken().catch(() => {
        self.signIn();
      });
    });
  }

  generateRandom() {
    const guidHolder = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx";
    const hex = "0123456789abcdef";
    let r = 0;
    let guidResponse = "";

    for (var i = 0; i < guidHolder.length; i++) {
      if (guidHolder[i] !== "-" && guidHolder[i] !== "4") {
        // each x and y needs to be random
        r = (Math.random() * 16) | 0;
      }

      if (guidHolder[i] === "x") {
        guidResponse += hex[r];
      } else if (guidHolder[i] === "y") {
        // clock-seq-and-reserved first hex is filtered and remaining hex values are random
        r &= 0x3; // bit and with 0011 to set pos 2 to zero ?0??
        r |= 0x8; // set pos 3 to 1 as 1???
        guidResponse += hex[r];
      } else {
        guidResponse += guidHolder[i];
      }
    }
    return guidResponse;
  }

  getRegistrationUrl() {
    let codeVerifier = this.generateRandom();
    let url =
      client.settings.authority +
      "/protocol/openid-connect/registrations" +
      "?client_id=" +
      encodeURIComponent(client.settings.client_id) +
      "&redirect_uri=" +
      encodeURIComponent(client.settings.redirect_uri) +
      "&scope=" +
      encodeURIComponent(client.settings.scope) +
      "&state=" +
      encodeURIComponent(this.generateRandom()) +
      "&nonce=" +
      encodeURIComponent(this.generateRandom()) +
      "&response_mode=" +
      encodeURIComponent("fragment") +
      "&response_type=" +
      encodeURIComponent(client.settings.response_type) +
      "&code_verifier=" +
      encodeURIComponent(codeVerifier) +
      "&code_challenge=" +
      encodeURIComponent(sha256(codeVerifier)) +
      "&code_challenge_method=" +
      encodeURIComponent("S256");

    return url;
  }

  parseJwt(token) {
    let base64Payload = token.split(".")[1];
    let payload = Buffer.from(base64Payload, "base64");
    return JSON.parse(payload.toString());
  }
}
