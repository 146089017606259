export const Suppliers = {
  namespaced: true,
  state: {
    records: [],
    recordsLoaded: false,

    record: {},
    recordLoaded: false,

    filters: null,

    tableHeaders: [
      { text: "Code", value: "code" },
      { text: "Supplier Code", value: "supplierCode" },
      { text: "External ID", value: "externalId" },
      { text: "External Ref.", value: "externalReference" },
      { text: "PoD", value: "pod" },
      { text: "Inventory", value: "warehousedInventory" },
      { text: "API", value: "api" },
      { text: "Lat", value: "lat" },
      { text: "Long", value: "lng" },
      { text: "Label", value: "label" },
      { text: "Address 1", value: "address1" },
      { text: "Address 2", value: "address2" },
      { text: "City", value: "city" },
      { text: "State", value: "state" },
      { text: "Post Code", value: "postcode" },
      { text: "Country", value: "country" },
      { text: "Country Code", value: "countryCode" },
      { text: "Status", value: "status" },
      { text: "Dimensions Units", value: "unitsDimensions" },
      { text: "Weight Units", value: "unitsWeight" },
      { text: "Currency", value: "currency" },
    ],
  },
  getters: {
    all: (state) => {
      return state.records;
    },
    byId: (state) => (id) => {
      return state.records.find((record) => record.id === id);
    },
    tableHeaders: (state) => {
      return state.tableHeaders;
    },
    filters: (state) => {
      return state.filters;
    },
    record: (state) => {
      return state.record;
    },
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadRecords");
      dispatch("resetFilters");
    },
    loadRecords({ commit }) {
      // let suppliers = apiService.suppliers.getAll();
      let suppliers = [];
      commit("SAVE_RECORDS", suppliers);
    },
    setSelected({ commit }, record) {
      commit("SET_SELECTED", record);
    },
    resetSelected({ commit }) {
      commit("RESET_SELECTED");
    },
    setFilter({ commit }, data) {
      commit("SET_FILTER", data);
    },
    resetFilter({ commit }, filter) {
      commit("RESET_FILTER", filter);
    },
    setFilters({ commit }, filters) {
      commit("SET_FILTERS", filters);
    },
    resetFilters({ commit }) {
      commit("RESET_FILTERS");
    },
    copyRecord({ commit }, record) {
      commit("ADD_RECORD", record);
    },
    update({ commit }, recordData) {
      commit("UPDATE_RECORD", recordData);
    },
  },
  mutations: {
    SAVE_RECORDS(state, records) {
      state.records = records;
    },
    ADD_RECORD(state, record) {
      state.records.push(record);
    },
    SET_SELECTED(state, record) {
      state.record = record;
    },
    RESET_SELECTED(state) {
      state.record = {};
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    RESET_FILTER(state, { filter }) {
      state.filters[filter] = null;
    },
    RESET_FILTERS(state) {
      state.filters = {};
    },
    UPDATE_RECORD(state, recordData) {
      // TODO: Implement API action for updating suppliers.
      let suppliers = state.records;

      const supplierId = parseInt(recordData[0]);
      const newSupplierData = recordData[1];

      let ids = suppliers.map((supplier) => supplier.id);
      let index = ids.indexOf(supplierId);

      let dataAsArrays = Object.entries(newSupplierData);
      for (const [key, value] of dataAsArrays) {
        suppliers[index][key] = value;
        // TODO: Send suppliers[index] to the API for updates in the backend.
      }

      state.records = suppliers;
    },
  },
  modules: {},
};
