<template>
  <div class="muli-font container mx-auto fill-height text-center">
    <div class="container mx-auto fill-height text-center">
      <p class="text-center" style="width: 100%">
        You are logged out. <a href="#" @click="login">Log in here</a>.
      </p>
    </div>
  </div>
</template>
<script>
const AuthStore = () => import("@s/modules/auth");

export default {
  methods: {
    login() {
      AuthStore.dispatch("login");
    },
  },
};
</script>

<style scoped>
</style>
