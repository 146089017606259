import { Dimensions } from "./dimensions";

export class Product {
  properties = {
    uuid: String,
    name: String,
    sku: String,
    ptSku: String,
    isbn: String,
    type: String,
    status: String,
    podEnabled: String,
    warehouseEnabled: String,
    isActive: String,
    description: String,
    // "customs": String,
    totalStock: String,
    details: String,
    errorMessages: String,
    dimensions: Dimensions,
    prices: String,
    images: String,
    gtin: String,
    gtinType: String,
    isbn10: String,
    isbn13: String,
    ean: String,
    upc: String,
    hsTariffCode: String,
    customsDescription: String,
    countryOfManufacture: String,
    costs: String,
  };

  statusColours = {
    new: "primary",
    pending: "warning",
    complete: "success",
    cancelled: "error",
    draft: "secondary",
  };

  hiddenTypeFields = {
    book: [
      "type",
      "podEnabled",
      "warehouseEnabled",
      "dimensions.*.measurement",
      "dimensions.width.unit",
      "dimensions.length.unit",
      "dimensions.depth.unit",
      "dimensions.weight.unit",
      "prices.*.currencyCode",
      "prices.*.amountMinorUnits",
      "costs.*.currencyCode",
      "costs.*.amountMinorUnits",
      "hsTariffCode",
      "customsDescription",
      "countryOfManufacture",
    ],
  };

  constructor(data) {
    let product = this;
    for (const [key, dataType] of Object.entries(this.properties)) {
      switch (dataType) {
        case String:
          product[key] = data[key] ?? null;
          break;
        case Dimensions:
          product[key] = data[key] ? new Dimensions(data[key]) : null;
          break;
        default:
          product[key] = data[key] ?? null;
          break;
      }
    }
  }

  getStatusColour() {
    return this.statusColours[this.status] ?? "red";
  }
}

export const Fields = {
  book: [
    [
      {
        label: "What is the product's name?",
        key: "",
        formType: "subheading",
      },
      {
        label: "Product Name",
        key: "name",
        formType: "text",
      },
    ],
    [
      {
        label: "Please add a SKU or other product ID",
        key: "",
        formType: "subheading",
      },
      {
        label: "SKU",
        key: "sku",
        formType: "text",
      },
      {
        label: "ISBN10",
        key: "details.isbn10",
        formType: "text",
      },
      {
        label: "ISBN13",
        key: "details.isbn13",
        formType: "text",
      },
    ],
  ],
  other: [
    [
      {
        label: "What is the product's name?",
        key: "",
        formType: "subheading",
      },
      {
        label: "Product Name",
        key: "name",
        formType: "text",
      },
    ],
    [
      {
        label: "Please add a SKU or other product ID",
        key: "",
        formType: "subheading",
      },
      {
        label: "SKU",
        key: "sku",
        formType: "text",
      },
      {
        label: "UPC",
        key: "details.upc",
        formType: "text",
      },
      {
        label: "EAN",
        key: "details.ean",
        formType: "text",
      },
    ],
  ],
};

export const filters = [
  "filterType",
  "filterStatus",
  "filterSearch",
  "filterDescription",
  "filterIsbn",
  "filterWarehouseEnabled",
  "filterPodEnabled",
  "page",
  "perPage",
  "sort",
  "filterHasInventory",
  "filterSupplierLocationCode",
];
