import apiService from "@p/apiService";

const PTApi = require("print_trail");

export const InvitationApi = new PTApi.InvitationApi();

export default {
  getInvitations() {
    let opts = apiService.getRequestOpts();
    return InvitationApi.invitationIndex(opts);
  },

  getCreateValidationRules() {
    let opts = apiService.getRequestOpts();
    return InvitationApi.invitationCreate(opts);
  },

  createInvitation(data) {
    let opts = apiService.getRequestOpts();
    opts.createInvitationRequestBody = PTApi.CreateInvitationRequestBody.constructFromObject(
      data
    );
    return InvitationApi.invitationStore(opts);
  },

  invitationAction(token) {
    return InvitationApi.invitationAction(token);
  },

  getUserInvitations() {
    let opts = apiService.getRequestOpts();
    return InvitationApi.invitationIndexUser(opts);
  },

  acceptInvitation(invitationUuid) {
    return InvitationApi.invitationAccept(invitationUuid);
  },

  rejectInvitation(invitationUuid) {
    return InvitationApi.invitationReject(invitationUuid);
  },

  cancelInvitation(invitationUuid) {
    let opts = apiService.getRequestOpts();
    return InvitationApi.invitationCancel(invitationUuid, opts);
  },
};
