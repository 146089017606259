import { DateTime } from "luxon";
import { ShippingData } from "./shippingData";
import { Product } from "./product";

export class Order {
  properties = {
    uuid: String,
    poNumber: String,
    phone: String,
    clonedFrom: String,
    externalOrderNumber: String,
    ptOrderNumber: String,
    source: String,
    status: String,
    operations: String,
    fulfillableStatus: String,
    fulfillableFrom: String,
    createdAt: String,
    totalItem: String,
    shipping: ShippingData,
    channel: String,
    cancellable: String,
    fulfilments: Array,
    orderLines: String,
    errors: String,
    taxCustoms: String,
    orderSource: String,
    orderStatus: String
  };

  statusColours = {
    draft: "grey",
    queued: "blue",
    holding: "orange",
    processing: "blue",
    complete: "green",
    cancelled: "grey",
    "cancel-pending": "grey",
    "back-order": "orange",
    returned: "purple",
    invalid: "red",
    error: "red",
    partial: "light blue",
    failed: "red",
  };

  constructor(data) {
    let order = this;
    for (const [key, dataType] of Object.entries(this.properties)) {
      switch (dataType) {
        case String:
          order[key] = data[key] ?? null;
          break;
        case ShippingData:
          order[key] = data[key] ? new ShippingData(data[key]) : null;
          break;
        case [Product]:
          order[key] = data[key].map((product) => new Product(product));
          break;
        case Array:
          order[key] = data[key] ?? [];
          break;
        default:
          order[key] = data[key] ?? null;
          break;
      }
    }
  }

  getStatusColour() {
    return this.statusColours[this.orderStatus.code] ?? "red";
  }
}

export const filters = [
  "filterSearch",
  "filterStatus",
  "filterDateFrom",
  "filterDateTo",
  "filterCountryCode",
  "page",
  "perPage",
];
