<template>
  <v-tooltip top :disabled="noTooltip">
    <template v-slot:activator="{ on }">
      <span v-on="on" :class="classes">{{ dateText }}</span>
    </template>
    <span>{{ date }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    date: {
      default: null,
    },
    showTime: {
      default: false,
      type: Boolean,
    },
    noTooltip: {
      default: false,
      type: Boolean,
    },
    color: {
      default: null,
    },
  },
  computed: {
    dateText() {
      if (this.showTime) {
        return this.$parseDate(this.date, "d MMM yyyy H:mm");
      }
      return this.$parseDate(this.date);
    },
    classes() {
      if (this.color) {
        return `${this.color}--text`;
      }
      return "";
    },
  },
};
</script>

<style scoped></style>
