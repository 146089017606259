import Vue from "vue";
import IdleJs from "@p/idleJs";
import echo from "@p/websockets";
import VueXModularStore from "@s";
import AuthModule from "@s/modules/auth";
import { DateTime, Interval } from "luxon";
import colors from "vuetify/lib/util/colors";
import snackbarEvents from "@p/events/snackbars";

import echoEvents from "@p/events/echo";
import AlertBus from "@p/events/alerts";
import authEvents from "@p/events/auth";
import mediaEvents from "@p/events/media";
import errorEvents from "@p/events/errors";
import imageEvents from "@p/events/images";
import importEvents from "@p/events/import";
import searchEvents from "@p/events/search";
import drawerEvents from "@p/events/drawers";
import inviteEvents from "@p/events/invites";
import channelEvents from "@p/events/channels";
import globalAlerts from "@p/events/globalAlerts";

import userEvents from "@users/events";
import teamEvents from "@teams/events";
import orderEvents from "@orders/events";
import productEvents from "@products/events";
import podEvents from "@products/events/pod";
import basketEvents from "@orders/events/basket";
import supplierEvents from "@products/events/suppliers";
import fulfilmentEvents from "@orders/events/fulfilments";

window.heic2any = require("heic2any");
window.Jimp = import('jimp/browser/lib/jimp');

Vue.prototype.$echo = echo;
Vue.prototype.$idleJs = IdleJs;
Vue.prototype.$colours = colors;
Vue.prototype.$alertBus = AlertBus;
Vue.prototype.$snackbars = snackbarEvents;
Vue.prototype.$globalAlerts = globalAlerts;

Vue.prototype.$podEvents = podEvents;
Vue.prototype.$echoEvents = echoEvents;
Vue.prototype.$authEvents = authEvents;
Vue.prototype.$userEvents = userEvents;
Vue.prototype.$teamEvents = teamEvents;
Vue.prototype.$mediaEvents = mediaEvents;
Vue.prototype.$errorEvents = errorEvents;
Vue.prototype.$imageEvents = imageEvents;
Vue.prototype.$orderEvents = orderEvents;
Vue.prototype.$basketEvents = basketEvents;
Vue.prototype.$drawerEvents = drawerEvents;
Vue.prototype.$importEvents = importEvents;
Vue.prototype.$inviteEvents = inviteEvents;
Vue.prototype.$searchEvents = searchEvents;
Vue.prototype.$channelEvents = channelEvents;
Vue.prototype.$productEvents = productEvents;
Vue.prototype.$supplierEvents = supplierEvents;
Vue.prototype.$fulfilmentEvents = fulfilmentEvents;

Vue.prototype.$local = () => process.env.VUE_APP_ENV === "local";
Vue.prototype.$staging = () => process.env.VUE_APP_ENV === "staging";
Vue.prototype.$prod = () =>
  process.env.VUE_APP_ENV === "prod" ||
  process.env.VUE_APP_ENV === "production";
Vue.prototype.$help = () =>
  window.open("https://docs.printtrail.com/", "_blank");
Vue.prototype.$isTeamSelected = () =>
  !!VueXModularStore.getters["teams/teamSelect/activeTeam"];
Vue.prototype.$currentTeam = () =>
  VueXModularStore.getters["teams/teamSelect/activeTeam"];
Vue.prototype.$isAuthenticated = () => AuthModule.getters.isUserAuthenticated;
Vue.prototype.$registrationUrl = () => AuthModule.getters["registrationUrl"];
Vue.prototype.$forceLogout = () => AuthModule.dispatch("logout");
Vue.prototype.$login = () => AuthModule.dispatch("login");

// Current user
const $currentUser = (Vue.prototype.$currentUser = () =>
  VueXModularStore.getters["user/user"]);

// Roles/permissions
Vue.prototype.$can = (action) => {
  let user = $currentUser();
  if (user.permissions) {
    return user.hasPermissionTo(action);
  }
  return false;
};
Vue.prototype.$hasRole = (role) => $currentUser().roles.includes(role);

// String manipulation
String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.snakeToCamel = function() {
  return this.replace(/([-_][a-z])/g, (group) =>
    group
      .toUpperCase()
      .replace("-", "")
      .replace("_", "")
  );
};

Vue.prototype.$parseDate = (date, format = "d MMM yyyy") => {
  let parsedFrom = DateTime.fromISO(date);
  return parsedFrom.toFormat(format);
};

// Timestamp manipulation
const humanizeDuration = require("humanize-duration");
Vue.prototype.$formatTime = (
  date,
  fromFormat = "yyyy-MM-dd HH:mm:ss",
  toFormat = "yyyy-MM-dd HH:mm:ss"
) => {
  let dateTime = null;

  if (fromFormat === "ISO") {
    dateTime = DateTime.fromISO(date);
  } else {
    dateTime = DateTime.fromFormat(date, fromFormat);
  }

  return dateTime.toFormat(toFormat);
};

Vue.prototype.$relativeTime = (date, format = "yyyy-MM-dd HH:mm:ss") => {
  let from = null;
  if (format === "ISO") {
    from = DateTime.fromISO(date);
  } else {
    from = DateTime.fromFormat(date, format);
  }
  const to = DateTime.now();

  let formatted = Interval.fromDateTimes(from, to)
    .toDuration()
    .valueOf();

  let humanizedFull = humanizeDuration(formatted, {
    units: ["y", "mo", "w", "d", "h", "m"],
    maxDecimalPoints: 0,
  });
  let humanizedSplit = humanizedFull.split(",");

  if (humanizedSplit[0].startsWith("0 minutes")) {
    return "Less than a minute ago";
  }
  return `${humanizedSplit[0]} ago`;
};

// Clipboard management
Vue.prototype.$copyText = (text) => {
  if (!navigator.clipboard) {
    return fallbackCopyText(text);
  }

  return navigator.clipboard
    .writeText(text)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};
let fallbackCopyText = (text) => {
  let textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  let successful = document.execCommand("copy");
  document.body.removeChild(textArea);
  return successful;
};

Vue.prototype.$sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// Move an array item from index X to index Y
Array.prototype.moveObjectAtIndex = function(sourceIndex, destIndex) {
  var placeholder = {};
  var objectToMove = this.splice(sourceIndex, 1, placeholder)[0];
  this.splice(destIndex, 0, objectToMove);
  this.splice(this.indexOf(placeholder), 1);
};

JSON.parsify = function(input) {
  return JSON.parse(JSON.stringify(input));
};

const removeEmpty = (Vue.prototype.$removeEmpty = (obj) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => !!v && v.length)
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  );
});
