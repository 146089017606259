import apiService from "@p/apiService";
import imageEvents from "@p/events/images";
import snackbars from "@p/events/snackbars";

export const Images = {
  namespaced: true,
  state: {
    uploadURL: "",
  },
  getters: {
    uploadURL: (state) => {
      return state.uploadURL;
    },
    testData: (state) => {
      return state;
    },
  },
  actions: {
    getNewImageUploadURL({ commit }, data) {
      apiService
        .callApi("products", "getProductImageUploadURL", data)
        .then((response) => {
          commit("STORE_SIGNED_UPLOAD_URL", response.preSignedUrl);
        });
    },
    uploadImageToS3({ commit }, file) {
      commit("UPLOAD_IMAGE_TO_S3", file);
    },
    reset({ commit }) {
      commit("RESET");
    },
    makePrimary({ commit }, data) {
      commit("MAKE_PRIMARY", data);
    },
    delete({ commit }, data) {
      commit("DELETE", data);
    },
  },
  mutations: {
    STORE_SIGNED_UPLOAD_URL(state, url) {
      state.uploadURL = url;
    },
    UPLOAD_IMAGE_TO_S3(state, file) {
      let xhr = new XMLHttpRequest();
      xhr.open("put", state.uploadURL);
      xhr.setRequestHeader("Content-Type", file.type);

      xhr.onreadystatechange = function() {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          apiService.media
            .confirmUploadSuccess(state.uploadURL)
            .then(() => {
              imageEvents.$emit(
                "successful-image-upload",
                "Image uploaded successfully!"
              );
            })
            .catch(() => {
              imageEvents.$emit(
                "unsuccessful-image-upload",
                "Failed to confirm uploaded image."
              );
            });
        }
        if (this.readyState === XMLHttpRequest.DONE && this.status !== 200) {
          imageEvents.$emit(
            "unsuccessful-image-upload",
            "Failed to upload image."
          );
        }
      };

      xhr.send(file);
    },
    RESET(state) {
      state.uploadURL = "";
    },
    MAKE_PRIMARY(state, data) {
      let productUuid = data[0];
      let imageUuid = data[1];
      let imageData = { primary: true };

      apiService
        .callApi(
          "products",
          "updateProductImage",
          productUuid,
          imageUuid,
          imageData
        )
        .then(() => {
          this.dispatch("products/loadRecords");
          snackbars.$emit("snackbar", {
            message: "Image has been made Primary",
          });
        });
    },
    DELETE(state, data) {
      let productUuid = data[0];
      let imageUuid = data[1];

      apiService
        .callApi("products", "deleteProductImage", productUuid, imageUuid)
        .then(() => {
          this.dispatch("products/loadRecords");
          snackbars.$emit("snackbar", {
            message: "Image deleted",
          });
        });
    },
  },
  modules: {},
};
