const PTApi = require("print_trail");
import apiService from "@p/apiService";

export const ClientApi = new PTApi.ClientApi();

export default {
  activeClients(filters = {}) {
    let opts = apiService.getRequestOpts();
    if (filters.filterName && filters.filterName !== "") {
      opts.filterName = filters.filterName;
    }
    if (filters.filterCode && filters.filterCode !== "") {
      opts.filterCode = filters.filterCode;
    }
    if (filters.filterSearch && filters.filterSearch !== "") {
      opts.filterSearch = filters.filterSearch;
    }
    if (filters.page) {
      opts.page = filters.page;
    }
    if (filters.perPage) {
      opts.perPage = filters.perPage;
    }
    return ClientApi.clientIndex(opts);
  },

  getClient(uuid) {
    let opts = apiService.getRequestOpts();
    return ClientApi.clientShow(uuid, opts);
  },

  connectClient(uuid) {
    let opts = apiService.getRequestOpts();
    return ClientApi.clientConnect(uuid, opts);
  },

  disconnectClient() {
    let opts = apiService.getRequestOpts();
    return ClientApi.clientDisconnect(opts);
  },

  lastActiveClient() {
    let opts = apiService.getRequestOpts();
    return ClientApi.clientLast(opts);
  },

  recentClients() {
    let opts = apiService.getRequestOpts();
    return ClientApi.clientRecent(opts);
  },

  clearRecentClientHistory() {
    let opts = apiService.getRequestOpts();
    return ClientApi.clientClearHistory(opts);
  },

  updateClient(clientUuid, data) {
    let opts = apiService.getRequestOpts();
    opts.updateClientRequestBody = PTApi.UpdateClientRequestBody.constructFromObject(
      data
    );
    return ClientApi.clientUpdate(clientUuid, opts);
  },

  createClient(data) {
    let opts = apiService.getRequestOpts();
    opts.storeClientRequestBody = PTApi.StoreClientRequestBody.constructFromObject(
      data
    );
    return ClientApi.clientStore(opts);
  },

  setActive(clientUuids) {
    let opts = apiService.getRequestOpts();
    return ClientApi.clientActive(clientUuids, opts);
  },

  setInactive(clientUuids) {
    let opts = apiService.getRequestOpts();
    return ClientApi.clientInactive(clientUuids, opts);
  },
};
