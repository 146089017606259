import apiService from "@p/apiService";

export const ProductTypes = {
  namespaced: true,
  state: {
    records: [],
    recordsLoaded: false,

    record: {},
    recordLoaded: false,

    filters: null,

    tableHeaders: [
      { text: "Product type name", value: "productTypeLabel" },
      { text: "Product type code", value: "productType" },
      { text: "Supplier code", value: "supplierCode" },
      { text: "Category", value: "category" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    productTypes: [],
  },
  getters: {
    all: (state) => {
      return state.records;
    },
    byId: (state) => (id) => {
      return state.records.find((record) => record.id === id);
    },
    tableHeaders: (state) => {
      return state.tableHeaders;
    },
    filters: (state) => {
      return state.filters;
    },
    record: (state) => {
      return state.record;
    },
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadRecords");
      dispatch("resetFilters");
    },
    loadRecords({ commit }) {
      apiService.callApi("products", "getProductTypesList").then((response) => {
        commit("SAVE_RECORDS", response.data);
      });
    },
    loadTypeDetails({ commit }, type) {
      apiService
        .callApi("products", "getProductTypeDetails", type)
        .then((response) => {
          commit("SET_SELECTED", response.data);
        });
    },
    setSelected({ commit }, record) {
      commit("SET_SELECTED", record);
    },
    resetSelected({ commit }) {
      commit("RESET_SELECTED");
    },
    setFilter({ commit }, data) {
      commit("SET_FILTER", data);
    },
    resetFilter({ commit }, filter) {
      commit("RESET_FILTER", filter);
    },
    setFilters({ commit }, filters) {
      commit("SET_FILTERS", filters);
    },
    resetFilters({ commit }) {
      commit("RESET_FILTERS");
    },
    copyRecord({ commit }, record) {
      commit("ADD_RECORD", record);
    },
    update({ commit }, recordData) {
      commit("UPDATE_RECORD", recordData);
    },
  },
  mutations: {
    SAVE_RECORDS(state, records) {
      state.records = records;
    },
    SAVE_PRODUCT_TYPE_RECORDS(state, records) {
      state.productTypes = records;
    },
    ADD_RECORD(state, record) {
      state.records.push(record);
    },
    SET_SELECTED(state, record) {
      state.record = record;
    },
    RESET_SELECTED(state) {
      state.record = {};
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    RESET_FILTER(state, { filter }) {
      state.filters[filter] = null;
    },
    RESET_FILTERS(state) {
      state.filters = {
        search: "",
        statusTypes: ["active", "inactive"],
        status: "",
      };
    },
    UPDATE_RECORD(state, recordData) {
      // TODO: Implement API action for updating suppliers.
      let productTypes = state.records;

      const productTypeId = parseInt(recordData[0]);
      const newProductTypeData = recordData[1];

      let ids = productTypes.map((productType) => productType.id);
      let index = ids.indexOf(productTypeId);

      let dataAsArrays = Object.entries(newProductTypeData);
      for (const [key, value] of dataAsArrays) {
        productTypes[index][key] = value;
        // TODO: Send productTypes[index] to the API for updates in the backend.
      }

      state.records = productTypes;
    },
  },
  modules: {},
};
