import apiService from "@p/apiService";
import snackbars from "@p/events/snackbars";
import fulfilmentEvents from "@orders/events/fulfilments";

export const Fulfilments = {
  namespaced: true,
  state: {
    records: [],
    recordsLoaded: false,

    record: {},
    recordLoaded: false,

    documents: {},

    tableHeaders: [
      { text: "Order Date", value: "orderDate" },
      { text: "Fulfilment number", value: "fulfilmentNumber" },
      { text: "Parent order", value: "parentOrder" },
      { text: "Channel", value: "channel" },
      { text: "Fulfilment status", value: "fulfilmentStatus" },
      { text: "Supplier location", value: "supplierLocation" },
      { text: "Number of items", value: "numberOfItems", align: "right" },
      { text: "Destination country", value: "destinationCountry" },
      { text: "Courier", value: "courier" },
      { text: "Shipping method", value: "shippingMethod" },
      { text: "Tracking number", value: "trackingNumber" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    filters: {},

    fulfilmentProductsHeaders: [
      { text: "Product Name", value: "name" },
      { text: "Quantity", value: "quantity", align: "right" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    statusColours: {
      draft: "grey",
      requested: "blue",
      processing: "blue",
      dispatched: "green",
      "cancel-pending": "grey",
      cancelled: "grey",
      returned: "purple",
      invalid: "red",
      failed: "red",
      error: "red",
      holding: "orange",
      "back-order": "orange",
    },
  },
  getters: {
    all: (state) => {
      return state.records;
    },
    byId: (state) => (id) => {
      return state.records.find((record) => record.id === id);
    },
    tableHeaders: (state) => {
      return state.tableHeaders;
    },
    filters: (state) => {
      return state.filters;
    },
    fulfilmentProductsHeaders: (state) => {
      return state.fulfilmentProductsHeaders;
    },
    statusColours: (state) => {
      return state.statusColours;
    },
    documents: (state) => (fulfilmentUuid) => {
      return state.documents[fulfilmentUuid];
    },
  },
  actions: {
    loadRecords({ commit }) {
      // let fulfilments = apiService.fulfilments.getAll();
      let fulfilments = [];
      commit("SAVE_RECORDS", fulfilments);
    },
    setSelected({ commit }, record) {
      commit("SET_SELECTED", record);
    },
    resetSelected({ commit }) {
      commit("RESET_SELECTED");
    },
    setFilter({ commit }, data) {
      commit("SET_FILTER", data);
    },
    resetFilter({ commit }, filter) {
      commit("RESET_FILTER", filter);
    },
    setFilters({ commit }, filters) {
      commit("SET_FILTERS", filters);
    },
    resetFilters({ commit }) {
      commit("RESET_FILTERS");
    },
    updateSupplierLocation({ commit }, payload) {
      commit("UPDATE_SUPPLIER_LOCATION", payload);
    },
    updateFulfilment({}, payload) {
      let fulfilmentUuid = payload[0];
      let fulfilmentData = payload[1];

      apiService
        .callApi(
          "fulfilments",
          "updateFulfilment",
          fulfilmentUuid,
          fulfilmentData
        )
        .then((response) => {
          snackbars.$emit("snackbar", {
            message: `Shipping updated for ${response.data.fulfilmentNumber}`,
          });
        });
    },
    updateDocument({}, payload) {
      let uuid = payload[0];
      let documentType = payload[1];
      apiService
        .callApi("media", "updateMedia", uuid, {
          extraMetadata: {
            documentType: documentType,
          },
        })
        .then(() => {
          fulfilmentEvents.$emit("document-updated");
          snackbars.$emit("snackbar", {
            message: "Document updated",
            type: "success",
          });
        });
    },
    loadFulfilmentDocuments({ commit }, fulfilmentUuid) {
      apiService
        .callApi("fulfilments", "getFulfilmentDocuments", fulfilmentUuid)
        .then((res) => {
          commit("STORE_FULFILMENT_DOCUMENTS", [fulfilmentUuid, res.data]);
        });
    },
  },
  mutations: {
    SAVE_RECORDS(state, records) {
      state.records = records;
    },
    SET_SELECTED(state, record) {
      state.record = record;
    },
    RESET_SELECTED(state) {
      state.record = {};
    },
    SET_FILTER(state, data) {
      let filterName = data[0];
      state.filters[filterName] = data[1];
    },
    RESET_FILTER(state, filter) {
      state.filters[filter] = "";
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    RESET_FILTERS(state) {
      state.filters = {
        search: "",
        fulfilmentStatus: "",
        dateFrom: "",
        dateTo: "",
        statusTypes: ["new", "pending", "cancelled", "shipped"],
        channel: "",
        channels: [],
        supplier: "",
        suppliers: [],
      };
    },
    UPDATE_SUPPLIER_LOCATION(state, payload) {
      let suppliers = state.records;

      const fulfilmentId = parseInt(payload[0]);
      const supplierId = payload[1];

      let ids = suppliers.map((supplier) => supplier.id);
      let index = ids.indexOf(fulfilmentId);
      suppliers[index].supplierId = supplierId;
      state.records = suppliers;
    },
    STORE_FULFILMENT_DOCUMENTS(state, payload) {
      let fulfilmentUuid = payload[0];
      let fulfilmentDocuments = payload[1];

      let documents = Object.assign({}, state.documents);
      documents[fulfilmentUuid] = fulfilmentDocuments;
      state.documents = documents;
    },
  },
};
