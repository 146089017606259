const PTApi = require("print_trail");
import apiService from "@p/apiService";

export const PoDApi = new PTApi.PoDApi();

export default {
  uploadProductPodArtwork(data) {
    let opts = apiService.getRequestOpts();
    opts.addPodDataArtworkRequestBody = PTApi.AddPodDataArtworkRequestBody.constructFromObject(
      data
    );
    return PoDApi.podArtworkUpload(opts);
  },

  deleteProductPod(productUuid, podIdentifier) {
    let opts = apiService.getRequestOpts();
    if (podIdentifier === "primary" || podIdentifier === "secondary") {
      return PoDApi.podDelete(productUuid, podIdentifier, opts);
    } else {
      throw 'PoD Identifier must be "primary" or "secondary"';
    }
  },

  getProductPods(productUuid) {
    let opts = apiService.getRequestOpts();
    return PoDApi.podIndex(productUuid, opts);
  },

  getPodTypeMetaData(podTypeUuid) {
    let opts = apiService.getRequestOpts();
    return PoDApi.podMetadataShow(podTypeUuid, opts);
  },

  submitProductPod(productUuid) {
    let opts = apiService.getRequestOpts();
    return PoDApi.podSubmit(productUuid, opts);
  },

  allPodTypes(search = null) {
    let opts = apiService.getRequestOpts();
    if (search) {
      opts.search = search;
    }
    return PoDApi.podTypesIndex(opts);
  },

  updateProductPod(productUuid, data) {
    let opts = apiService.getRequestOpts();
    opts.updatePodDataRequestBody = PTApi.UpdatePodDataRequestBody.constructFromObject(
      data
    );
    return PoDApi.podUpdate(productUuid, opts);
  },

  reviewPodData(productUuid, metadataFieldCode, data) {
    let opts = apiService.getRequestOpts();
    opts.reviewPodDataRequestBody = PTApi.ReviewPodDataRequestBody.constructFromObject(
      data
    );
    return PoDApi.podDataReview(productUuid, metadataFieldCode, opts);
  },

  clonePod(productUuid) {
    let opts = apiService.getRequestOpts();
    return PoDApi.podClone(productUuid, opts);
  },

  deletePod(productUuid, identifier) {
    let opts = apiService.getRequestOpts();
    return PoDApi.podDelete(productUuid, identifier, opts);
  },

  deletePodData(productUuid, podMetadataCode) {
    let opts = apiService.getRequestOpts();
    return PoDApi.podDataDelete(productUuid, podMetadataCode, opts);
  },
};
