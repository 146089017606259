const adminNavItems = [
  {
    text: "Teams",
    route: "/admin/teams",
    icon: "fas fa-info-circle",
    requiredPermission: "viewAdminTeams",
  },
  {
    text: "Users",
    route: "/admin/users",
    icon: "fas fa-users",
    requiredPermission: "viewAdminUsers",
  },
  {
    text: "PoD Review",
    route: "/admin/pod-review",
    icon: "fas fa-clipboard-check",
  },
  {
    text: "Fulfilments",
    route: "/admin/fulfilments",
    icon: "fas fa-cube",
    requiredPermission: "viewAdminFulfilments",
  },
  {
    text: "Shipping Zones",
    route: "/admin/shipping-zones",
    icon: "fa-map-marker",
    requiredPermission: "viewAdminShippingZones",
  },
  {
    text: "PoD Product Types",
    route: "/admin/products",
    icon: "fa-print",
    requiredPermission: "viewAdminPodProductTypes",
  },
  {
    text: "Fulfilment Locations",
    route: "/admin/locations",
    icon: "fas fa-industry",
    requiredPermission: "viewAdminSuppliers",
  },
];

export default adminNavItems;
