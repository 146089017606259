import apiService from "@p/apiService";

export const SupplierLocations = {
  namespaced: true,
  state: {
    supplierLocations: [],
  },
  getters: {
    all: (state) => state.supplierLocations,
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadRecords");
    },
    loadRecords({ commit }) {
      apiService.callApi("general", "getSupplierLocations").then((response) => {
        commit("SAVE_RECORDS", response.data);
      });
    },
  },
  mutations: {
    SAVE_RECORDS(state, supplierLocations) {
      state.supplierLocations = supplierLocations;
    },
  },
  modules: {},
};
