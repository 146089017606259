const PTApi = require("print_trail");
import apiService from "@p/apiService";

export const WarehouseApi = new PTApi.WarehouseApi();

export default {
  getWarehouses(productUuid) {
    let opts = apiService.getRequestOpts();
    return WarehouseApi.warehouseIndex(productUuid, opts);
  },

  shippingZones(options) {
    let opts = apiService.getRequestOpts();
    if (options) {
      opts.filterGroup = options.filterGroup ?? null;
      opts.filterName = options.filterName ?? null;
      opts.page = options.page ?? null;
      opts.perPage = options.perPage ?? null;
    }

    if (!opts.filterGroup) {
      delete opts.filterGroup;
    }
    if (!opts.filterName) {
      delete opts.filterName;
    }
    if (!opts.page) {
      delete opts.page;
    }
    if (!opts.perPage) {
      delete opts.perPage;
    }

    return WarehouseApi.warehouseShippingZones(opts);
  },

  supplierLocations(productUuid, options) {
    let opts = apiService.getRequestOpts();
    if (options) {
      if (options.filterLabel && options.filterLabel !== "") {
        opts.filterLabel = options.filterLabel;
      } else {
        opts.filterLabel = null;
      }
      if (options.page) {
        opts.page = options.page;
      }
      if (options.perPage) {
        opts.perPage = options.perPage;
      }
    }
    return WarehouseApi.warehouseSupplierLocations(productUuid, opts);
  },

  syncWarehouse(productUuid, data) {
    let opts = apiService.getRequestOpts();
    opts.warehouseRequestBody = PTApi.WarehouseRequestBody.constructFromObject(
      data
    );
    return WarehouseApi.warehouseSync(productUuid, opts);
  },
};
