import apiService from "@p/apiService";
import { TeamUsers } from "./teamUsers";
import { TeamSelect } from "./teamSelect";
import snackbarEvents from "@p/events/snackbars";
import teamEvents from "@teams/events";
import router from "@r";

export const Teams = {
  namespaced: true,
  state: {
    filters: null,
    teams: [],
    teamsLoaded: false,

    recentTeams: [],
    recentTeamsLoaded: false,

    activeTeamUuid: {},
    teamLoaded: false,

    teamTableHeaders: [
      { text: "UUID", value: "uuid" },
      { text: "Name", value: "name" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    settingsTableHeaders: [
      { text: "ID", value: "uuid" },
      { text: "Name", value: "name" },
      { text: "Status", value: "status" },
      { text: "Orders", value: "orders" },
      { text: "Fulfilments", value: "fulfilments" },
      { text: "Users", value: "users" },
      { text: "Channels", value: "channels" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  },
  getters: {
    initialised: (state) => state.teamsLoaded,
    allTeams: (state) => state.teams,
    teamByUuid: (state) => (uuid) =>
      state.teams.find((team) => team.uuid === uuid),
    settingsTableHeaders: (state) => state.settingsTableHeaders,
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("teamSelect/initialise");
    },
    updateTeam({ dispatch }, data) {
      let clientUuid = data[0];
      let clientData = data[1];
      let shouldReload = !!data[3];
      apiService
        .callApi("client", "updateClient", clientUuid, clientData)
        .then(() => {
          snackbarEvents.$emit("snackbar", {
            message: "Team details updated",
          });

          if (shouldReload) {
            dispatch("initialise");
          }

          teamEvents.$emit('team-details-updated');
        });
    },
    resetFilters({ commit }) {
      commit("RESET_FILTERS");
    },
    clearFilter({ commit }, filter) {
      commit("CLEAR_FILTER", filter);
    },
  },

  mutations: {
    SAVE_TEAMS(state, teams) {
      state.teams = teams;
      state.teamsLoaded = true;
    },
    RESET_FILTERS(state) {
      state.filters = {
        search: "",
      };
    },
    CLEAR_FILTER(state, { filter }) {
      state.filters[filter] = "";
    },
  },

  modules: {
    teamUsers: TeamUsers,
    teamSelect: TeamSelect,
  },
};
