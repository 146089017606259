import apiService from "@p/apiService";
import { filters } from "@/models/order";

const PTApi = require("print_trail");

export const OrderApi = new PTApi.OrderApi();
export const FulfilmentApi = new PTApi.FulfilmentApi();

export default {
  getOrders(options) {
    let opts = apiService.getRequestOpts();
    if (options) {
      filters.forEach((filter) => {
        if (options[filter] !== null && options.filter !== "") {
          opts[filter] = options[filter];
        } else {
          delete opts[filter];
        }
      });
    }
    return OrderApi.orderIndex(opts);
  },

  getOrder(uuid) {
    let opts = apiService.getRequestOpts();
    return OrderApi.orderShow(uuid, opts);
  },

  createOrder(data) {
    let opts = apiService.getRequestOpts();
    opts.orderRequestBody = PTApi.OrderRequestBody.constructFromObject(data);
    return OrderApi.orderStore(opts);
  },

  submitOrder(orderUuid) {
    let opts = apiService.getRequestOpts();
    return OrderApi.orderSubmit(orderUuid, opts);
  },

  submitBulkOrders(orderUuids) {
    let opts = apiService.getRequestOpts();
    opts.bulkOrdersRequestBody2 = PTApi.BulkOrdersRequestBody2.constructFromObject(
      orderUuids
    );
    return OrderApi.orderBulkSubmit(opts);
  },

  updateOrder(orderUuid, data) {
    let opts = apiService.getRequestOpts();
    opts.orderRequestBody1 = PTApi.OrderRequestBody1.constructFromObject(data);
    return OrderApi.orderUpdate(orderUuid, opts);
  },

  cancelOrder(orderUuid) {
    let opts = apiService.getRequestOpts();
    return OrderApi.orderCancel(orderUuid, opts);
  },

  cancelBulkOrders(orderUuids) {
    let opts = apiService.getRequestOpts();
    opts.bulkOrdersRequestBody1 = PTApi.BulkOrdersRequestBody1.constructFromObject(
      orderUuids
    );
    return OrderApi.orderBulkCancel(opts);
  },

  cloneOrder(orderUuid) {
    let opts = apiService.getRequestOpts();
    return OrderApi.orderClone(orderUuid, opts);
  },

  cloneBulkOrders(orderUuids) {
    let opts = apiService.getRequestOpts();
    opts.bulkOrdersRequestBody = PTApi.BulkOrdersRequestBody.constructFromObject(
      orderUuids
    );
    return OrderApi.orderBulkClone(opts);
  },

  convertToDraft(orderUuid) {
    let opts = apiService.getRequestOpts();
    return OrderApi.orderDraft(orderUuid, opts);
  },

  updateMinorDetails(payload) {
    let opts = apiService.getRequestOpts();
    opts.orderRequestBody3 = PTApi.OrderRequestBody3.constructFromObject(payload[1]);
    return OrderApi.orderUpdateMinorDetails(payload[0], opts);
  },
  
  cancelFulfilments(fulfilmentUuids) {
    let opts = apiService.getRequestOpts();
    opts.bulkUuidRequestBody = PTApi.BulkUuidRequestBody.constructFromObject(fulfilmentUuids);
    return FulfilmentApi.fulfilmentCancel(opts);
  },

  processFulfilments(fulfilmentUuids) {
    let opts = apiService.getRequestOpts();
    opts.bulkUuidRequestBody1 = PTApi.BulkUuidRequestBody1.constructFromObject(fulfilmentUuids);
    return FulfilmentApi.fulfilmentProcess(opts);
  }
};
