const createMenu = [
  {
    text: "Create Order",
    route: "/TEAM_CODE/orders/create",
    icon: "fas fa-plus",
    createIcon: "fas fa-shopping-cart",
    requiredPermission: "createOrders",
  },
  {
    text: "Import Orders",
    route: "/TEAM_CODE/orders/import",
    icon: "fas fa-cloud-upload-alt",
    createIcon: "fas fa-cloud-upload-alt",
    requiredPermission: "importOrders",
  },
  {
    text: "Create Product",
    route: "/TEAM_CODE/products/create",
    icon: "fas fa-plus",
    createIcon: "fas fa-tags",
    requiredPermission: "createProducts",
  },
  {
    text: "Import Products",
    route: "/TEAM_CODE/products/import",
    icon: "fas fa-cloud-upload-alt",
    createIcon: "fas fa-cloud-upload-alt",
    requiredPermission: "createProducts",
  },
];

export default createMenu;
