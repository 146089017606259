import { ShippingZone } from "./shippingZone";
import { SupplierLocation } from "./supplierLocation";

export class InventoryLocation {
  properties = {
    priority: String,
    stockAlert: String,
    stock: String,
    stockLastSyncedAt: String,
    backorder: Boolean,
    shippingZones: [ShippingZone],
    supplierLocation: SupplierLocation,
  };

  constructor(data) {
    let inventoryLocation = this;
    for (const [key, dataType] of Object.entries(this.properties)) {
      switch (dataType) {
        case String:
          inventoryLocation[key] = data[key] ?? null;
          break;
        case [ShippingZone]:
          inventoryLocation[key] = data[key].map(
            (shippingZone) => new ShippingZone(shippingZone)
          );
          break;
        case SupplierLocation:
          inventoryLocation[key] = data[key]
            ? new SupplierLocation(data[key])
            : null;
          break;
        default:
          inventoryLocation[key] = data[key] ?? null;
          break;
      }
    }
  }
}
