const PTApi = require("print_trail");
import apiService from "@p/apiService";
import { filters } from "@/models/product";

export const ProductApi = new PTApi.ProductApi();

export default {
  getProducts(options) {
    let opts = apiService.getRequestOpts();
    if (options) {
      filters.forEach((filter) => {
        if (options[filter] !== null || options.filter !== "") {
          opts[filter] = options[filter];
        } else {
          delete opts[filter];
        }
      });
    }
    return ProductApi.productIndex(opts);
  },

  createProduct(productData) {
    let opts = apiService.getRequestOpts();
    opts.productRequestBody = PTApi.ProductRequestBody.constructFromObject(
      productData
    );
    return ProductApi.productStore(opts);
  },

  getProduct(uuid) {
    let opts = apiService.getRequestOpts();
    return ProductApi.productShow(uuid, opts);
  },

  deleteProduct(uuid) {
    let opts = apiService.getRequestOpts();
    return ProductApi.productDestroy(uuid, opts);
  },

  updateProduct(uuid, data) {
    let opts = apiService.getRequestOpts();
    opts.productRequestBody1 = PTApi.ProductRequestBody1.constructFromObject(
      data
    );
    return ProductApi.productUpdate(uuid, opts);
  },

  getProductImageUploadURL(data) {
    let opts = apiService.getRequestOpts();
    opts.addProductImageRequestBody = PTApi.AddProductImageRequestBody.constructFromObject(
      data
    );
    return ProductApi.productImageUpload(opts);
  },

  setActive(uuid) {
    let opts = apiService.getRequestOpts();
    return ProductApi.productSetActive(uuid, opts);
  },

  setInactive(uuid) {
    let opts = apiService.getRequestOpts();
    return ProductApi.productSetInactive(uuid, opts);
  },

  setPreOrder(uuid) {
    let opts = apiService.getRequestOpts();
    return ProductApi.productSetPreOrder(uuid, opts);
  },

  updateProductImage(productUuid, imageUuid, data) {
    let opts = apiService.getRequestOpts();
    opts.updateProductImageRequestBody = PTApi.UpdateProductImageRequestBody.constructFromObject(
      data
    );
    return ProductApi.productImageUpdate(productUuid, imageUuid, opts);
  },

  deleteProductImage(productUuid, imageUuid) {
    let opts = apiService.getRequestOpts();
    return ProductApi.productImageDestroy(productUuid, imageUuid, opts);
  },

  getProductTypesList() {
    let opts = apiService.getRequestOpts();
    return ProductApi.productGetProductTypeList(opts);
  },

  getProductTypeDetails(type) {
    let opts = apiService.getRequestOpts();
    return ProductApi.productCreateByType(type, opts);
  },
};
