import { Channels } from "./channels";
import { Orders } from "./orders";
import { Suppliers } from "./suppliers";
import { Teams } from "./teams";
import { Users } from "./users";

export const Admin = {
  namespaced: true,
  modules: {
    channels: Channels,
    orders: Orders,
    suppliers: Suppliers,
    teams: Teams,
    users: Users,
  },
  actions: {
    initialise() {
      //
    },
  },
};
