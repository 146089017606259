import apiService from "@p/apiService";

const PTApi = require("print_trail");

export const TeamApi = new PTApi.TeamApi();

export default {
  getUsers(team) {
    let opts = apiService.getRequestOpts();
    return TeamApi.teamGetUsers(team, opts);
  },
  removeUser(team, user) {
    let opts = apiService.getRequestOpts();
    return TeamApi.teamRemoveUser(team, user, opts);
  },
};
