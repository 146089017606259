const Products = () => import("../views/All.vue");
const CreateProduct = () => import("../views/Create.vue");
const ProductDetails = () => import("../views/Details.vue");
const ChannelConfiguration = () => import("../views/ChannelConfiguration.vue");
const ChannelAddToProduct = () => import("../views/ChannelAdd.vue");
const Inventory = () => import("../views/Inventory.vue");
const Import = () => import("../views/Import.vue");

// PRODUCTS
const routes = [
  {
    path: "products",
    component: {
      render(component) {
        return component("router-view");
      },
    },
    meta: {
      breadcrumb: "All Products",
      title: "All Products",
    },
    children: [
      {
        path: "",
        name: "products",
        component: Products,
        meta: {
          tab: "Products",
          title: "All Products",
        },
      },
      {
        path: "create",
        name: "create-product",
        component: CreateProduct,
        meta: {
          breadcrumb: "Create Product",
          title: "Create Product",
        },
      },
      {
        path: "inventory",
        name: "products-inventory",
        component: Inventory,
        meta: {
          breadcrumb: "Inventory",
          title: "Inventory",
        },
      },
      {
        path: ":id/details",
        name: "product_details",
        component: ProductDetails,
        meta: {
          breadcrumb: "Product Details",
          title: "Product Details",
        },
      },
      {
        path: ":id/details/channels/:channelId/details",
        name: "channel-details-product",
        component: ChannelConfiguration,
        meta: {
          breadcrumb: "Product Channel Details",
          title: "Product Channel Details",
        },
      },
      {
        path: ":id/details/channels/add",
        name: "channel-add-product",
        component: ChannelAddToProduct,
        meta: {
          breadcrumb: "Product Channel Add",
          title: "Product Channel Add",
        },
      },
      {
        path: "import/:id?",
        name: "import_products",
        component: Import,
        meta: {
          breadcrumb: "Import",
          title: "Import Products",
        },
      },
    ],
  },
];

export default routes;
