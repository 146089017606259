export const Invited = {
  namespaced: true,
  state: {
    records: [],
    recordsLoaded: false,

    record: {},
    recordLoaded: false,

    filters: null,

    tableHeaders: [
      { text: "Email", value: "email" },
      { text: "Name", value: "name" },
      { text: "Status", value: "status" },
      { text: "Role(s)", value: "role" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  },
  getters: {
    all: (state) => {
      return state.records;
    },
    byId: (state) => (id) => {
      return state.records.find((record) => record.id === id);
    },
    tableHeaders: (state) => {
      return state.tableHeaders;
    },
    record: (state) => {
      return state.record;
    },
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadRecords");
      dispatch("resetFilters");
    },
    loadRecords({ commit }) {
      // let invitedUsers = apiService.invitedUsers.getAll();
      let invitedUsers = [];
      commit("SAVE_RECORDS", invitedUsers);
    },
    setSelected({ commit }, record) {
      commit("SET_SELECTED", record);
    },
    resetSelected({ commit }) {
      commit("RESET_SELECTED");
    },
    update({ commit }, recordData) {
      commit("UPDATE_RECORD", recordData);
    },
    setFilter({ commit }, data) {
      commit("SET_FILTER", data);
    },
    setFilters({ commit }, data) {
      commit("SET_FILTERS", data);
    },
    resetFilter({ commit }, filter) {
      commit("RESET_FILTER", filter);
    },
    resetFilters({ commit }) {
      commit("RESET_FILTERS");
    },
  },
  mutations: {
    SAVE_RECORDS(state, records) {
      state.records = records;
    },
    ADD_RECORD(state, record) {
      state.records.push(record);
    },
    SET_SELECTED(state, record) {
      state.record = record;
    },
    RESET_SELECTED(state) {
      state.record = {};
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    RESET_FILTER(state, { filter }) {
      state.filters[filter] = null;
    },
    RESET_FILTERS(state) {
      state.filters = {};
    },
    UPDATE_RECORD(state, recordData) {
      // TODO: Implement API action for updating teamUsers.
      let invitedUsers = state.records;

      const invitedUserId = parseInt(recordData[0]);
      const newInvitedUserData = recordData[1];

      let ids = invitedUsers.map((invitedUser) => invitedUser.id);
      let index = ids.indexOf(invitedUserId);

      let dataAsArrays = Object.entries(newInvitedUserData);
      for (const [key, value] of dataAsArrays) {
        invitedUsers[index][key] = value;
        // TODO: Send invitedUsers[index] to the API for updates in the backend.
      }

      state.records = invitedUsers;
    },
  },
  modules: {},
};
