import apiService from "@p/apiService";

const PTApi = require("print_trail");

export const UserApi = new PTApi.UserApi();

export default {
  getUserDetails() {
    let opts = apiService.getRequestOpts();
    return UserApi.userMe(opts);
  },

  getEmailValidationRules() {
    let opts = apiService.getRequestOpts();
    return UserApi.userChangeEmailRules(opts);
  },

  changeUserEmail(data) {
    let opts = apiService.getRequestOpts();
    opts.changeEmailRequestBody = PTApi.ChangeEmailRequestBody.constructFromObject(
      data
    );
    return UserApi.userChangeEmail(opts);
  },

  getPasswordValidationRules() {
    let opts = apiService.getRequestOpts();
    return UserApi.userChangePasswordRules(opts);
  },

  changeUserPassword(data) {
    let opts = apiService.getRequestOpts();
    opts.changePasswordRequestBody = PTApi.ChangePasswordRequestBody.constructFromObject(
      data
    );
    return UserApi.userChangePassword(opts);
  },

  register(userData) {
    let opts = apiService.getRequestOpts();
    opts.registerUserRequestBody = PTApi.RegisterUserRequestBody.constructFromObject(
      userData
    );
    return UserApi.userRegister(opts);
  },

  updateUser(userData) {
    let opts = apiService.getRequestOpts();
    opts.updateUserRequestBody = PTApi.UpdateUserRequestBody.constructFromObject(
      userData
    );
    return UserApi.userUpdate(userData.uuid, opts);
  },

  getAllUsers(filters = {}) {
    let opts = apiService.getRequestOpts();
    for (const [key, value] of Object.entries(filters)) {
      opts[key] = value;
    }
    return UserApi.userIndex(opts);
  },

  getById(uuid) {
    let opts = apiService.getRequestOpts();
    return UserApi.userShow(uuid, opts);
  },
};
