export default class User {
  constructor(userData) {
    this.uuid = userData.uuid;
    this.firstName = userData.firstName;
    this.lastName = userData.lastName;
    this.name = `${userData.firstName} ${userData.lastName}`;
    this.email = userData.email;
    this.roles = userData.roles;
    this.permissions = userData.permissions;
    this.tenantCode = userData.tenantCode;
    this.tenantName = userData.tenantName;
    this.tenantUuid = userData.tenantUuid;
  }

  hasPermissionTo(action) {
    if (this.roles.includes("staff") || this.roles.includes("admin")) {
      return true;
    }

    if (this.permissions.includes("*") || action === "" || !action) {
      return true;
    }

    return this.permissions.length ? this.permissions.includes(action) : false;
  }

  hasRole(roles = []) {
    let hasRole = false;
    for (let role of roles) {
      if (this.roles.includes(role)) {
        hasRole = true;
      }
    }
    return hasRole;
  }
}
