import Vue from "vue";

export default new Vue({
  data: {
    alerts: [],
    alertCodes: {
      TeamIsInactive: {
        type: "error",
        text: "This team is inactive. No new orders can be placed.",
      },
    },
  },
  methods: {
    resetAlerts() {
      this.alerts = [];
    },
    addGlobalAlert(alertCode) {
      let alert = this.alertCodes[alertCode];
      if (alert) {
        if (!this.alerts.includes(alert)) {
          this.alerts.push(alert);
        }
      }
    },
  },
});
