import apiService from "@p/apiService";

const PTApi = require("print_trail");

export const MediaApi = new PTApi.MediaApi();

export default {
  confirmUploadSuccess(preSignedUrl) {
    let opts = apiService.getRequestOpts();
    let data = {
      preSignedUrl: preSignedUrl,
    };
    opts.confirmMediaUploadedRequestBody = PTApi.ConfirmMediaUploadedRequestBody.constructFromObject(
      data
    );
    return MediaApi.mediaUploadConfirm(opts);
  },

  getUploadURL(data) {
    let opts = apiService.getRequestOpts();
    opts.addProductImageRequestBody1 = PTApi.AddProductImageRequestBody1.constructFromObject(
      data
    );
    return MediaApi.mediaUploadRequest(opts);
  },

  deleteMedia(mediaUuid) {
    let opts = apiService.getRequestOpts();
    return MediaApi.mediaDestroy(mediaUuid, opts);
  },

  updateMedia(mediaUuid, data) {
    let opts = apiService.getRequestOpts();
    opts.updateMediaRequestBody = PTApi.UpdateMediaRequestBody.constructFromObject(
      data
    );
    return MediaApi.mediaUpdate(mediaUuid, opts);
  },
};
