import apiService from "@p/apiService";
import snackbarEvents from "@p/events/snackbars";
import teamEvents from "@teams/events";

export const TeamSelect = {
  namespaced: true,
  state: {
    teams: [],
    activeTeamUuid: {},
    metadata: null,
    showSnackbar: false,
  },
  getters: {
    allTeams: (state) => state.teams,
    activeTeam: (state) =>
      state.teams.find((team) => team.uuid === state.activeTeamUuid),
    showSnackbar: (state) => state.showSnackbar,
    byCode: (state) => (code) => state.teams.find((team) => team.code === code),
  },
  actions: {
    initialise({ commit, dispatch }) {
      apiService
        .callApi("client", "activeClients")
        .then((clientsRes) => {
          let teams = clientsRes.data;
          if (teams.length) {
            commit("SAVE_TEAMS", teams);
            commit("SET_METADATA", clientsRes.meta);

            if (teams.length > 1) {
              apiService
                .callApi("client", "lastActiveClient")
                .then((lastActiveRes) => {
                  dispatch("setActiveTeam", lastActiveRes.data);
                });
            } else {
              dispatch("setActiveTeam", teams[0]);
            }
          }
        })
        .finally(() => {
          dispatch("cacheAllTeams");
          teamEvents.$emit("finished-team-fetch");
          setTimeout(() => {
            commit("SET_SHOW_SNACKBAR", true);
          }, 3000);
        });
    },
    setActiveTeam({ commit }, team) {
      let currentTeam = apiService.getClientUuidHeader();
      apiService.setClientUuidHeader(team.uuid);
      apiService
        .callApi("client", "connectClient", team.uuid)
        .then(() => {
          teamEvents.$emit("changed-active-team", team);
          commit("SET_ACTIVE_TEAM", team.uuid);
        })
        .catch((e) => {
          apiService.setClientUuidHeader(currentTeam);
          snackbarEvents.$emit("snackbar", {
            message: "Failed to switch team.",
          });
          console.error(e);
        });
    },
    pollSetActiveTeam({ commit, dispatch }, team) {
      apiService.setClientUuidHeader(team.uuid);
      apiService
        .callApi("client", "connectClient", team.uuid)
        .then(() => {
          dispatch("cacheAllTeams");
          teamEvents.$emit("changed-active-team", team);
          teamEvents.$emit("poll-set-active-complete");
          commit("SET_ACTIVE_TEAM", team.uuid);
        })
        .catch(() => {
          teamEvents.$emit("poll-set-active-failed", team);
        });
    },
    cacheAllTeams({ commit, dispatch }) {
      if (TeamSelect.state.metadata) {
        let pages = TeamSelect.state.metadata.last_page;
        if (pages) {
          for (let i = 1; i <= pages; ++i) {
            apiService
              .callApi("client", "activeClients", { page: i })
              .then((clientsRes) => {
                commit("ADD_TEAMS_TO_CACHE", clientsRes.data);
                if (i === pages) {
                  teamEvents.$emit("team-caching-finished");
                }
              });
          }
        }
        teamEvents.$emit("finised-caching-all-teams");
      }
    },
    addTeam({ commit }, data) {
      commit("ADD_TEAMS_TO_CACHE", [data]);
    },
  },

  mutations: {
    SAVE_TEAMS(state, teams) {
      state.teams = teams;
    },
    SET_ACTIVE_TEAM(state, activeTeamUuid) {
      state.activeTeamUuid = activeTeamUuid;
    },
    SET_METADATA(state, metadata) {
      state.metadata = metadata;
    },
    ADD_TEAMS_TO_CACHE(state, teams) {
      state.teams = Array.from(new Set([...teams, ...state.teams]));
    },
    SET_SHOW_SNACKBAR(state, show) {
      state.showSnackbar = show;
    },
  },
};
