import AuthenticationService from "@p/authentication";
import authEvents from "@p/events/auth";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const AuthClient = new AuthenticationService();

export default new Vuex.Store({
  namespaced: true,
  state: {
    initialised: false,
    user: null,
    authenticated: false,
    registrationUrl: null,
    keycloakRoles: [],
  },
  getters: {
    isUserAuthenticated: (state) => !!state.user,
    userProfile: (state) => state.user,
    registrationUrl: (state) => state.registrationUrl,
    keycloakRoles: (state) => state.keycloakRoles,
  },
  actions: {
    initialise(context) {
      context.commit("INITIALISE");
    },
    login(context) {
      context.commit("ATTEMPT_LOGIN");
    },
    logout(context) {
      context.commit("ATTEMPT_LOGOUT");
    },
    setUser(context, user) {
      context.commit("SET_USER", user);
    },
    resetUser(context) {
      context.commit("RESET_USER");
    },
    getRegistrationUrl(context) {
      let url = AuthClient.getRegistrationUrl();
      context.commit("STORE_REGISTRATION_URL", url);
    },
    getKeycloakRoles(context) {
      AuthClient.getRoles().then((result) => {
        context.commit("SET_ROLES", result);
      });
    },
  },
  mutations: {
    INITIALISE(state) {
      if (!state.initialised) {
        let intendedURL = window.location.href;
        let localStorage = window.localStorage;

        if (intendedURL.split("/").length > 6) {
          localStorage.setItem("intendedURL", intendedURL);
          localStorage.setItem("deepLinked", true);
        }

        AuthClient.setUpEventHook();
        AuthClient.getSignedIn()
          .then((result) => {
            AuthClient.signInSilent()
              .then((user) => {
                authEvents.$emit(
                  "set-authentication-header",
                  user.access_token
                );
                this.dispatch("setUser", user.profile);
                this.dispatch("getKeycloakRoles");
              })
              .then(() => {
                // User is logged in.
                authEvents.$emit("finished-authentication-attempt");
              })
              .catch(() => {
                // User is not logged in.
                authEvents.$emit("finished-authentication-attempt");
                authEvents.$emit("force-logout");
              });

            state.initialised = result;
          })
          .catch((err) => {
            if (err === "Not authenticated.") {
              this.dispatch("getRegistrationUrl");
              // The only routes we're allowed to visit without authentication
              if (
                !window.location.pathname.startsWith("/invite/") &&
                !window.location.pathname.startsWith("/register")
              ) {
                // Sign in if not on invite/register pages
                authEvents.$emit("force-logout");
                AuthClient.signIn();
              } else {
                // If we're on that page, we need to get rid of the spinner.
                authEvents.$emit("finished-authentication-attempt");
              }
            }
          });
      }
    },
    ATTEMPT_LOGIN() {
      AuthClient.signIn();
    },
    ATTEMPT_LOGOUT() {
      AuthClient.signOut().then(() => {
        this.dispatch("resetUser");
      });
    },
    SET_USER(state, user) {
      state.user = user;
      state.authenticated = true;
    },
    RESET_USER(state) {
      state.user = null;
      state.authenticated = false;
    },
    STORE_REGISTRATION_URL(state, url) {
      state.registrationUrl = url;
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
  },
  modules: {},
});
