// Import all vee-validate rules
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);
