export class SupplierLocation {
  properties = {
    supplierCode: String,
    code: String,
    label: String,
    countryCode: String,
  };

  constructor(data) {
    let supplierLocation = this;
    for (const [key, dataType] of Object.entries(this.properties)) {
      switch (dataType) {
        case String:
          supplierLocation[key] = data[key] ?? null;
          break;
        default:
          supplierLocation[key] = data[key] ?? null;
          break;
      }
    }
  }
}
