export const CurrentUser = {
  namespaced: true,
  state: {
    profile: {},
    invitations: [],
    pendingInvitations: [],
    invitationsTableHeaders: [
      { text: "Team name", value: "team" },
      { text: "Expires at", value: "expired_at" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    filters: [],
  },
  getters: {
    userProfile: (state) => {
      return state.profile;
    },
    invitations: (state) => {
      return state.invitations;
    },
    pendingInvitations: (state) => {
      return state.pendingInvitations;
    },
    filters: (state) => {
      return state.filters;
    },
    tableHeaders: (state) => {
      return state.invitationsTableHeaders;
    },
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadRecords");
      dispatch("resetFilters");
    },
    loadRecords({ commit }) {
      let invitations = [];
      commit("SAVE_INVITATIONS", invitations);
      commit("SAVE_PENDING_INVITATIONS", invitations);
    },
    setFilter({ commit }, data) {
      commit("SET_FILTER", data);
    },
    resetFilter({ commit }, filter) {
      commit("RESET_FILTER", filter);
    },
    setFilters({ commit }, filters) {
      commit("SET_FILTERS", filters);
    },
    resetFilters({ commit }) {
      commit("RESET_FILTERS");
    },
  },
  mutations: {
    SAVE_INVITATIONS(state, invitations) {
      state.invitations = invitations;
    },
    SAVE_PENDING_INVITATIONS(state, invitations) {
      state.pendingInvitations = invitations;
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    RESET_FILTER(state, { filter }) {
      state.filters[filter] = null;
    },
    RESET_FILTERS(state) {
      state.filters = {
        search: "",
      };
    },
  },
  modules: {},
};
