const ProfileSideMenuItems = [
  {
    icon: "fas fa-user-circle",
    text: "Your details",
    path: "/profile/details",
    children: null,
  },
  {
    icon: "fas fa-paper-plane",
    text: "Your invites",
    path: "/profile/invites",
    children: null,
  },
];

export default ProfileSideMenuItems;
