import apiService from "@p/apiService";
// import router from '@r';
import importEvents from "@p/events/import";

export const Import = {
  namespaced: true,
  state: {
    batchImportData: null,
    batchImportErrors: null,
    batchImportResults: null,
  },
  getters: {
    batchImportData: (state) => {
      return state.batchImportData;
    },
    batchImportErrors: (state) => {
      return state.batchImportErrors;
    },
    batchImportResults: (state) => {
      return state.batchImportResults;
    },
  },
  actions: {
    uploadFile({ commit, dispatch }, file) {
      apiService
        .callApi("import", "storeProductImportBatch", file)
        .then((response) => {
          dispatch("getImportBatchData", response.data.data.uuid);
          importEvents.$emit("advance-step-product-import");
        })
        .catch((err) => {
          commit("SET_BATCH_IMPORT_ERRORS", err);
        });
    },
    getImportBatchData({ commit }, uuid) {
      apiService
        .callApi("import", "getProductImportBatch", uuid)
        .then((response) => {
          commit("SET_BATCH_IMPORT_DATA", response.data);
        })
        .then(() => {
          apiService
            .callApi("import", "getBatchProducts", uuid)
            .then((response) => {
              // router.push({name: 'import_products', params: {id: uuid}});
              commit("SET_BATCH_IMPORT_PRODUCTS", response.data);
            });
        })
        .finally(() => {
          importEvents.$emit("hide-spinner");
        });
    },
    importBatch({ commit }, uuid) {
      apiService
        .callApi("import", "processProductImportBatch", uuid)
        .then((response) => {
          commit("SET_BATCH_IMPORT_RESULTS", response.data);
          importEvents.$emit("advance-step-product-import");
        });
    },
    reset({ commit }) {
      commit("RESET");
    },
  },
  mutations: {
    SET_BATCH_IMPORT_DATA(state, data) {
      state.batchImportData = data;
    },
    SET_BATCH_IMPORT_ERRORS(state, data) {
      state.batchImportErrors = data;
    },
    SET_BATCH_IMPORT_PRODUCTS(state, data) {
      let batchImportData = Object.assign({}, state.batchImportData);
      batchImportData.products = data;
      state.batchImportData = batchImportData;
    },
    SET_BATCH_IMPORT_RESULTS(state, data) {
      state.batchImportResults = data;
    },
    RESET(state) {
      state.batchImportData = null;
      state.batchImportErrors = null;
      state.batchImportResults = null;
    },
  },
};
