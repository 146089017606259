// Application imports.
import "@/prototypes";
import "@p/validation";
import Vue from "vue";
import App from "@/App";
import router from "@r";
import Date from "@c/Date";
import IdleJs from "@p/idleJs";
import VueMeta from "vue-meta";
import vuetify from "@p/vuetify";
import VueXModularStore from "@s";
import JsonCSV from "vue-json-csv";
import { VTextField } from "vuetify/lib";
import Unauthorised from "@c/Unauthorised";
import VCurrencyField from "v-currency-field";
import Unauthenticated from "@v/Unauthenticated";

// Styles.
import "vuetify/dist/vuetify.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

// Configure Vue.
Vue.config.productionTip = false;
if (
  process.env.VUE_APP_ENV !== "prod" &&
  process.env.VUE_APP_ENV !== "production"
) {
  Vue.config.devtools = true;
}

Vue.component("v-text-field", VTextField);
Vue.component("unauthorised", Unauthorised);
Vue.component("unauthenticated", Unauthenticated);
Vue.component("downloadCsv", JsonCSV);
Vue.component("date", Date);

Vue.use(VCurrencyField, {
  decimalLength: 2,
  autoDecimalMode: true,
  min: 0,
  max: null,
  defaultValue: null,
  valueAsInteger: true,
  allowNegative: false,
});
Vue.use(VueMeta);

// Boot application
new Vue({
  router,
  store: VueXModularStore,
  vuetify,
  template: "<router-view/>",
  render: (h) => h(App),
  beforeCreate() {
    this.$store.dispatch("initialiseAuthentication");
  },
  created() {
    IdleJs.start();
  },
}).$mount("#app");
