import apiService from "@p/apiService";
import snackbarEvents from "@p/events/snackbars";
import router from "@r";
import teamEvents from "@teams/events";

export const Teams = {
  namespaced: true,
  state: {
    teams: [],
    metadata: null,

    tableHeaders: [
      { text: "Team Name", value: "name" },
      { text: "Team Code", value: "code" },
      { text: "Number Of Users", value: "usersCount" },
      { text: "Status", value: "status" },
      { text: "Billing Contacts", value: "billingEmails" },
      { text: "Billing Country", value: "billingAddress.countryName" },
      { text: "Tax ID", value: "billingVatNumber" },
      { text: "EORI", value: "eori" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  },
  getters: {
    allTeams: (state) => state.teams,
    teamByUuid: (state) => (uuid) =>
      state.teams.find((team) => team.uuid === uuid),
    tableHeaders: (state) => state.tableHeaders,
    metadata: (state) => state.metadata,
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadTeams");
    },
    loadTeams({ commit }, filters) {
      apiService
        .callApi("client", "activeClients", filters)
        .then((response) => {
          commit("SAVE_TEAMS", response.data);
          commit("SET_META", response.meta);
        });
    },
    createTeam({ dispatch }, data) {
      apiService
        .callApi("client", "createClient", data)
        .then((res) => {
          snackbarEvents.$emit("snackbar", {
            message: "Team created",
          });
          teamEvents.$emit("new-team-created", res.data);
        })
        .finally(() => {
          setTimeout(function() {
            dispatch("loadTeams");
          }, 5000);
        });
    },
    setActive({ dispatch }, uuid) {
      apiService.callApi("client", "setActive", uuid).then(() => {
        dispatch("initialise");
      });
    },
    setInactive({ dispatch }, uuid) {
      apiService.callApi("client", "setInactive", uuid).then(() => {
        dispatch("initialise");
      });
    },
  },
  mutations: {
    SAVE_TEAMS(state, teams) {
      state.teams = teams;
    },
    SET_META(state, meta) {
      state.metadata = meta;
    },
  },
};
