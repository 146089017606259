import IdleJs from "idle-js";
import AuthModule from "@s/modules/auth";
import authEvents from "./events/auth";
import idleEvents from "./events/idle";

const commitLogout = function() {
  if (authEvents.commitAutoLogout) {
    AuthModule.dispatch("logout");
  }
};

let minutes = process.env.VUE_APP_IDLE_TIMEOUT ?? 20;

let idleTimer = 60000 * minutes;

const opts = {
  idle: idleTimer,
  // idle: 1000 * 5, // 5 seconds. (Used for testing).
  events: ["mousemove", "keydown", "mousedown", "touchstart"], // these will reset the idle timer.
  onIdle: () => {
    // Will be executed after the idle timer has expired
    authEvents.commitAutoLogout = true;
    idleEvents.timer = setInterval(function() {
      if (idleEvents.secondsRemaining !== 0) {
        idleEvents.secondsRemaining--;
      }
      if (idleEvents.secondsRemaining === 0) {
        commitLogout();
      }
    }, 1000);
  },
  onActive: () => {
    // ... Do nothing.
  },
  onHide: () => {
    // ... Do nothing.
  },
  onShow: () => {
    // ... Do nothing.
  },
  keepTracking: true, // Keep tracking even after reset.
  startAtIdle: false, // Start as idle, the above events will trigger non-idle state
};

export default new IdleJs(opts);
