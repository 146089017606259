const teamSettingsSideMenuItems = [
  {
    icon: "fas fa-store",
    text: "Channels",
    path: "/TEAM_CODE/settings/channels",
    requiredPermission: "",
    children: [
      {
        text: "Add Channel",
        path: "/TEAM_CODE/settings/channels/create",
        requiredPermission: "createTeamChannels",
        icon: "fas fa-plus",
      },
      // {
      //     "text": "SKU Mapping",
      //     "path": "/TEAM_CODE/settings/channels/skumapping",
      //     "requiredPermission": "",
      //     "icon": "fas fa-random"
      // }
    ],
  },
  {
    icon: "fas fa-users",
    text: "Users",
    path: "/TEAM_CODE/settings/users",
    requiredPermission: "",
    children: [
      {
        text: "Invite new user",
        path: "/TEAM_CODE/settings/users/create",
        requiredPermission: "createInvites",
        icon: "fas fa-plus",
      },
    ],
  },
  {
    icon: "fas fa-info-circle",
    text: "Team",
    path: "/TEAM_CODE/settings/team",
    requiredPermission: "",
    children: [],
  },
  {
    icon: "fas fa-address-book",
    text: "Address Book",
    path: "/TEAM_CODE/settings/address",
    requiredPermission: "",
    children: [],
  },
  {
    icon: "fas fa-credit-card",
    text: "Billing",
    path: "/TEAM_CODE/settings/billing",
    requiredPermission: "viewTeamBilling",
    children: [],
  },
  {
    icon: "fas fa-passport",
    text: "Customs",
    path: "/TEAM_CODE/settings/customs",
    requiredPermission: "",
    children: [],
  },
  {
    icon: "fas fa-bell",
    text: "Notifications",
    path: "/TEAM_CODE/settings/notifications",
    requiredPermission: "",
    children: [],
  },
];

export default teamSettingsSideMenuItems;
