require("es6-promise").polyfill();
import Vue from "vue";
import Vuex from "vuex";

import AuthModule from "./modules/auth";

import { User } from "@users/store";
import { Admin } from "@admin/store";
import { Teams } from "@teams/store";
import { Orders } from "@orders/store";
import { Products } from "@products/store";
import { Media } from "@media/store";
import { SupplierLocations } from "@products/store/modules/supplierLocations";
import { Billing } from "@teams/store/billing";
import { Basket } from "@orders/store/modules/basket";
import { CurrentUser } from "@users/store/modules/currentUser";

import { Search } from "./modules/search";
import { Channels } from "./modules/channels";
import { Countries } from "./modules/countries";
import { Invitations } from "./modules/invitations";

const tenantAwareStores = {
  basket: Basket,
  billing: Billing,
  channels: Channels,
  countries: Countries,
  invitations: Invitations,
  media: Media,
  orders: Orders,
  products: Products,
  search: Search,
  supplierLocations: SupplierLocations,
  user: User,
};

const tenantUnawareStores = {
  admin: Admin,
  currentUser: CurrentUser,
  teams: Teams,
};

Vue.use(Vuex);

const ModularStore = new Vuex.Store({
  actions: {
    initialiseAuthentication() {
      AuthModule.dispatch("initialise");
    },
    initialiseTenantAwareStores() {
      for (const [key] of Object.entries(tenantAwareStores)) {
        this.dispatch(`${key}/initialise`);
      }
    },
    initialiseTenantUnawareStores() {
      for (const [key] of Object.entries(tenantUnawareStores)) {
        this.dispatch(`${key}/initialise`);
      }
    },
  },
  modules: {
    ...tenantAwareStores,
    ...tenantUnawareStores,
  },
});

export default ModularStore;
