import { Invited } from "./invited";
import apiService from "@p/apiService";
import snackbars from "@p/events/snackbars";
import User from "@/models/user";

export const TeamUsers = {
  namespaced: true,
  state: {
    records: [],
    recordsLoaded: false,

    record: {},
    recordLoaded: false,

    filters: null,

    tableHeaders: [
      { text: "Email", value: "email" },
      { text: "Name", value: "name" },
      // {text: "Status", value: "status"},
      { text: "Role(s)", value: "role" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  },
  getters: {
    all: (state) => {
      return state.records;
    },
    byId: (state) => (id) => {
      return state.records.find((record) => record.uuid === id);
    },
    tableHeaders: (state) => {
      return state.tableHeaders;
    },
    record: (state) => {
      return state.record;
    },
    filters: (state) => {
      return state.filters;
    },
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadRecords");
      dispatch("resetFilters");
    },
    loadRecords({ commit }) {
      let currentTeam = apiService.requestOpts.xClientUuid;
      apiService
        .callApi("teamUser", "getUsers", currentTeam)
        .then((response) => {
          commit("SAVE_RECORDS", response.data);
        });
    },
    removeUser({ dispatch }, userUuid) {
      let currentTeam = apiService.requestOpts.xClientUuid;
      apiService
        .callApi("teamUser", "removeUser", currentTeam, userUuid)
        .then(() => {
          dispatch("loadRecords");
          snackbars.$emit("snackbar", {
            message: "User removed successfully",
          });
          location.reload();
        });
    },
    update({ dispatch }, userData) {
      apiService.callApi("user", "updateUser", userData).then(() => {
        dispatch("initialise");
        snackbars.$emit("snackbar", {
          message: `User ${userData.firstName} ${userData.lastName} has been updated.`,
        });
      });
    },
    setSelected({ commit }, record) {
      commit("SET_SELECTED", record);
    },
    resetSelected({ commit }) {
      commit("RESET_SELECTED");
    },
    setFilter({ commit }, data) {
      commit("SET_FILTER", data);
    },
    setFilters({ commit }, data) {
      commit("SET_FILTERS", data);
    },
    resetFilter({ commit }, filter) {
      commit("RESET_FILTER", filter);
    },
    resetFilters({ commit }) {
      commit("RESET_FILTERS");
    },
  },
  mutations: {
    SAVE_RECORDS(state, records) {
      state.records = records.map((user) => new User(user));
    },
    ADD_RECORD(state, record) {
      state.records.push(record);
    },
    SET_SELECTED(state, record) {
      state.record = record;
    },
    RESET_SELECTED(state) {
      state.record = {};
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    RESET_FILTER(state, { filter }) {
      state.filters[filter] = null;
    },
    RESET_FILTERS(state) {
      state.filters = {
        search: "",
        teams: ["team 1", "team 2", "team 3"],
      };
    },
    UPDATE_RECORD(state, recordData) {
      // TODO: Implement API action for updating teamUsers.
      let teamUsers = state.records;

      const teamUserId = parseInt(recordData[0]);
      const newTeamUserData = recordData[1];

      let ids = teamUsers.map((teamUser) => teamUser.id);
      let index = ids.indexOf(teamUserId);

      let dataAsArrays = Object.entries(newTeamUserData);
      for (const [key, value] of dataAsArrays) {
        teamUsers[index][key] = value;
        // TODO: Send teamUsers[index] to the API for updates in the backend.
      }

      state.records = teamUsers;
    },
  },
  modules: {
    invitedUsers: Invited,
  },
};
