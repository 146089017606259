<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <p class="text-center text-h4 pl-4 grey--text darken-1 lato-font">
          <slot>You are not authorised to view this page.</slot>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Unauthorised",
};
</script>

<style scoped>
</style>