import apiService from "@p/apiService";

const PTApi = require("print_trail");

export const GeneralApi = new PTApi.GeneralApi();

export default {
  getCountries() {
    let opts = apiService.getRequestOpts();
    return GeneralApi.generalGetCountries(opts);
  },
  getTariffCodes(filters) {
    let opts = apiService.getRequestOpts();
    for (const [key, value] of Object.entries(filters)) {
      opts[key] = value;
    }
    return GeneralApi.generalGetTariffCodes(opts);
  },
  getSupplierLocations() {
    let opts = apiService.getRequestOpts();
    return GeneralApi.generalGetSupplierLocations(opts);
  },
};
