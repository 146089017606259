import Vue from "vue";

export default new Vue({
  data: {
    snackbarMessage: null,
    showSnackbar: false,
    details: null,
    errorMessage: null,
    scrollUp: false,
    persistent: false,
    type: "success",
    showIcon: true,
    colour: null,
  },
});
