const SettingsNavItems = [
  {
    text: "Channels",
    route: "/TEAM_CODE/settings/channels",
    icon: "fas fa-store",
    createIcon: "",
    requiredPermission: "",
  },
  {
    text: "Users",
    route: "/TEAM_CODE/settings/users",
    icon: "fas fa-users",
    createIcon: "",
    requiredPermission: "",
  },
  {
    text: "Team",
    route: "/TEAM_CODE/settings/team",
    icon: "fas fa-info-circle",
    createIcon: "",
    requiredPermission: "",
  },
  {
    text: "Address Book",
    route: "/TEAM_CODE/settings/address",
    icon: "fas fa-address-book",
    createIcon: "",
    requiredPermission: "",
  },
  {
    text: "Billing",
    route: "/TEAM_CODE/settings/billing",
    icon: "fas fa-credit-card",
    createIcon: "",
    requiredPermission: "",
  },
  {
    text: "Customs",
    route: "/TEAM_CODE/settings/customs",
    icon: "fas fa-passport",
    createIcon: "",
    requiredPermission: "",
  },
  {
    text: "Notifications",
    route: "/TEAM_CODE/settings/notifications",
    icon: "fas fa-bell",
    createIcon: "",
    requiredPermission: "",
  },
];

export default SettingsNavItems;
