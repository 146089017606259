const Profile = () => import("@users/views/Profile");
const Invites = () => import("@users/views/Invites");

// ADMIN

const routes = [
  {
    path: "/profile",
    component: {
      render(component) {
        return component("router-view");
      },
    },
    meta: {
      breadcrumb: "Your Profile",
      title: "Your Profile",
    },
    children: [
      {
        path: "",
        name: "profile",
        redirect: { name: "details" },
        meta: {
          tab: "Settings",
          title: "Settings",
        },
      },
      {
        path: "details",
        name: "details",
        component: Profile,
        meta: {
          breadcrumb: "Your Details",
          title: "Your Details",
        },
      },
      {
        path: "invites",
        name: "profile_invites",
        component: Invites,
        meta: {
          breadcrumb: "Your Invites",
          title: "Your Invites",
        },
      },
    ],
  },
];

export default routes;
