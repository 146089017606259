import Vue from "vue";

export const ORIGINAL_TIMEOUT = 30;

export default new Vue({
  data: {
    timer: null,
    secondsRemaining: 30,
  },
  methods: {
    reset() {
      clearInterval(this.timer);
      this.secondsRemaining = ORIGINAL_TIMEOUT;
    },
  },
});
