import apiService from "@p/apiService";
import imageEvents from "@p/events/images";

export const Pod = {
  namespaced: true,
  state: {
    productPods: [],
    productPodsLoaded: false,

    podTypes: [],
    podTypesLoaded: false,

    statusColours: {},

    podTypeMetadata: {},

    artworkUploadUrl: "",
  },
  getters: {
    productPods: (state) => {
      return state.productPods;
    },
    allPodTypes: (state) => {
      return state.podTypes;
    },
    podTypeByUuid: (state) => (podTypeUuid) => {
      return state.podTypes.find((podType) => podType.uuid === podTypeUuid);
    },
    statusColours: (state) => {
      return state.statusColours;
    },
    podTypeMetadata: (state) => (podTypeUuid) => {
      return state.podTypeMetadata[podTypeUuid];
    },
    allPodMetadata: (state) => {
      return state.podTypeMetadata;
    },
    artworkUploadUrl: (state) => {
      return state.artworkUploadUrl;
    },
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadPodTypes");
      dispatch("loadStatusColours");
    },
    loadProductPods({ commit }, productUuid) {
      apiService
        .callApi("pod", "getProductPods", productUuid)
        .then((response) => {
          commit("SAVE_PODS", response.data);
        });
    },
    loadPodTypes({ commit }) {
      apiService.callApi("pod", "allPodTypes").then((response) => {
        let podTypes = response.data;

        commit("SAVE_POD_TYPES", podTypes);
      });
    },
    loadPodTypeMetadata({ commit }, podTypeUuid) {
      commit("LOAD_POD_TYPE_METADATA", podTypeUuid);
    },
    loadStatusColours({ commit }) {
      commit("SET_STATUS_COLOURS");
    },
    createNewDraftPod({ commit }, payload) {
      commit("CREATE_NEW_DRAFT_POD", payload);
    },
    updatePodVersion({ commit }, payload) {
      commit("UPDATE_POD_VERSION", payload);
    },
    getArtworkUploadUrl({ commit }, data) {
      apiService.callApi("pod", "uploadProductPodArtwork", data).then((res) => {
        commit("STORE_SIGNED_UPLOAD_URL", res.preSignedUrl);
      });
    },
    uploadPodArtworkToS3({ commit }, file) {
      commit("UPLOAD_POD_ARTWORK_TO_S3", file);
    },
    resetPodArtworkUploadUrl({ commit }) {
      commit("RESET_PRESIGNED_URL");
    },
    submitDraftPodForApproval({ commit }, productUuid) {
      commit("SUBMIT_DRAFT_POD", productUuid);
    },
    reviewPodData({ commit }, data) {
      commit("REVIEW_POD_DATA", data);
    },
    clonePod({ commit }, data) {
      commit("CLONE_POD", data);
    },
    deletePod({ commit }, data) {
      commit("DELETE_POD", data);
    },
    deletePodData({ commit }, data) {
      commit("DELETE_POD_DATA", data);
    },
  },
  mutations: {
    SAVE_PODS(state, productPods) {
      state.productPods = productPods;
      state.productPodsLoaded = true;
    },
    SAVE_POD_TYPES(state, podTypes) {
      state.podTypes = podTypes;
      state.podTypesLoaded = true;
    },
    SET_STATUS_COLOURS(state) {
      state.statusColours = {
        draft: "grey",
        pending: "warning",
        approved: "success",
        rejected: "error",
        cancelled: "error",
      };
    },
    CREATE_NEW_DRAFT_POD(state, payload) {
      let newPayload = {
        podTypeUuid: payload.podTypeUuid,
        data: payload.data,
      };
      apiService
        .callApi("pod", "updateProductPod", payload.productUuid, newPayload)
        .then((response) => {
          state.productPods = response.data;
        });
    },
    UPDATE_POD_VERSION(state, payload) {
      let productUuid = payload[0];
      let data = payload[1];

      apiService
        .callApi("pod", "updateProductPod", productUuid, data)
        .then((response) => {
          state.productPods = response.data;
        });
    },
    LOAD_POD_TYPE_METADATA(state, podTypeUuid) {
      apiService
        .callApi("pod", "getPodTypeMetaData", podTypeUuid)
        .then((response) => {
          let metadata = {};
          metadata[podTypeUuid] = response.data;
          state.podTypeMetadata = {
            ...state.podTypeMetadata,
            ...metadata,
          };
        });
    },
    STORE_SIGNED_UPLOAD_URL(state, uploadUrl) {
      state.artworkUploadUrl = uploadUrl;
    },
    UPLOAD_POD_ARTWORK_TO_S3(state, file) {
      let xhr = new XMLHttpRequest();
      xhr.open("put", state.artworkUploadUrl);
      xhr.setRequestHeader("Content-Type", file.type);

      xhr.onreadystatechange = function() {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          apiService.media
            .confirmUploadSuccess(state.artworkUploadUrl)
            .then(() => {
              imageEvents.$emit(
                "successful-image-upload",
                "Image uploaded successfully!"
              );
            })
            .catch(() => {
              imageEvents.$emit(
                "unsuccessful-image-upload",
                "Failed to confirm uploaded image."
              );
            });
        }
        if (this.readyState === XMLHttpRequest.DONE && this.status !== 200) {
          imageEvents.$emit(
            "unsuccessful-image-upload",
            "Failed to upload image."
          );
        }
      };

      xhr.send(file);
    },
    RESET_PRESIGNED_URL(state) {
      state.artworkUploadUrl = "";
    },
    SUBMIT_DRAFT_POD(state, productUuid) {
      apiService
        .callApi("pod", "submitProductPod", productUuid)
        .then((response) => {
          state.productPods = response.data;
        });
    },
    REVIEW_POD_DATA(state, data) {
      apiService.callApi("pod", "reviewPodData", data[0], data[1], data[2]);
    },
    CLONE_POD(state, productUuid) {
      apiService.callApi("pod", "clonePod", productUuid).then(() => {
        apiService
          .callApi("pod", "getProductPods", productUuid)
          .then((response) => {
            state.productPods = response.data;
          });
      });
    },
    DELETE_POD(state, data) {
      apiService
        .callApi("pod", "deletePod", data[0], data[1])
        .then((response) => {
          state.productPods = response.data;
        });
    },
    DELETE_POD_DATA(state, data) {
      apiService.callApi("pod", "deletePodData", data[0], data[1]).then(() => {
        apiService
          .callApi("pod", "getProductPods", data[0])
          .then((response) => {
            state.productPods = response.data;
          });
      });
    },
  },
  modules: {},
};
