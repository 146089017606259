export class ShippingZone {
  properties = {
    code: String,
    name: String,
  };

  constructor(data) {
    let shippingZone = this;
    for (const [key, dataType] of Object.entries(this.properties)) {
      switch (dataType) {
        case String:
          shippingZone[key] = data[key] ?? null;
          break;
        default:
          shippingZone[key] = data[key] ?? null;
          break;
      }
    }
  }
}
