import apiService from "@p/apiService";
import orderEvents from "@orders/events";

import { Order } from "@/models/order";

import { Fulfilments } from "./modules/fulfilments";
import { Import } from "./modules/import";
import snackbars from "@p/events/snackbars";

export const Orders = {
  namespaced: true,
  state: {
    records: [],
    recordsLoaded: false,
    recordsMeta: {},

    record: {},
    recordLoaded: false,

    filters: null,

    newOrder: null,

    tableHeaders: [
      { text: "Order Number", value: "externalOrderNumber" },
      { text: "Channel", value: "channel" },
      { text: "Order Status", value: "status" },
      // Order date format should be YYYY-MM-DD
      { text: "Order Date", value: "createdAt" },
      // "Name" values should be first and last name concatenated, eg "John Smith" we will add the function to do this once we have a first name and second from the API
      { text: "Name", value: "name" },
      { text: "Postcode", value: "shipping.postcode" },
      { text: "Country", value: "shipping.countryCode" },
      { text: "Total Items", value: "totalItem", align: "right" },
      // "Fulfilments" values should just be integers between 1–9
      // {text: "Fulfilments", value: "fulfilments"},
      { text: "Actions", value: "actions", sortable: false },
    ],

    statusColours: {
      draft: "grey",
      queued: "blue",
      holding: "orange",
      processing: "blue",
      complete: "green",
      cancelled: "grey",
      "cancel-pending": "grey",
      "back-order": "orange",
      returned: "purple",
      invalid: "red",
      error: "red",
      partial: "light blue",
      failed: "red",
    },
  },
  getters: {
    all: (state) => {
      return state.records;
    },
    byId: (state) => (id) => {
      return state.records.find((record) => record.uuid === id);
    },
    metadata: (state) => {
      return state.recordsMeta;
    },
    newOrderData: (state) => {
      return state.newOrder;
    },
    tableHeaders: (state) => {
      return state.tableHeaders;
    },
    filters: (state) => {
      return state.filters;
    },
    record: (state) => {
      return state.record;
    },
    statusColours: (state) => {
      return state.statusColours;
    },
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadRecords");
      dispatch("resetFilters");
    },
    loadRecords({ commit }, options) {
      apiService.callApi("orders", "getOrders", options).then((response) => {
        commit("SAVE_RECORDS", JSON.parsify(response.data));
        commit("SAVE_METADATA", JSON.parsify(response.meta));
      });
    },
    loadRecord({ commit }, uuid) {
      apiService.callApi("orders", "getOrder", uuid).then((response) => {
        commit("ADD_RECORD", JSON.parsify(response.data));
      });
    },
    instantiateNewOrder({ commit }) {
      commit("INSTANTIATE_NEW_ORDER");
    },
    setNewOrderProducts({ commit }, products) {
      commit("SET_NEW_ORDER_PRODUCTS", products);
    },
    setNewOrderAddressData({ commit }, addressData) {
      commit("SET_NEW_ORDER_ADDRESS_DATA", addressData);
    },
    storeNewOrder({ commit }) {
      commit("STORE_NEW_ORDER");
    },
    setSelected({ commit }, record) {
      commit("SET_SELECTED", record);
    },
    resetSelected({ commit }) {
      commit("RESET_SELECTED");
    },
    resetStore({ commit }) {
      commit("RESET_STORE");
    },
    setFilter({ commit }, data) {
      commit("SET_FILTER", data);
    },
    resetFilter({ commit }, filter) {
      commit("RESET_FILTER", filter);
    },
    resetFilters({ commit }) {
      commit("RESET_FILTERS");
    },
    cloneBulkOrders({ commit }, recordIds) {
      commit("CLONE_BULK_ORDERS", recordIds);
      orderEvents.$emit('reset-filters');
    },
    cloneOrder({ commit }, orderUuid) {
      commit("CLONE_ORDER", orderUuid);
    },
    update({ commit }, recordData) {
      commit("UPDATE_RECORD", recordData);
    },
    cancelBulkOrders({ commit }, uuids) {
      commit("CANCEL_BULK_ORDERS", uuids);
      orderEvents.$emit('reset-filters');
    },
    cancelOrder({ commit }, uuid) {
      commit("CANCEL_ORDER", uuid);
    },
    submitOrder({ commit }, orderUuid) {
      commit("SUBMIT_ORDER", orderUuid);
    },
    submitBulkOrders({ commit }, orderUuids) {
      commit("SUBMIT_BULK_ORDERS", orderUuids);
      orderEvents.$emit('reset-filters');
    },
    cancelFulfilments({ commit }, fulfilmentUuids) {
      commit("CANCEL_FULFILMENTS", fulfilmentUuids);
    },
    convertOrderToDraft({ dispatch }, payload) {
      let orderUuid = payload[0];
      let redirectToEdit = !!payload[1];
      apiService
        .callApi("orders", "convertToDraft", orderUuid)
        .then((response) => {
          if (response.data.status === "draft") {
            snackbars.$emit("snackbar", {
              message: "Converted to draft",
            });
          }
          dispatch("initialise");

          if (redirectToEdit) {
            orderEvents.$emit("redirect-to-edit-order", orderUuid);
          }
        })
        .catch(() => {
          orderEvents.$emit("convert-to-draft-error");
          snackbars.$emit("snackbar", {
            message: "Address and products can no longer be edited for this order",
            colour: "red",
            type: "error"
          });
          dispatch("initialise");
        });
    },
    updateMinorDetails({dispatch}, payload) {
      apiService.callApi("orders", "updateMinorDetails", payload).then(res => {
        dispatch("initialise");
        snackbars.$emit('snackbar', {
          message: 'Customer tax/contact details updated'
        });
      });
    },
    processFulfilments({commit}, fulfilmentUuids) {
      commit("PROCESS_FULFILMENTS", fulfilmentUuids);
    }
  },
  mutations: {
    SAVE_RECORDS(state, records) {
      state.records = records.map((record) => new Order(record));
    },
    SAVE_METADATA(state, metadata) {
      state.recordsMeta = metadata;
    },
    INSTANTIATE_NEW_ORDER(state) {
      state.newOrder = {
        poNumber: "",
        phone: "",
        shipping: {},
        products: [],
      };
    },
    SET_NEW_ORDER_PRODUCTS(state, products) {
      let newOrder = Object.assign({}, state.newOrder);
      newOrder.products = products.map((product) => {
        return {
          uuid: product.uuid,
          quantity: product.quantity,
        };
      });
      state.newOrder = newOrder;
    },
    SET_NEW_ORDER_ADDRESS_DATA(state, shippingData) {
      let newOrder = Object.assign({}, state.newOrder);
      newOrder.shipping = shippingData;
      state.newOrder = newOrder;
    },
    ADD_RECORD(state, record) {
      state.records.push(new Order(record));
    },
    SET_SELECTED(state, record) {
      state.record = record;
    },
    RESET_SELECTED(state) {
      state.record = {};
    },
    SET_FILTERS(state, data) {
      state.filters = data;
    },
    RESET_FILTER(state, { filter }) {
      state.filters[filter] = null;
    },
    RESET_FILTERS(state) {
      state.filters = {
        search: "",
        status: "",
        dateFrom: "",
        dateTo: "",
        statusTypes: ["new", "pending", "complete", "cancelled"],
        channel: "",
        channels: [],
        destination: "",
        destinations: [],
      };
    },
    UPDATE_RECORD(state, recordData) {
      let orderUuid = recordData.uuid;

      let newOrderData = state.newOrder;

      let orderData = {
        PONumber: recordData.PONumber,
        phone: recordData.shipping.phone,
        ...newOrderData,
      };

      apiService
        .callApi("orders", "updateOrder", orderUuid, orderData)
        .then((response) => {
          state.record = response.data;
        });
    },
    SUBMIT_ORDER(state, orderUuid) {
      apiService.callApi("orders", "submitOrder", orderUuid).then(() => {
        snackbars.$emit("snackbar", {
          message: `Queued for processing.`,
        });
      });
    },
    SUBMIT_BULK_ORDERS(state, orderUuids) {
      apiService
        .callApi("orders", "submitBulkOrders", { uuids: orderUuids })
        .then(() => {
          snackbars.$emit("snackbar", {
            message: "Order(s) queued for processing",
          });
        });
    },
    CLONE_BULK_ORDERS(state, orderUuids) {
      apiService
        .callApi("orders", "cloneBulkOrders", { uuids: orderUuids })
        .then(() => {
          this.dispatch("orders/loadRecords");

          snackbars.$emit("snackbar", {
            message: `Order(s) copied`,
          });
        });
    },
    CLONE_ORDER(state, orderUuid) {
      apiService.callApi("orders", "cloneOrder", orderUuid).then((response) => {
        this.dispatch("orders/loadRecords");
        snackbars.$emit("snackbar", {
          message: `Order copied`,
        });
        orderEvents.$emit("open-order-details", response.data.uuid);
      });
    },
    CANCEL_BULK_ORDERS(state, orderUuids) {
      apiService
        .callApi("orders", "cancelBulkOrders", { uuids: orderUuids })
        .then(() => {
          this.dispatch("orders/loadRecords");
          snackbars.$emit("snackbar", {
            message: `Attempting to cancel ${orderUuids.length} orders and their fulfilments`,
            showIcon: false,
          });
        });
    },
    CANCEL_ORDER(state, orderUuid) {
      let orderData = null;
      orderData = state.records.find((order) => order.uuid === orderUuid);

      apiService.callApi("orders", "cancelOrder", orderUuid).then(() => {
        this.dispatch("orders/loadRecords");
        snackbars.$emit("snackbar", {
          message: `Attempting to cancel order ${orderData.ptOrderNumber} and its fulfilments`,
          showIcon: false,
        });
      });
    },
    CANCEL_FULFILMENTS(state, fulfilmentUuids) {
      apiService
        .callApi("orders", "cancelFulfilments", { uuids: fulfilmentUuids })
        .then(() => {
          this.dispatch("orders/loadRecords");
          if (fulfilmentUuids.length === 1) {
            snackbars.$emit("snackbar", {
              message: `Attempting to cancel fulfilment`,
            });
          } else {
            snackbars.$emit("snackbar", {
              message: `Attempting to cancel ${fulfilmentUuids.length} fulfilments`,
            });
          }
        });
    },
    PROCESS_FULFILMENTS(state, fulfilmentUuids) {
      apiService
        .callApi("orders", "processFulfilments", { uuids: fulfilmentUuids })
        .then(() => {
          this.dispatch("orders/loadRecords");
          if (fulfilmentUuids.length === 1) {
            snackbars.$emit("snackbar", {
              message: `Attempting to process fulfilment`,
            });
          } else {
            snackbars.$emit("snackbar", {
              message: `Attempting to process ${fulfilmentUuids.length} fulfilments`,
            });
          }
        });
    },
    RESET_STORE(state) {
      state.newOrder = {};
      state.record = {};
    },
  },
  modules: {
    fulfilments: Fulfilments,
    import: Import,
  },
};
