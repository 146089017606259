const Reports = () => import("../views/reports/All.vue");

// REPORTS
const routes = [
  {
    path: "reports",
    component: {
      render(component) {
        return component("router-view");
      },
    },
    meta: {},
    children: [
      {
        path: "",
        name: "reports",
        component: Reports,
        meta: {
          tab: "Reports",
          title: "Reports",
        },
      },
    ],
  },
];

export default routes;
