import Vue from "vue";

export default new Vue({
  data: {
    showDialog: false,
    dialogTitle: null,
    dialogText: null,
    confirmText: null,
    cancelText: null,
    dismissable: true,
    dialogResult: null,
    isTerminal: false,

    alertType: null,
    alertMessage: null,
    showAlert: false,
  },
  methods: {
    triggerDialog(dialogOpts) {
      this.showDialog = true;
      this.dialogText = dialogOpts.dialogText;
      this.dialogTitle = dialogOpts.dialogTitle;
      this.confirmText = dialogOpts.confirmText;
      this.cancelText = dialogOpts.cancelText;
      this.dissmissable = dialogOpts.dissmissable;

      this.isTerminal = dialogOpts.isTerminal && dialogOpts.isTerminal === true;
    },
    hideDialog() {
      return new Promise((resolve) => {
        this.showDialog = false;
        return resolve;
      });
    },
    resetDialog() {
      let self = this;
      this.hideDialog().then(() => {
        self.dialogTitle = null;
        self.dialogText = null;
        self.confirmText = null;
        self.cancelText = null;
        self.dismissable = true;
        self.dialogResult = null;
        self.isTerminal = false;
      });
    },
    showSuccessDialog(
      text = "Operation completed successfully.",
      confirmButtonText = "Ok"
    ) {
      let dialogOpts = {
        dialogTitle: "Success",
        dialogText: text,
        confirmText: confirmButtonText,
        cancelText: null,
      };

      this.$emit("show-dialog", dialogOpts);
    },
  },
});
