import apiService from "@p/apiService";

export const Search = {
  namespaced: true,
  state: {
    records: [],
    isLoading: false,
  },
  getters: {
    results: (state) => {
      return state.records;
    },
    isLoading: (state) => {
      return state.isLoading;
    },
  },
  actions: {
    initialise() {
      // Do nothing...
    },
    setLoading({ commit }, isLoading) {
      commit("SET_LOADING", isLoading);
    },
    productSearch({ commit }, searchString) {
      commit("SET_LOADING", true);

      if (searchString === "" || !searchString) {
        commit("SET_LOADING", false);
        commit("SAVE_RECORDS", []);
        return;
      }

      let options = {
        filterSearch: searchString,
        filterStatus: "active,pre-order",
        perPage: 10,
      };

      apiService
        .callApi("products", "getProducts", options)
        .then((response) => {
          commit("SAVE_RECORDS", response.data);
          commit("SET_LOADING", false);
        });
    },
    globalSearch({ commit }, searchString) {
      commit("SET_LOADING", true);
      let results = [];

      if (searchString === "" || !searchString) {
        commit("SET_LOADING", false);
        commit("SAVE_RECORDS", results);
        return;
      }

      let options = {
        filterSearch: searchString,
        perPage: 5,
      };

      apiService.callApi("orders", "getOrders", options).then((response) => {
        results = results.concat(JSON.parse(JSON.stringify(response.data)));

        apiService
          .callApi("products", "getProducts", options)
          .then((response) => {
            results = results.concat(JSON.parse(JSON.stringify(response.data)));
          })
          .then(() => {
            commit("SAVE_RECORDS", results);
            commit("SET_LOADING", false);
          });
      });
    },
  },
  mutations: {
    SAVE_RECORDS(state, records) {
      state.records = records;
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  modules: {},
};
