import apiService from "@p/apiService";
import authEvents from "@p/events/auth";
import UserClass from "@/models/user";

export const User = {
  namespaced: true,
  state: {
    user: {},
    teamInvitations: [],
    teamInvitationsMeta: {},
    validationRules: {
      email: {},
      password: {},
    },
  },
  getters: {
    teamInvitations: (state) => state.teamInvitations,
    emailValidationRules: (state) => state.validationRules.email,
    passwordValidationRules: (state) => state.validationRules.password,
    user: (state) => state.user,
    role: (state) => state.user.role,
    permissions: (state) => state.user.permissions,
  },
  actions: {
    initialise({ dispatch }) {
      // dispatch('getEmailValidationRules');
      // dispatch('getPasswordValidationRules');
      dispatch("getUserTeamInvitations");
      dispatch("getUserDetails");
    },
    getUserDetails({ commit }) {
      apiService.callApi("user", "getUserDetails").then((response) => {
        let user = new UserClass(response.data);
        commit("SET_USER", user);
      });
    },
    getEmailValidationRules({ commit }) {
      apiService.callApi("user", "getEmailValidationRules").then((response) => {
        commit("SAVE_EMAIL_RULES", response.data);
      });
    },
    getPasswordValidationRules({ commit }) {
      apiService
        .callApi("user", "getPasswordValidationRules")
        .then((response) => {
          commit("SAVE_PASSWORD_RULES", response.data);
        });
    },
    storeNewPassword({}, passwordData) {
      apiService
        .callApi("user", "changeUserPassword", passwordData)
        .then(() => {
          authEvents.$emit("force-logout");
        });
    },
    storeNewEmail({}, emailData) {
      apiService.callApi("user", "changeUserEmail", emailData).then(() => {
        authEvents.$emit("force-logout");
      });
    },
    getUserTeamInvitations({ commit }, timeout = 0) {
      setTimeout(() => {
        apiService
        .callApi("invitations", "getUserInvitations")
        .then((response) => {
          commit("SAVE_USER_TEAM_INVITATIONS", response.data);
          commit("SAVE_USER_TEAM_INVITATIONS_META", response.meta);
        });
      }, timeout);
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SAVE_EMAIL_RULES(state, rules) {
      state.validationRules.email = rules;
    },
    SAVE_PASSWORD_RULES(state, rules) {
      state.validationRules.password = rules;
    },
    SAVE_USER_TEAM_INVITATIONS(state, invitations) {
      state.teamInvitations = invitations;
    },
    SAVE_USER_TEAM_INVITATIONS_META(state, metaData) {
      state.teamInvitationsMeta = metaData;
    },
  },
  modules: {},
};
