const productsNavItems = [
  {
    text: "All Products",
    route: "/TEAM_CODE/products/",
    icon: "fas fa-tags",
    createIcon: "",
    requiredPermission: "",
  },
  {
    text: "Create Product",
    route: "/TEAM_CODE/products/create",
    icon: "fas fa-plus",
    createIcon: "fas fa-tags",
    requiredPermission: "createProducts",
  },
  {
    text: "Inventory",
    route: "/TEAM_CODE/products/inventory",
    icon: "fas fa-industry",
    createIcon: "",
    requiredPermission: "",
  },
  {
    text: "Import Products",
    route: "/TEAM_CODE/products/import",
    icon: "fas fa-cloud-upload-alt",
    createIcon: "fas fa-cloud-upload-alt",
    requiredPermission: "createProducts",
  },
];

export default productsNavItems;
