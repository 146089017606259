import apiService from "@p/apiService";
import snackbars from "@p/events/snackbars";
import channelEvents from "@p/events/channels";

export const Channels = {
  namespaced: true,
  state: {
    records: [],
    recordsLoaded: false,

    record: {},
    recordLoaded: false,

    tokens: [],

    filters: null,

    tableHeaders: [
      { text: "Name", value: "name" },
      { text: "ID", value: "id" },
      // {text: "Mode", value: "mode"},
      { text: "Active?", value: "isActive" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  },
  getters: {
    all: (state) => {
      return state.records;
    },
    byId: (state) => (id) => {
      return state.records.find((record) => record.uuid === id);
    },
    tableHeaders: (state) => {
      return state.tableHeaders;
    },
    filters: (state) => {
      return state.filters;
    },
    record: (state) => {
      return state.record;
    },
    tokens: (state) => {
      return state.tokens;
    },
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadRecords");
      dispatch("resetFilters");
    },
    loadRecords({ commit }) {
      apiService.channels.getChannels().then((response) => {
        commit("SAVE_RECORDS", response.data);
        commit("SET_TOKENS", []);
      });
    },
    resetTokens({ commit }) {
      commit("SET_TOKENS", []);
    },
    createApiKey({ dispatch }, data) {
      let channelUuid = data[0];
      let apiKeyData = data[1];
      apiService
        .callApi("channels", "storeChannelToken", channelUuid, apiKeyData)
        .then(() => {
          dispatch("initialise");
        });
    },
    getChannelTokens({ commit }, channelUuid) {
      apiService
        .callApi("channels", "getChannelTokens", channelUuid)
        .then((response) => {
          commit("SET_TOKENS", response.data);
        });
    },
    setActive({ dispatch }, data) {
      let channelUuid = data[0];
      let active = data[1];

      if (active === true) {
        apiService
          .callApi("channels", "setChannelActive", channelUuid)
          .then(() => {
            snackbars.$emit("snackbar", {
              message: "Channel is now active",
            });
            dispatch("loadRecords");
          });
      } else {
        apiService
          .callApi("channels", "setChannelInactive", channelUuid)
          .then(() => {
            snackbars.$emit("snackbar", {
              message: "Channel is now inactive",
            });
            dispatch("loadRecords");
          });
      }
    },
    create({ dispatch }, data) {
      apiService.callApi("channels", "createChannel", data).then((response) => {
        dispatch("loadRecords");
        channelEvents.$emit("open-channel-details", response.data.uuid);
      });
    },
    deleteApiKey({ dispatch }, data) {
      let channelUuid = data[0];
      let tokenUuid = data[1];
      apiService
        .callApi("channels", "deleteChannelToken", channelUuid, tokenUuid)
        .then(() => {
          dispatch("loadRecords");
          snackbars.$emit("snackbar", {
            message: "API credentials deleted",
          });
        });
    },
    deleteChannel({ dispatch }, uuid) {
      apiService.callApi("channels", "deleteChannel", uuid).then(() => {
        dispatch("loadRecords");
        snackbars.$emit("snackbar", {
          message: "Channel Disconnected",
        });
      });
    },
    setSelected({ commit }, record) {
      commit("SET_SELECTED", record);
    },
    resetSelected({ commit }) {
      commit("RESET_SELECTED");
    },
    setFilter({ commit }, data) {
      commit("SET_FILTER", data);
    },
    resetFilter({ commit }, filter) {
      commit("RESET_FILTER", filter);
    },
    setFilters({ commit }, filters) {
      commit("SET_FILTERS", filters);
    },
    resetFilters({ commit }) {
      commit("RESET_FILTERS");
    },
    copyRecord({ commit }, record) {
      commit("ADD_RECORD", record);
    },
    update({ dispatch }, data) {
      let channelUuid = data[0];
      let channelData = data[1];
      apiService
        .callApi("channels", "updateChannel", channelUuid, channelData)
        .then(() => {
          dispatch("loadRecords");
        });
    },
  },
  mutations: {
    SET_TOKENS(state, data) {
      state.tokens = data;
    },
    SAVE_RECORDS(state, records) {
      state.records = records;
    },
    ADD_RECORD(state, record) {
      state.records.push(record);
    },
    SET_SELECTED(state, record) {
      state.record = record;
    },
    RESET_SELECTED(state) {
      state.record = {};
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    RESET_FILTER(state, { filter }) {
      state.filters[filter] = null;
    },
    RESET_FILTERS(state) {
      state.filters = {
        search: "",
        status: "",
        dateFrom: "",
        dateTo: "",
        statusTypes: ["new", "pending", "complete", "cancelled"],
        channel: "",
        channels: [],
        destination: "",
        destinations: [],
        currency: "",
      };
    },
  },
  modules: {},
};
