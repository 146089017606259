import apiService from "@p/apiService";
import snackbars from "@p/events/snackbars";
import inviteEvents from "@p/events/invites";
import authEvents from "@p/events/auth";
import teamEvents from "@teams/events";

export const Invitations = {
  namespaced: true,
  state: {
    records: [],
    recordsLoaded: false,

    record: {},
    recordLoaded: false,

    filters: null,

    tableHeaders: [
      { text: "Email", value: "email" },
      { text: "First Name", value: "firstName" },
      { text: "Last Name", value: "lastName" },
      { text: "Status", value: "status" },
      { text: "Role(s)", value: "roles" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  },
  getters: {
    all: (state) => {
      return state.records;
    },
    byId: (state) => (id) => {
      return state.records.find((record) => record.id === id);
    },
    tableHeaders: (state) => {
      return state.tableHeaders;
    },
    record: (state) => {
      return state.record;
    },
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadRecords");
    },
    loadRecords({ commit }) {
      apiService.callApi("invitations", "getInvitations").then((response) => {
        commit("SAVE_RECORDS", response.data);
      });
    },
    setSelected({ commit }, record) {
      commit("SET_SELECTED", record);
    },
    resetSelected({ commit }) {
      commit("RESET_SELECTED");
    },
    getInvitationValidationRules({ commit }) {
      apiService
        .callApi("invitations", "getCreateValidationRules")
        .then((response) => {
          commit("SAVE_VALIDATION_RULES", response.data);
        });
    },
    createInvitation({ dispatch }, invitationData) {
      apiService
        .callApi("invitations", "createInvitation", invitationData)
        .then(() => {
          snackbars.$emit("snackbar", {
            message: `${invitationData.email} has been emailed an invitation link to join the ${invitationData.team} team`,
          });
        })
        .then(() => {
          dispatch("initialise");
        });
    },
    getInvitationAction({ commit }, token) {
      apiService
        .callApi("invitations", "invitationAction", token)
        .then((response) => {
          commit("SET_SELECTED", response.data);
          inviteEvents.$emit("finished-invite-fetch");
        })
        .catch(() => {
          inviteEvents.$emit("finished-invite-fetch");
        });
    },
    acceptInvitation({ dispatch }, invitation) {
      apiService
        .callApi("invitations", "acceptInvitation", invitation.uuid)
        .then((res) => {
          snackbars.$emit("snackbar", {
            message: `You have now joined the ${invitation.team} team`,
          });
          teamEvents.$emit("joined-new-team", invitation.team);
        });
    },
    rejectInvitation({ dispatch }, invitation) {
      apiService
        .callApi("invitations", "rejectInvitation", invitation.uuid)
        .then(() => {
          snackbars.$emit("snackbar", {
            message: `You rejected the invite to join ${invitation.team}.`,
          });
          authEvents.$emit("finished-authentication-attempt");
          dispatch("initialise");
        });
    },
    cancelInvitation({ dispatch }, invitationUuid) {
      apiService
        .callApi("invitations", "cancelInvitation", invitationUuid)
        .then(() => {
          dispatch("loadRecords");
          snackbars.$emit("snackbar", {
            message: "Invitation revoked.",
          });
        });
    },
  },
  mutations: {
    SAVE_RECORDS(state, records) {
      state.records = records;
    },
    ADD_RECORD(state, record) {
      state.records.push(record);
    },
    SET_SELECTED(state, record) {
      state.record = record;
    },
    RESET_SELECTED(state) {
      state.record = {};
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    RESET_FILTER(state, { filter }) {
      state.filters[filter] = null;
    },
    RESET_FILTERS(state) {
      state.filters = {};
    },
    SAVE_VALIDATION_RULES(state, validationRules) {
      state.validationRules = validationRules;
    },
  },
  modules: {},
};
