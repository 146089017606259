const ProfileMenuItems = [
  {
    text: "Your details",
    route: "/profile/details",
    icon: "fas fa-user-circle",
    createIcon: "",
    requiredPermission: "",
  },
  {
    text: "Your invites",
    route: "/profile/invites",
    icon: "fas fa-paper-plane",
    createIcon: "",
    requiredPermission: "",
  },
];

export default ProfileMenuItems;
