import apiService from "@p/apiService";
import mediaEvents from "@p/events/media";
import snackbars from "@p/events/snackbars";
import heic2any from "heic2any";
const Jimp = require("jimp/browser/lib/jimp");
import store from "@s/";

export const Media = {
  namespaced: true,
  state: {
    loading: false,
    uploadURL: "",
    uploadStep: 1,
    rawFile: null,
    workingFile: null,
    imageViewportDimensions: {
      width: null,
      height: null
    },
    cropperCoordinates: {
      x1: null,
      y1: null,
      x2: null,
      y2: null
    },
    originalImageDimensions: {
      width: null,
      height: null
    },
    croppedImageData: null,
    croppedImageDimensions: {
      width: null,
      height: null
    },
    resizedImageData: null,
    thresholdImageData: null,
    thresholdAmount: 100,
    finalFile: null,
  },
  getters: {
    test: state => state,
    loading: state => state.loading,
    uploadURL: state => state.uploadURL,
    rawFile: state => state.rawFile,
    workingFile: state => state.workingFile,
    uploadStep: state => state.uploadStep,
    fileTypeIsValid: state => state.rawFile && state.rawFile.type.startsWith("image/"),
    imagePreviewURL: state => {
      if (state.workingFile) {
        return URL.createObjectURL(state.workingFile);
      } else if (state.rawFile) {
        return URL.createObjectURL(state.rawFile);
      } else {
        return null;
      }
    },
    imageViewportDimensions: state => state.imageViewportDimensions,
    cropperCoordinates: state => state.cropperCoordinates,
    originalImageDimensions: state => state.originalImageDimensions,
    croppedImageData: state => state.croppedImageData,
    resizedImageData: state => state.resizedImageData,
    thresholdImageData: state => state.thresholdImageData,
    thresholdAmount: state => state.thresholdAmount,
    finalFile: state => state.finalFile
  },
  actions: {
    // For new image uploader
    resetUploader({commit}) {
      commit('RESET_UPLOADER');
    },
    setRawFile({commit}, rawFile) {
      commit('SET_RAW_FILE', rawFile);
    },
    advanceStep({commit}, options = {extraActions: [], longestEdgeSizeMaxPx: 2000}) {
      commit('ADVANCE_STEP', options);
    },
    setViewportDimensions({commit}, dimensions) {
      commit('SET_VIEWPORT_DIMENSIONS', dimensions);
    },
    setCropperCoordinates({commit}, cropperData) {
      commit('SET_CROPPER_COORDINATES', cropperData);
    },
    setOriginalImageDimensions({commit}, imageDimensions) {
      commit('SET_ORIGINAL_IMAGE_DIMENSIONS', imageDimensions);
    },
    applyJimpThresholdToImage({commit}, thresholdAmount) {
      commit('APPLY_JIMP_THRESHOLD_TO_IMAGE', thresholdAmount);
    },
    setThresholdAmount({commit}, thresholdAmount) {
      commit('SET_THRESHOLD_AMOUNT', thresholdAmount);
    },
    getFinalFile({commit}, options = {upload: false}) {
      commit('GET_FINAL_FILE', options);
    },

    // legacy stuff needed for other parts of app
    initialise() {
      // Do nothing...
    },
    getUploadURL({ commit }, data) {
      apiService.callApi("media", "getUploadURL", data).then((res) => {
        commit("SET_UPLOAD_URL", res.preSignedUrl);
      });
    },
    uploadFile({ commit }, file) {
      commit("UPLOAD_FILE", file);
    },
    resetUploadUrl({ commit }) {
      commit("RESET_UPLOAD_URL");
    },
    // eslint-disable-next-line no-empty-pattern
    deleteFile({}, fileUuid) {
      apiService
        .callApi("media", "deleteMedia", fileUuid)
        .then(() => {
          mediaEvents.$emit("file-successfully-deleted");
          snackbars.$emit("snackbar", {
            message: "File successfully deleted",
          });
        })
        .catch(() => {
          mediaEvents.$emit("file-delete-failed");
          snackbars.$emit("snackbar", {
            message: "File delete failed",
            type: "error",
          });
        });
    },
  },
  mutations: {
    // For new image uploader
    RESET_UPLOADER(state) {
      state.uploadURL = "";
      state.rawFile = null;
      state.workingFile = null;
      state.uploadStep = 1;
      state.imageViewportDimensions = {
        width: null,
        height: null
      },
      state.cropperCoordinates = {
        x1: null,
        y1: null,
        x2: null,
        y2: null
      }
      state.originalImageDimensions = {
        width: null,
        height: null
      },
      state.cropperCoordinatesOfOriginalImage = {
        x1: null,
        y1: null,
        x2: null,
        y2: null
      }
      state.croppedImageData = null;
      state.croppedImageDimensions = {
        width: null,
        height: null
      }
      state.resizedImageData = null;
      state.thresholdImageData = null;
    },
    SET_RAW_FILE(state, rawFile) {
      state.rawFile = rawFile;
    },
    ADVANCE_STEP(state, options) {
      let currentStep = state.uploadStep;
      state.loading = true;
      
      if (currentStep === 1) {
        let fileIsHeic = ['image/heif', 'image/heic'].includes(state.rawFile.type);

        if (fileIsHeic) {
          let url = URL.createObjectURL(this.workingFile);

          fetch(url)
            .then(res => res.blob())
            .then(blob => heic2any({blob}))
            .then(blob => {
              const file = new File([blob], state.rawFile.name, {type: 'image/png'});
              state.workingFile = file;
            })
            .then(() => {
              state.uploadStep = currentStep + 1
              state.loading = false;
            });
        } else {
          state.workingFile = state.rawFile;
          state.uploadStep = currentStep + 1;
          state.loading = false;
        }
      }

      if (currentStep === 2) {
        let imagePreviewURL = URL.createObjectURL(state.workingFile);

        Jimp.read(imagePreviewURL).then(img => {
          let coords = state.cropperCoordinates;
          let x = coords.x1 ?? 0;
          let y = coords.y1 ?? 0;
          let w = coords.x2 ?? state.originalImageDimensions.width;
          let h = coords.y2 ?? state.originalImageDimensions.height;
          console.log([x,y,w,h]);
          img.crop(x,y,w,h).getBase64Async(state.workingFile.type).then(data => {
            state.croppedImageData = data;
            state.uploadStep = currentStep + 1;

            let img = new Image();
            img.onload = function()  {
              state.croppedImageDimensions = {
                width: this.width,
                height: this.height
              }

              resizeImage(state, options);
            };
            img.src = data;
          });
        });
      }

      function resizeImage(state, options) {
        let longestEdgeSizeMaxPx = options.longestEdgeSizeMaxPx;
        let d = { // Original dimensions
          w: state.croppedImageDimensions.width,
          h: state.croppedImageDimensions.height
        }
        let newD = { // New dimensions
          w: state.croppedImageDimensions.width,
          h: state.croppedImageDimensions.height
        }

        let longestEdge = d.w > d.h ? "w" : "h";
        let otherEdge = d.w > d.h ? "h": "w";

        if (d[longestEdge] > longestEdgeSizeMaxPx) {
          newD[longestEdge] = longestEdgeSizeMaxPx;
          newD[otherEdge] = longestEdgeSizeMaxPx * (d[otherEdge] / d[longestEdge]);
        }

        Jimp.read(state.croppedImageData).then(img => {
          img
            .resize(newD.w, newD.h)
            .getBase64Async(state.workingFile.type).then((data) => {
              state.resizedImageData = data;
              state.loading = false;

              if (options.extraActions.includes('threshold')) {
                store.dispatch("media/applyJimpThresholdToImage");
              }
            });
        });
      }
    },
    SET_VIEWPORT_DIMENSIONS(state, dimensions) {
      state.imageViewportDimensions = dimensions;
    },
    SET_CROPPER_COORDINATES(state, cropperData) {
      state.cropperCoordinates = cropperData;
    },
    SET_ORIGINAL_IMAGE_DIMENSIONS(state, imageDimensions) {
      state.originalImageDimensions = imageDimensions;
    },
    APPLY_JIMP_THRESHOLD_TO_IMAGE(state, thresholdAmount) {
      state.loading = true;
      thresholdAmount = thresholdAmount ?? state.thresholdAmount;
      Jimp.read(state.resizedImageData).then(img => {
        img
          .threshold({max: thresholdAmount, replace: 255, autoGreyscale: true})
          .getBase64Async(state.workingFile.type).then(data => {
            state.thresholdImageData = data;
            state.loading = false;
          });
      });
    },
    SET_THRESHOLD_AMOUNT(state, thresholdAmount) {
      state.thresholdAmount = thresholdAmount;
    },
    GET_FINAL_FILE(state, options) {
      function getFinalFile (data, state) {
        return fetch(data)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], state.workingFile.name, {type: state.workingFile.type})
            state.finalFile = file;
          });
      }

      let finalImageData;

      if (state.thresholdImageData) {
        finalImageData = state.thresholdImageData;
      } else if (state.resizedImageData) {
        finalImageData = state.resizedImageData;
      } else if (state.croppedImageData) {
        finalImageData = state.croppedImageData;
      }

      getFinalFile(finalImageData, state).then(() => {
        if (!state.finalFile) {
          state.finalFile = state.workingFile;
        }

        if (options.upload) {
          store.dispatch("media/uploadFile", state.finalFile);
        }
      });
    },


    // Legacy stuff used elsewhere in app
    SET_UPLOAD_URL(state, uploadURL) {
      state.uploadURL = uploadURL;
    },
    UPLOAD_FILE(state, file) {
      let xhr = new XMLHttpRequest();
      xhr.open("put", state.uploadURL);
      xhr.setRequestHeader("Content-Type", file.type);

      xhr.onreadystatechange = function() {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          apiService.media
            .confirmUploadSuccess(state.uploadURL)
            .then(() => {
              mediaEvents.$emit(
                "successful-file-upload",
                "File uploaded successfully!"
              );
            })
            .catch(() => {
              mediaEvents.$emit(
                "unsuccessful-file-upload",
                "Failed to confirm uploaded file."
              );
            });
        }
        if (this.readyState === XMLHttpRequest.DONE && this.status !== 200) {
          mediaEvents.$emit(
            "unsuccessful-file-upload",
            "Failed to upload file."
          );
        }
      };

      xhr.send(file);
    },
    RESET_UPLOAD_URL(state) {
      state.uploadURL = "";
    },
  },
  modules: {},
};
