import apiService from "@p/apiService";

const PTApi = require("print_trail");

export const FulfilmentApi = new PTApi.FulfilmentApi();

export default {
  getFulfilment(fulfilmentUuid) {
    let opts = apiService.getRequestOpts();
    return FulfilmentApi.fulfilmentShow(fulfilmentUuid);
  },

  updateFulfilment(fulfilmentUuid, data) {
    let opts = apiService.getRequestOpts();
    opts.updateFulfilmentRequestBody = PTApi.UpdateFulfilmentRequestBody.constructFromObject(
      data
    );
    return FulfilmentApi.fulfilmentUpdate(fulfilmentUuid, opts);
  },

  cancelFulfilments(fulfilments) {
    let opts = apiService.getRequestOpts();
    opts.bulkUuidRequestBody = PTApi.BulkUuidRequestBody.constructFromObject({
      uuids: fulfilments,
    });
    return FulfilmentApi.fulfilmentCancel(opts);
  },

  getFulfilmentDocuments(fulfilmentUuid) {
    let opts = apiService.getRequestOpts();
    return FulfilmentApi.fulfilmentShowDocuments(fulfilmentUuid, opts);
  },
};
