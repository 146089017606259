<template>
  <v-app class="muli-font" style="font-family: 'Lato', sans-serif !important">
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />
    <link
      href="https://use.fontawesome.com/releases/v5.1.0/css/all.css"
      rel="stylesheet"
    />

    <!--    Loading spinner -->
    <div v-if="showLoadingSpinner" class="spinner-parent">
      <div class="spinner-content">
        <div class="loader-wrapper">
          <p class="ml-n12 mb-16">
            <v-img src="/pt-logo.svg" width="200px"></v-img>
          </p>
          <div class="loader"></div>
        </div>
      </div>
    </div>

    <!--    Snackbars -->
    <snackbar></snackbar>

    <!--    Navigation bars -->
    <desktop-navigation
      v-if="$vuetify.breakpoint.mdAndUp"
      :navigation="navigation"
      :tabs="tabs"
    ></desktop-navigation>
    <mobile-navigation
      v-else
      :mobileDrawer="mobileDrawer"
      :navigation="navigation"
      :tabs="tabs"
      @toggle-mobile-drawer="toggleMobileDrawer"
    ></mobile-navigation>

    <!--    Confirmation dialog -->
    <confirm-dialog v-if="showDialog"></confirm-dialog>
    <!--    Inactivity dialog -->
    <inactivity-dialog :dialog="showInactivityDialog"></inactivity-dialog>

    <!--    Errors dialog -->
    <v-dialog v-model="showErrorsDialog" width="750">
      <errors-dialog></errors-dialog>
    </v-dialog>

    <!--    Main content if authentication + team fetch finished -->
    <v-main v-if="!showLoadingSpinner" class="consil-bg__light">
      <!--      Settings side-menu -->
      <side-menu
        v-if="
          $route.path.includes('/settings') &&
          $isAuthenticated() &&
          $isTeamSelected()
        "
        :navigation="navigation"
        :settingsDrawer="settingsDrawer"
        :tabs="tabs"
      ></side-menu>

      <!--      Admin side-menu -->
      <admin-side-menu
        v-if="
          $route.path.includes('/admin') &&
          $isAuthenticated() &&
          $isTeamSelected()
        "
        :adminDrawer="adminDrawer"
        :navigation="navigation"
        :tabs="tabs"
      ></admin-side-menu>

      <!--      Profile side menu-->
      <profile-side-menu
        v-if="$route.path.includes('/profile') && $isAuthenticated()"
        :navigation="navigation"
        :profileDrawer="profileDrawer"
        :tabs="tabs"
      ></profile-side-menu>

      <global-alerts />

      <!--      Breadcrumbs-->
      <v-breadcrumbs :items="breadCrumbs" divider="/">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            class="grey--text lighten-1"
            @mouseenter="$event.target.classList.add('primary--text')"
            @mouseleave="$event.target.classList.remove('primary--text')"
          >
            <template v-slot>
              <router-link
                :to="item.to"
                class="breadcrumb-link text-decoration-none"
                style="color: inherit !important"
              >
                {{ item.text }}
              </router-link>
            </template>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!--      Live chat-->
      <chat v-if="websocketsConnected" />

      <!--      Main content -->
      <router-view
        @toggle-admin-drawer="toggleAdminDrawer"
        @toggle-profile-drawer="toggleProfileDrawer"
        @toggle-settings-drawer="toggleSettingsDrawer"
      >
      </router-view>
    </v-main>

    <!--    Main content otherwise -->
    <v-main v-else-if="!$isTeamSelected()">
      <!--      Profile sidemenu -->
      <profile-side-menu
        v-if="$route.path.includes('/profile') && $isAuthenticated()"
        :navigation="navigation"
        :profileDrawer="profileDrawer"
        :tabs="tabs"
      ></profile-side-menu>

      <!--      Content if url is /profile -->
      <router-view
        v-if="$route.path.split('/')[1] === 'profile'"
        @toggle-admin-drawer="toggleAdminDrawer"
        @toggle-profile-drawer="toggleProfileDrawer"
        @toggle-settings-drawer="toggleSettingsDrawer"
      ></router-view>
      <no-team-selected v-else></no-team-selected>
    </v-main>

    <!--    Main content if not authenticated -->
    <v-main v-else-if="!$isAuthenticated()" class="consil-bg__light">
      <unauthenticated></unauthenticated>
    </v-main>

    <!--    Show/hide profile side meu -->
    <div
      v-if="$route.path.split('/')[1] === 'profile'"
      style="position: fixed; top: 93vh; left: 0.9rem; z-index: 99999"
    >
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click.stop="toggleProfileDrawer">
            <v-icon v-if="profileDrawer" style="font-size: 1rem"
              >fas fa-times</v-icon
            >
            <v-icon v-else style="font-size: 1rem">fas fa-bars</v-icon>
          </v-btn>
        </template>
        <span>Toggle Menu</span>
      </v-tooltip>
    </div>

    <!--    Footer -->
    <v-footer
      class="white mt-8"
      padless
      style="background-color: #f4f6f6 !important"
    >
      <v-col class="text-center" cols="12" style="font-size: 0.8em">
        <a
          href="https://docs.printtrail.com/"
          style="text-decoration: none"
          target="_blank"
          >User Guide</a
        >
        <span class="mx-2 grey--text">|</span>
        <a
          href="https://printtrail.com/terms-and-conditions/"
          style="text-decoration: none"
          target="_blank"
          >Terms & Conditions</a
        >
        <span class="mx-2 grey--text">|</span>
        <a
          href="https://printtrail.com/privacy-policy/"
          style="text-decoration: none"
          target="_blank"
          >Privacy Policy</a
        >
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import router from "@r";
import authEvents from "@p/events/auth";

const Chat = () => import("@c/Chat");
const Snackbar = () => import("@c/Snackbar");
const GlobalAlerts = () => import("@c/GlobalAlerts");
const ErrorsDialog = () => import("@c/ErrorsDialog");
const ConfirmDialog = () => import("@c/ConfirmDialog");
const MobileNavigation = () => import("@c/MobileNavbar");
const NoTeamSelected = () => import("@v/NoTeamSelected");
const Unauthenticated = () => import("@v/Unauthenticated");
const InactivityDialog = () => import("@c/InactivityDialog");
const SideMenu = () => import("@settings/components/SideMenu");
const AdminSideMenu = () => import("@admin/components/SideMenu");
const DesktopNavigation = () => import("@/components/DesktopNavbar.vue");
const ProfileSideMenu = () => import("@users/components/profile/SideMenu");

export default {
  metaInfo() {
    if (this.$route.path.includes("/invite")) {
      return {
        meta: [
          {
            name: "robots",
            content: "noindex,nofollow",
          },
        ],
      };
    }
  },
  name: "App",
  components: {
    GlobalAlerts,
    Chat,
    ErrorsDialog,
    InactivityDialog,
    ConfirmDialog,
    NoTeamSelected,
    Unauthenticated,
    DesktopNavigation,
    MobileNavigation,
    SideMenu,
    AdminSideMenu,
    ProfileSideMenu,
    Snackbar,
  },
  data: () => ({
    settingsDrawer: true,
    adminDrawer: true,
    mobileDrawer: false,
    profileDrawer: true,
    finishedAuthenticating: false,
    finishedTeamFetch: false,
    finishedInvitationFetch: false,
    showErrorsDialog: false,
    finishedCheckTeam: false,
  }),
  created() {},
  mounted() {
    this.initialiseDialogEvents();
    this.initialiseAuthEvents();
    this.initialiseTeamEvents();
    this.initialiseErrorEvents();
    if (this.$isAuthenticated()) {
      this.checkForPendingInvitations();
    }
    this.awaitUserAndSetupEcho();
    this.awaitRouterAndSetTeam();

    setTimeout(() => {
      localStorage.removeItem("deepLinked");
      localStorage.removeItem("intendedURL");
    }, 30000);
  },
  methods: {
    awaitRouterAndSetTeam() {
      let toTeam = this.$route.params.team;

      if (toTeam === undefined) {
        setTimeout(this.awaitRouterAndSetTeam, 100);
      } else {
        let team = this.$store.getters["teams/teamSelect/byCode"](toTeam);

        if (team) {
          if (team.uuid !== this.$currentTeam().uuid) {
            this.$teamEvents.$emit("set-active-team", team);
          }
        } else {
          this.$router.push({
            name: "error404",
          });
        }
      }

      this.finishedCheckTeam = true;
    },
    awaitUserAndSetupEcho() {
      let user = this.$currentUser();
      if (JSON.stringify(user) === "{}") {
        setTimeout(() => {
          this.awaitUserAndSetupEcho();
        }, 100);
      } else {
        this.setupEcho();
      }
    },
    setupEcho() {
      if (this.connectLiveChat) {
        if (!this.websocketsConnected) {
          this.$echo.connect();
        }

        this.setupWebsockets();
      }
    },
    setupWebsockets() {
      let self = this;
      this.$echo
        .channel(`live-support.${this.$currentUser().uuid}`)
        .listen("LiveChatResponse", (e) => {
          self.$echoEvents.$emit("message-received", e.message);
        })
        .listen("NewNotification", (e) => {
          console.log("New notification!", e.notification.body);
        });
    },
    toggleSettingsDrawer() {
      if (this.mobileDrawer) {
        this.mobileDrawer = false;
      }
      this.settingsDrawer = !this.settingsDrawer;
    },
    toggleAdminDrawer() {
      if (this.mobileDrawer) {
        this.mobileDrawer = false;
      }
      this.adminDrawer = !this.adminDrawer;
    },
    toggleMobileDrawer() {
      if (this.settingsDrawer) {
        this.settingsDrawer = false;
      }
      this.mobileDrawer = !this.mobileDrawer;
    },
    toggleProfileDrawer() {
      if (this.mobileDrawer) {
        this.mobileDrawer = false;
      }
      this.profileDrawer = !this.profileDrawer;
    },

    initialiseDialogEvents() {
      this.$alertBus.$on("show-dialog", (dialogOpts) => {
        this.$alertBus.triggerDialog(dialogOpts);
      });
      this.$alertBus.$on("dialog-response", () => {
        this.$alertBus.resetDialog();
      });
    },
    initialiseAuthEvents() {
      this.$authEvents.$on("finished-authentication-attempt", () => {
        if (this.$isAuthenticated()) {
          this.$store.dispatch("initialiseTenantUnawareStores");
          this.$store.dispatch("user/getUserTeamInvitations", 500);
        } else {
          this.finishedTeamFetch = true;
          this.finishedInvitationFetch = true;
        }
        this.finishedAuthenticating = true;
      });
      this.$authEvents.$on("force-logout", () => {
        this.$forceLogout();
      });
    },
    initialiseTeamEvents() {
      this.$teamEvents.$on("finished-team-fetch", () => {
        this.finishedTeamFetch = true;
        if (this.$isAuthenticated()) {
          let teams = this.$store.getters["teams/teamSelect/allTeams"];
          if (teams.length === 0) {
            this.$router.push({ name: "profile_invites" });
          }
        }
      });

      this.$teamEvents.$on("changed-active-team", (team) => {
        this.$store.dispatch("initialiseTenantAwareStores");
        this.$store.dispatch("user/getUserDetails");

        let redirectedRoutes = [
          "order_details",
          "tracking",
          "fulfilment_details",
          "product_details",
          "channel-details-product",
          "channel-add-product",
        ];

        let deepLinked = window.localStorage.getItem("deepLinked");
        if (!deepLinked && redirectedRoutes.includes(this.$route.name)) {
          this.$router.push({ name: "orders", params: { team: team.code } });
        }
      });
    },
    initialiseErrorEvents() {
      this.$errorEvents.$on("not-found", (data) => {
        if (!data) {
          data = {
            type: null,
            uuid: null,
          };
        }
        this.$router.push({
          name: "error404",
          query: { type: data.type, uuid: data.uuid },
        });
      });

      this.$errorEvents.$on("show-error-dialog", (errors) => {
        this.$errorEvents.errorMessages = errors;
        this.showErrorsDialog = true;
      });
    },
    checkForPendingInvitations() {
      let self = this;
      let invitations = this.$store.getters["currentUser/pendingInvitations"];

      if (invitations) {
        this.$alertBus.$once("dialog-response", (dialogResponse) => {
          if (dialogResponse) {
            self.$router.push({ name: "profile_invites" });
          }
        });

        let dialogOpts = {
          dialogTitle: "You have pending invitations",
          dialogText: "Do you want to go to the 'your invitations' page now?",
          confirmText: "Yes",
          cancelText: "No",
        };
        this.$alertBus.$emit("show-dialog", dialogOpts);
      }
    },
  },
  computed: {
    console: () => console,
    connectLiveChat() {
      return process.env.VUE_APP_ENABLE_LIVE_CHAT;
    },
    breadCrumbs() {
      // Get current route parameters.
      let params = this.$route.params;
      // Get the route objects for the current route and parent routes.
      let matched = this.$route.matched;
      // Remove the first object, if it matches example.app/TEAM_CODE
      // And we don't want a breadcrumb for that
      if (!matched[0]) {
        return;
      }

      if (matched[0].name === "base") {
        matched.shift();
      }
      // Loop through the route objects
      let crumbs = matched.map((route) => {
        // Store the path
        let to = route.path;
        // Loop through our current route parameters
        for (const [key, value] of Object.entries(params)) {
          // Replace the parameter in the route path with the parameter's value
          to = to.replace(`:${key}`, value);
        }
        // Get the breadcrumb text
        let crumb = route.meta.breadcrumb ?? null;
        // If the route has no breadcrumb text in the meta, don't return it
        if (crumb) {
          return {
            to: to,
            text: crumb,
          };
        }
      });
      // Remove undefined-s.
      return crumbs.filter((crumb) => !!crumb);
    },
    websocketsConnected() {
      return this.$echo.connector.pusher.connection.state === "connected";
    },
    tabs() {
      return router.getNavbarTabs();
    },
    navigation() {
      return router.getNavbarItems();
    },
    currentPath() {
      let path = this.$route.path.split("/");
      return path.shift();
    },
    settingsSideNavOn() {
      return this.currentPath[0] === "settings";
    },
    showLoadingSpinner() {
      return !(
        this.finishedAuthenticating &&
        this.finishedTeamFetch &&
        this.finishedCheckTeam
      );
    },
    showInactivityDialog() {
      return authEvents.commitAutoLogout;
    },
    showDialog() {
      return this.$alertBus.showDialog;
    },
  },
  watch: {
    settingsDrawer(value) {
      this.$drawerEvents.$emit("settings-drawer-toggled", value);
    },
    adminDrawer(value) {
      this.$drawerEvents.$emit("admin-drawer-toggled", value);
    },
    mobileDrawer(value) {
      this.$drawerEvents.$emit("mobile-drawer-toggled", value);
    },
    profileDrawer(value) {
      this.$drawerEvents.$emit("profile-drawer-toggled", value);
    },
    websocketsConnected(value) {
      if (value === false) {
        this.setupEcho();
      }
    },
  },
};
</script>

<style>
.spinner-parent {
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
}

.spinner-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  position: relative;
  background-color: white;
  z-index: 9998 !important;
}

.loader-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.loader {
  border: 6px solid #f3f3f3; /*background*/
  border-top: 6px solid #37bfa7; /*foreground*/
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
  z-index: 9999 !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.v-stepper__step:not(.v-stepper__step--complete):not(.v-stepper__step--active)
  > .v-stepper__step__step {
  background-color: unset !important;
  border: 2px solid #b1b1b1;
}

.v-stepper__step--active > .v-stepper__step__step {
  color: #37bfa7 !important;
}

span.stepper__step__step {
  display: none !important;
}

.v-stepper__step.step_with_errors:not(.v-stepper__step--complete):not(.v-stepper__step--active)
  > .v-stepper__step__step,
.step_with_errors > span.v-stepper__step__step {
  color: #ff7e7e !important;
  border-color: #ff7e7e !important;
  background-color: #ff7e7e !important;
}

.step_with_errors > div.v-stepper__label {
  text-shadow: 0 0 0 #ff7e7e !important;
}

.step_with_errors > div.v-stepper__label > span {
  color: #ff7e7e !important;
  text-shadow: 0 0 0 #ff7e7e !important;
}
</style>
