import apiService from "@p/apiService";
import axios from "axios";

const PTApi = require("print_trail");

export const ImportApi = new PTApi.ImportApi();

export default {
  getImportBatches() {
    let opts = apiService.getRequestOpts();
    return ImportApi.importBatchIndex(opts);
  },

  getOrderImportBatch(uuid) {
    let opts = apiService.getRequestOpts();
    return ImportApi.importBatchShow(uuid, opts);
  },

  getBatchOrders(uuid) {
    let opts = apiService.getRequestOpts();
    return ImportApi.importOrderIndex(uuid, opts);
  },

  getBatchOrder(batchUuid, orderUuid) {
    let opts = apiService.getRequestOpts();
    return ImportApi.importOrderShow(batchUuid, orderUuid, opts);
  },

  getBatchOrderLines(batchUuid, orderUuid) {
    let opts = apiService.getRequestOpts();
    return ImportApi.importOrderLineIndex(batchUuid, orderUuid, opts);
  },

  async storeOrderImportBatch(data) {
    let endpoint = "/api/import-batches/import-orders";
    let headers = apiService.getAxiosHeaders();

    let channel = data[0];
    let file = data[1];
    let formData = new FormData();
    formData.append("file", file);
    formData.append("channelUuid", channel);

    return await axios.post(endpoint, formData, { headers: headers });
  },

  processOrderImportBatch(uuid, queueForProcessing = false) {
    let opts = apiService.getRequestOpts();
    if (queueForProcessing) {
      opts.importBatchOptionRequestBody = PTApi.ImportBatchOptionRequestBody.constructFromObject(
        {
          options: ["order_queue_for_processing"],
        }
      );
    }
    return ImportApi.importBatchProcess(uuid, opts);
  },

  async storeProductImportBatch(file) {
    let endpoint = "/api/import-batches/import-products";
    let headers = apiService.getAxiosHeaders();

    let formData = new FormData();
    formData.append("file", file);

    return await axios.post(endpoint, formData, { headers: headers });
  },

  getProductImportBatch(uuid) {
    let opts = apiService.getRequestOpts();
    return ImportApi.importBatchShow(uuid, opts);
  },

  getBatchProducts(uuid) {
    let opts = apiService.getRequestOpts();
    return ImportApi.importProductIndex(uuid, opts);
  },

  processProductImportBatch(uuid) {
    let opts = apiService.getRequestOpts();
    return ImportApi.importBatchProcess(uuid, opts);
  },
};
