const adminSideMenuItems = [
  {
    icon: "fas fa-info-circle",
    text: "Teams",
    path: "/admin/teams",
    children: [
      {
        text: "Create Team",
        path: "/admin/teams/create",
        icon: "",
      },
    ],
  },
  {
    icon: "fas fa-users",
    text: "Users",
    path: "/admin/users",
    children: [],
  },
  {
    text: "PoD Review",
    path: "/admin/pod-review",
    icon: "fas fa-clipboard-check",
    children: [],
  },
  {
    icon: "fas fa-cube",
    text: "Fulfilments",
    path: "/admin/fulfilments",
    children: [],
  },
  {
    icon: "fa-map-marker",
    text: "Shipping Zones",
    path: "/admin/shipping-zones",
    children: [
      {
        text: "Add a shipping zone",
        path: "/admin/shipping-zones/create",
        icon: "",
      },
    ],
  },
  {
    icon: "fa-print",
    text: "PoD Product Types",
    path: "/admin/products",
    children: [],
  },
  {
    icon: "fas fa-industry",
    text: "Fulfilment Locations",
    path: "/admin/locations",
    children: [
      {
        text: "Add a fulfilment location",
        path: "/admin/locations/create",
        icon: "fas fa-plus",
      },
    ],
  },
];

export default adminSideMenuItems;
