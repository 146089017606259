import apiService from "@p/apiService";

const PTApi = require("print_trail");

export const ChannelApi = new PTApi.ChannelApi();

export default {
  getChannels(options) {
    let opts = apiService.getRequestOpts();
    if (options) {
      if (options.page) {
        opts.page = options.page;
      }
      if (options.perPage) {
        opts.perPage = options.perPage;
      }
      if (options.sort) {
        opts.sort = options.sort;
      }
      if (options.filterName && options.filterName !== "") {
        opts.filterName = options.filterName;
      } else {
        opts.filterName = null;
      }
      if (options.filterCode && options.filterCode !== "") {
        opts.filterCode = options.filterCode;
      } else {
        opts.filterCode = null;
      }
    }
    return ChannelApi.channelIndex(opts);
  },

  getChannel(channelUuid) {
    let opts = apiService.getRequestOpts();
    return ChannelApi.channelShow(channelUuid, opts);
  },

  createChannel(data) {
    let opts = apiService.getRequestOpts();
    opts.createChannelRequestBody = PTApi.CreateChannelRequestBody.constructFromObject(
      data
    );
    return ChannelApi.channelStore(opts);
  },

  updateChannel(channelUuid, data) {
    let opts = apiService.getRequestOpts();
    opts.updateChannelRequestBody = PTApi.UpdateChannelRequestBody.constructFromObject(
      data
    );
    return ChannelApi.channelUpdate(channelUuid, opts);
  },

  deleteChannel(channelUuid) {
    let opts = apiService.getRequestOpts();
    return ChannelApi.channelDestroy(channelUuid, opts);
  },

  getChannelTokens(channelUuid, options) {
    let opts = apiService.getRequestOpts();
    if (options) {
      if (options.page) {
        opts.page = options.page;
      }
      if (options.perPage) {
        opts.perPage = options.perPage;
      }
      if (options.sort) {
        opts.sort = options.sort;
      }
      if (options.filterName && options.filterName !== "") {
        opts.filterName = options.filterName;
      } else {
        opts.filterName = null;
      }
      if (options.filterCode && options.filterCode !== "") {
        opts.filterCode = options.filterCode;
      } else {
        opts.filterCode = null;
      }
    }
    return ChannelApi.channelTokenIndex(channelUuid, opts);
  },

  storeChannelToken(channelUuid, data) {
    let opts = apiService.getRequestOpts();
    opts.channelTokenRequestBody = PTApi.ChannelTokenRequestBody.constructFromObject(
      data
    );
    return ChannelApi.channelTokenStore(channelUuid, opts);
  },

  deleteChannelToken(channelUuid, tokenUuid) {
    let opts = apiService.getRequestOpts();
    return ChannelApi.channelTokenDestroy(channelUuid, tokenUuid, opts);
  },

  setChannelActive(channelUuid) {
    let opts = apiService.getRequestOpts();
    return ChannelApi.channelSetActive(channelUuid, opts);
  },

  setChannelInactive(channelUuid) {
    let opts = apiService.getRequestOpts();
    return ChannelApi.channelSetInactive(channelUuid, opts);
  },
};
