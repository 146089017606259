const Orders = () => import("../views/All.vue");
const OrderDetails = () => import("../views/Details.vue");
const FulfilmentDetails = () => import("../views/fulfilments/Details");
const ViewOrderTracking = () => import("../views/Tracking.vue");
const Fulfilments = () => import("../views/fulfilments/All.vue");
const CreateOrder = () => import("../views/create/Create.vue");
const CreateOrderFulfilment = () =>
  import("../views/create/OrderFulfilment.vue");
const ImportUpload = () => import("../views/import/Upload");
const ImportPreview = () => import("../views/import/Preview");
const ImportError = () => import("../views/import/Error");
const ImportResults = () => import("../views/import/Results");

// ORDERS
const routes = [
  {
    path: "orders",
    component: {
      render(component) {
        return component("router-view");
      },
    },
    meta: {
      breadcrumb: "All Orders",
      meta: {
        title: "All orders",
      },
    },
    children: [
      {
        path: "",
        name: "orders",
        component: Orders,
        meta: {
          title: "All orders",
        },
      },
      {
        path: ":id/details",
        name: "order_details",
        component: OrderDetails,
        meta: {
          breadcrumb: "Order Details",
          title: "Order Details",
        },
      },
      {
        path: ":id/tracking",
        name: "tracking",
        component: ViewOrderTracking,
        meta: {
          breadcrumb: "Order Tracking",
          title: "Order Tracking",
        },
      },

      {
        path: "fulfilments",
        name: "fulfilments",
        component: Fulfilments,
        meta: {
          breadcrumb: "Fulfilments",
          title: "All Fulfilments",
        },
      },

      {
        path: "fulfilment/:id/details",
        name: "fulfilment_details",
        component: FulfilmentDetails,
        meta: {
          breadcrumb: "Order Fulfilment Details",
          title: "Order Fulfilment Details",
        },
      },

      // CREATE ORDER LINKS
      {
        path: "create",
        name: "order_create",
        component: CreateOrder,
        meta: {
          breadcrumb: "Create Order",
          title: "Create Order",
        },
      },
      {
        path: "import",
        component: {
          render(component) {
            return component("router-view");
          },
        },
        meta: {
          breadcrumb: "Import",
          title: "Import Orders",
        },
        children: [
          {
            path: "",
            name: "import_orders",
            component: ImportUpload,
            meta: {
              breadcrumb: "Import Orders",
              title: "Import Orders",
            },
          },
          {
            path: ":id/preview",
            name: "import_preview",
            component: ImportPreview,
            meta: {
              breadcrumb: "Import Preview",
              title: "Import Preview",
            },
          },
          {
            path: ":id/results",
            name: "import_results",
            component: ImportResults,
            meta: {
              breadcrumb: "Import Results",
              title: "Import Results",
            },
          },
          {
            path: "error",
            name: "import_error",
            component: ImportError,
            meta: {
              breadcrumb: "Import Error",
              title: "Import Error",
            },
          },
        ],
      },
      {
        path: "create/fulfilment",
        name: "order_create_fulfilment",
        component: CreateOrderFulfilment,
        meta: {
          breadcrumb: "Order Fulfilment Create",
          title: "Create Order Fulfilment",
        },
      },
    ],
  },
];

export default routes;
