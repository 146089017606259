export const Billing = {
  namespaced: true,
  state: {
    card: {},

    billingAddress: {},
  },
  getters: {
    card: (state) => {
      return state.card;
    },
    billingAddress: (state) => {
      return state.billingAddress;
    },
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadCard");
      dispatch("loadBillingDetails");
    },
    loadCard({ commit }) {
      commit("LOAD_CARD");
    },
    loadBillingDetails({ commit }) {
      commit("LOAD_BILLING");
    },
  },
  mutations: {
    LOAD_CARD: (state) => {
      state.card = {
        name: "Mickey Mouse",
        number: "4751 1234 4249 9012",
        exp: "0427",
        cvv: "951",
      };
    },
    LOAD_BILLING: (state) => {
      state.billingAddress = {
        country: "United Kingdom",
        postcode: "NE99 9ZZ",
        address_1: "99 Made-up Way",
        address_2: "Coolsville",
        town_city: "Old York",
        state_province_county: "Southumberland",
        note: "N/A",
        phone: "09194715551",
        mobile: "07123188501",
        email: "mickeymouse@disney.com",
      };
    },
  },
  modules: {},
};
