import apiService from "@p/apiService";
import router from "@r";
import importEvents from "@p/events/import";

export const Import = {
  namespaced: true,
  state: {
    batchImportData: null,
    batchImportErrors: null,
  },
  getters: {
    batchImportData: (state) => {
      return state.batchImportData;
    },
    batchImportErrors: (state) => {
      return state.batchImportErrors;
    },
  },
  actions: {
    uploadFile({ commit }, data) {
      apiService
        .callApi("import", "storeOrderImportBatch", data)
        .then((response) => {
          commit("SET_BATCH_IMPORT_DATA", response.data.data);
          router.push({
            name: "import_preview",
            params: { id: response.data.data.uuid },
          });
        })
        .catch((err) => {
          commit("SET_BATCH_IMPORT_ERRORS", err);
          router.push({ name: "import_error" });
        });
    },
    getImportBatchData({ commit }, uuid) {
      apiService
        .callApi("import", "getOrderImportBatch", uuid)
        .then((response) => {
          commit("SET_BATCH_IMPORT_DATA", response.data);
        })
        .then(() => {
          apiService
            .callApi("import", "getBatchOrders", uuid)
            .then((response) => {
              commit("SET_BATCH_IMPORT_ORDERS", response.data);
            });
        })
        .finally(() => {
          importEvents.$emit("hide-spinner");
        });
    },
    importBatchProcess({ commit }, uuid) {
      apiService
        .callApi("import", "processOrderImportBatch", uuid, true)
        .then((response) => {
          commit("SET_BATCH_IMPORT_RESULTS", response.data);
        });
    },
    importBatchDraft({ commit }, uuid) {
      apiService
        .callApi("import", "processOrderImportBatch", uuid)
        .then((response) => {
          commit("SET_BATCH_IMPORT_RESULTS", response.data);
        });
    },
  },
  mutations: {
    SET_BATCH_IMPORT_DATA(state, data) {
      state.batchImportData = data;
    },
    SET_BATCH_IMPORT_ERRORS(state, data) {
      state.batchImportErrors = data;
    },
    SET_BATCH_IMPORT_ORDERS(state, data) {
      let batchImportData = Object.assign({}, state.batchImportData);
      batchImportData.orders = data;
      state.batchImportData = batchImportData;
    },
    SET_BATCH_IMPORT_RESULTS(state, data) {
      state.batchImportResults = data;
    },
  },
};
