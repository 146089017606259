export const Channels = {
  namespaced: true,
  state: {
    records: [],
    recordsLoaded: false,

    record: {},
    recordLoaded: false,

    tableHeaders: [
      { text: "Channel Name", value: "name" },
      { text: "Channel Type", value: "type" },
      { text: "Currency", value: "currency" },
      { text: "Status", value: "status" },
      { text: "Mode", value: "mode" },
      { text: "Sync Status", value: "syncStatus" },
      { text: "Last Sync", value: "lastSync" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    filters: {},
  },
  getters: {
    all: (state) => {
      return state.records;
    },
    byId: (state) => (id) => {
      return state.records.find((record) => record.id === id);
    },
    tableHeaders: (state) => {
      return state.tableHeaders;
    },
    filters: (state) => {
      return state.filters;
    },

    allowedFileTypes: (state) => {
      return state.allowedFileTypes;
    },
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadRecords");
      dispatch("resetFilters");
    },
    loadRecords({ commit }) {
      // let channels = apiService.channels.getAll();
      let channels = [];
      commit("SAVE_RECORDS", channels);
    },
    setSelected({ commit }, record) {
      commit("SET_SELECTED", record);
    },
    resetSelected({ commit }) {
      commit("RESET_SELECTED");
    },
    setFilter({ commit }, data) {
      commit("SET_FILTER", data);
    },
    resetFilter({ commit }, filter) {
      commit("RESET_FILTER", filter);
    },
    setFilters({ commit }, filters) {
      commit("SET_FILTERS", filters);
    },
    resetFilters({ commit }) {
      commit("RESET_FILTERS");
    },
  },
  mutations: {
    SAVE_RECORDS(state, records) {
      state.records = records;
    },
    SET_SELECTED(state, record) {
      state.record = record;
    },
    RESET_SELECTED(state) {
      state.record = {};
    },
    SET_FILTER(state, data) {
      let filterName = data[0];
      state.filters[filterName] = data[1];
    },
    RESET_FILTER(state, filter) {
      state.filters[filter] = null;
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    RESET_FILTERS(state) {
      state.filters = {};
    },
  },
};
