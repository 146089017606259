import apiService from "@p/apiService";
import snackbars from "@p/events/snackbars";
import productEvents from "@products/events";

import { Product } from "@/models/product";

import { ProductTypes } from "./modules/types";
import { Pricing } from "./modules/pricing";
import { Pod } from "./modules/pod";
import { Images } from "./modules/images";
import { Warehouses } from "./modules/warehouses";
import { Import } from "./modules/import";

export const Products = {
  namespaced: true,
  state: {
    records: [],
    recordsLoaded: false,
    recordsMeta: {},

    record: {},
    recordLoaded: false,
    recordModalType: null,

    filters: null,

    tableHeaders: [
      // DISABLED FOR TIME-BEING
      // {text: "Image", value: "image", width: "110px"},
      { text: "Product Title", value: "name" },
      { text: "SKU", value: "sku" },
      { text: "ID", value: "gtin" },
      { text: "Type", value: "type" },
      { text: "Production", value: "production" },
      { text: "Status", value: "status", align: "center" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    statusColours: {
      "pre-order": "warning",
      active: "primary",
      inactive: "grey",
    },

    tariffCodes: [],
  },
  getters: {
    initialised: (state) => state.recordsLoaded,
    all: (state) => state.records,
    metadata: (state) => state.recordsMeta,
    byId: (state) => (id) => state.records.find((record) => record.uuid === id),
    tableHeaders: (state) => state.tableHeaders,
    filters: (state) => state.filters,
    record: (state) => state.record,
    recordModalType: (state) => state.recordModalType,
    statusColours: (state) => state.statusColours,
    tariffCodes: (state) => state.tariffCodes,
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadRecords");
      dispatch("pricing/initialise");
      dispatch("pod/initialise");
      dispatch("productTypes/initialise");
      dispatch("loadTariffCodes");
    },
    loadRecords({ commit }, opts) {
      apiService.callApi("products", "getProducts", opts).then((res) => {
        commit("SAVE_METADATA", JSON.parse(JSON.stringify(res.meta)));
        commit("SAVE_RECORDS", JSON.parse(JSON.stringify(res.data)));
      });
    },
    loadRecordByUuid({ commit }, uuid) {
      apiService.callApi("products", "getProduct", uuid).then((res) => {
        commit("ADD_RECORD", res.data);
      });
    },
    loadTariffCodes({ commit }, filters = {}) {
      apiService.callApi("general", "getTariffCodes", filters).then((res) => {
        commit("SAVE_TARIFF_CODES", res.data);
      });
    },
    create({ dispatch }, productData) {
      apiService
        .callApi("products", "createProduct", productData)
        .then((response) => {
          dispatch("initialise");
          productEvents.$emit("product-created", response.data);
        });
    },
    deleteProduct({ dispatch }, uuid) {
      apiService.callApi("products", "deleteProduct", uuid).then(() => {
        snackbars.$emit("snackbar", {
          message: "Product deleted.",
        });
        dispatch("initialise");
      });
    },
    setSelected({ commit }, record) {
      commit("SET_SELECTED", record);
    },
    resetSelected({ commit }) {
      commit("RESET_SELECTED");
    },
    setFilter({ commit }, data) {
      commit("SET_FILTER", data);
    },
    resetFilter({ commit }, filter) {
      commit("RESET_FILTER", filter);
    },
    setFilters({ commit }, filters) {
      commit("SET_FILTERS", filters);
    },
    resetFilters({ commit }) {
      commit("RESET_FILTERS");
    },
    copyRecord({ commit }, record) {
      commit("ADD_RECORD", record);
    },
    update({ commit }, recordData) {
      commit("UPDATE_RECORD", recordData);
    },
    setWarehouseEnabled({ commit }, data) {
      commit("SET_WAREHOUSE_FOR_PRODUCT", data);
    },
    setPodEnabled({ commit }, data) {
      commit("SET_POD_FOR_PRODUCT", data);
    },
    showModal({ commit }, data) {
      commit("SET_MODAL_DATA", data);
    },
    resetModal({ commit }) {
      commit("RESET_MODAL_DATA");
    },
    setStatus({ dispatch }, data) {
      let uuid = data[0];
      let status = data[1];

      switch (status) {
        case "active":
          apiService.callApi("products", "setActive", uuid).finally(() => {
            dispatch("loadRecords");
          });
          break;
        case "inactive":
          apiService.callApi("products", "setInactive", uuid).finally(() => {
            dispatch("loadRecords");
          });
          break;
        case "pre-order":
          apiService.callApi("products", "setPreOrder", uuid).finally(() => {
            dispatch("loadRecords");
          });
          break;
        default:
          break;
      }
    },
  },
  mutations: {
    SAVE_RECORDS(state, records) {
      state.records = records.map((record) => new Product(record));
    },
    SAVE_METADATA(state, metadata) {
      state.recordsMeta = metadata;
    },
    ADD_RECORD(state, record) {
      state.records.push(record);
    },
    SET_SELECTED(state, record) {
      state.record = record;
    },
    RESET_SELECTED(state) {
      state.record = {};
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    RESET_FILTER(state, { filter }) {
      state.filters[filter] = null;
    },
    RESET_FILTERS(state) {
      state.filters = {
        search: "",
        sku: "",
        productionMethod: "",
        productionMethods: ["PoD", "Warehoused", "PoD and Warehoused"],
      };
    },
    UPDATE_RECORD(state, recordData) {
      const productId = recordData[0];
      const newProductData = recordData[1];

      apiService
        .callApi("products", "updateProduct", productId, newProductData)
        .then(() => {
          this.dispatch("products/loadRecords");
          snackbars.$emit("snackbar", {
            message: "Product updated.",
          });
          productEvents.$emit("product-successfully-updated");
        });
    },
    SET_WAREHOUSE_FOR_PRODUCT(state, data) {
      let products = state.records;
      const productUuid = data[0];
      const setWarehouseEnabled = data[1];

      let uuids = products.map((product) => product.uuid);
      let indexOfProduct = uuids.indexOf(productUuid);

      let customs = products[indexOfProduct].customs;
      if (!Array.isArray(customs)) {
        products[indexOfProduct].customs = [];
      }

      products[indexOfProduct].warehouseEnabled = setWarehouseEnabled;
      apiService
        .callApi("products", "updateProduct", data[0], products[indexOfProduct])
        .then(() => {
          state.records = products;
        });
    },
    SET_POD_FOR_PRODUCT(state, data) {
      let products = state.records;
      const productUuid = data[0];
      const setPodEnabled = data[1];

      let uuids = products.map((product) => product.uuid);
      let indexOfProduct = uuids.indexOf(productUuid);

      let customs = products[indexOfProduct].customs;
      if (!Array.isArray(customs)) {
        products[indexOfProduct].customs = [];
      }

      products[indexOfProduct].podEnabled = setPodEnabled;
      apiService
        .callApi("products", "updateProduct", data[0], products[indexOfProduct])
        .then(() => {
          state.records = products;
        });
    },
    SET_MODAL_DATA(state, data) {
      let modalType = data[0];
      let modalData = data[1];
      state.recordModalType = modalType;
      state.record = modalData;
    },
    RESET_MODAL_DATA(state) {
      state.recordModalType = null;
      state.record = {};
    },
    SAVE_TARIFF_CODES(state, data) {
      state.tariffCodes = data;
    },
  },
  modules: {
    images: Images,
    import: Import,
    pricing: Pricing,
    productTypes: ProductTypes,
    pod: Pod,
    warehouses: Warehouses,
  },
};
