const Billing = () => import("@settings/views/billing/Billing");
const Channels = () => import("@settings/views/channels/All");
const Team = () => import("@settings/views/teams/Team");
const Users = () => import("@settings/views/users/All");
const CreateChannel = () => import("@settings/views/channels/Create.vue");
const ChannelDetails = () => import("@settings/views/channels/Details");
const CreateUser = () => import("@settings/views/users/Create.vue");
const UserDetails = () => import("@settings/views/users/Details");
const Customs = () => import("@settings/views/customs/Customs");
const AddressBook = () => import("@settings/views/teams/AddressBook");
const TeamDetails = () => import("@settings/views/teams/Details");
const SKUMapping = () => import("@settings/views/channels/SKUMapping.vue");
const Notifications = () =>
  import("@settings/views/notifications/Notifications");

// SETTINGS
const routes = [
  {
    path: "settings",
    component: {
      render(component) {
        return component("router-view");
      },
    },
    meta: {
      breadcrumb: "Settings",
      title: "Settings",
    },
    children: [
      {
        path: "",
        name: "settings",
        redirect: { name: "settings_channels" },
        meta: {
          title: "Settings",
        },
      },
      {
        path: "channels",
        component: {
          render(component) {
            return component("router-view");
          },
        },
        meta: {
          breadcrumb: "Channels",
          title: "Channels Settings",
        },
        children: [
          {
            path: "",
            name: "settings_channels",
            component: Channels,
            meta: {
              title: "Channels Settings",
            },
          },
          {
            path: "create",
            name: "settings_channels_create",
            component: CreateChannel,
            meta: {
              breadcrumb: "Create Channel",
              title: "Create Channel",
            },
          },
          {
            path: ":id/details",
            name: "settings_channels_details",
            component: ChannelDetails,
            meta: {
              breadcrumb: "Channel details",
              title: "Channel Details",
            },
          },
          // {
          //     path: 'skumapping',
          //     name: 'sku-mapping',
          //     component: SKUMapping,
          //     meta: {
          //         breadcrumb: 'SKU Mapping',
          //         title: 'SKU Mapping'
          //     }
          // },
        ],
      },
      {
        path: "users",
        component: {
          render(component) {
            return component("router-view");
          },
        },
        meta: {
          breadcrumb: "Users",
          title: "Users Settings",
        },
        children: [
          {
            path: "",
            name: "settings_users",
            component: Users,
            meta: {
              title: "User Settings",
            },
          },
          {
            path: "create",
            name: "settings_users_create",
            component: CreateUser,
            meta: {
              breadcrumb: "Invite new user",
              title: "Invite New User",
            },
          },
          {
            path: ":id/details",
            name: "settings_user_details",
            component: UserDetails,
            meta: {
              breadcrumb: "User details",
              title: "User Details",
            },
          },
        ],
      },
      {
        path: "team",
        component: {
          render(component) {
            return component("router-view");
          },
        },
        meta: {
          breadcrumb: "Team",
          requiredPermission: "",
          title: "Team Settings",
        },
        children: [
          {
            path: "",
            name: "settings_team",
            component: Team,
            meta: {
              title: "Team Settings",
            },
          },
          {
            path: ":id/details",
            name: "settings_team_details",
            component: TeamDetails,
            meta: {
              breadcrumb: "Team Details",
              title: "Team Details",
            },
          },
        ],
      },
      {
        path: "address",
        component: {
          render(component) {
            return component("router-view");
          },
        },
        meta: {
          breadcrumb: "Address Book",
          title: "Address Book",
        },
        children: [
          {
            path: "",
            name: "settings_team_address_book",
            component: AddressBook,
            meta: {
              title: "Address Book",
            },
          },
        ],
      },
      {
        path: "billing",
        component: {
          render(component) {
            return component("router-view");
          },
        },
        meta: {
          title: "Billing Settings",
          breadcrumb: "Billing",
        },
        children: [
          {
            path: "",
            name: "settings_billing",
            component: Billing,
            meta: {
              title: "Billing Settings",
            },
          },
        ],
      },
      {
        path: "customs",
        component: {
          render(component) {
            return component("router-view");
          },
        },
        meta: {
          title: "Customs Settings",
          breadcrumb: "Customs",
        },
        children: [
          {
            path: "",
            name: "settings_customs",
            component: Customs,
            meta: {
              title: "Customs Settings",
            },
          },
        ],
      },
      {
        path: "notifications",
        component: {
          render(component) {
            return component("router-view");
          },
        },
        meta: {
          title: "Notifications Settings",
          breadcrumb: "Notifications",
        },
        children: [
          {
            path: "",
            name: "settings_notifications",
            component: Notifications,
            meta: {
              title: "Notifications Settings",
            },
          },
        ],
      },
    ],
  },
];

export default routes;
