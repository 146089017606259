const Suppliers = () => import("@admin/views/suppliers/All");
const ShippingZones = () => import("@admin/views/shipping/All");
const ChannelTypes = () => import("@admin/views/channelTypes/All");
const Teams = () => import("@admin/views/teams/All");
const TeamDetails = () => import("@admin/views/teams/Details");
const TeamsFulfilmentsAll = () => import("@admin/views/fulfilments/All");
const CreateTeam = () => import("@admin/views/teams/Create");
const AdminUsers = () => import("@admin/views/users/All");
const AdminUserCreate = () => import("@admin/views/users/Create");
const PodProductTypes = () => import("@admin/views/products/PodProductTypes");
const PodProductTypeDetails = () =>
  import("@admin/views/products/PodProductTypeDetails");
const AdminUserDetails = () => import("@admin/views/users/Details");
const podReview = () => import("@admin/views/pod-review/podReview");

const CreateSupplier = () =>
  import("@products/components/suppliers/create/Form");
const SupplierDetails = () =>
  import("@products/components/suppliers/details/Details");
const CreateShippingZone = () =>
  import("@products/components/shippingZones/create/Form");
const ShippingDetails = () =>
  import("@products/components/shippingZones/details/Details");
const CreateLocation = () =>
  import("@products/components/locations/create/Form");
const LocationDetails = () =>
  import("@products/components/locations/details/Details");

// ADMIN
const routes = [
  {
    path: "/admin",
    component: {
      render(component) {
        return component("router-view");
      },
    },
    meta: {
      breadcrumb: "Admin",
      title: "Admin",
    },
    children: [
      {
        path: "",
        name: "admin",
        redirect: { name: "admin_teams" },
        meta: {
          breadcrumb: "Admin",
          title: "Admin",
        },
      },
      {
        path: "teams",
        component: {
          render(component) {
            return component("router-view");
          },
        },
        meta: {
          breadcrumb: "Teams",
          title: "Teams Admin",
        },
        children: [
          {
            path: "",
            name: "admin_teams",
            component: Teams,
            meta: {
              title: "Teams Admin",
            },
          },
          {
            path: "create",
            name: "admin_team_create",
            component: CreateTeam,
            meta: {
              breadcrumb: "Create Team",
              title: "Create Team",
            },
          },
          {
            path: ":id/details",
            name: "admin_team_details",
            component: TeamDetails,
            meta: {
              breadcrumb: "Team Details",
              title: "Team Details",
            },
          },
        ],
      },
      {
        path: "locations",
        component: {
          render(component) {
            return component("router-view");
          },
        },
        meta: {
          breadcrumb: "Fulfulment Locations",
          title: "Fulfulment Locations Admin",
        },
        children: [
          {
            path: "",
            name: "admin_fulfilment_locations",
            component: Suppliers,
            meta: {
              title: "Fulfulment Locations Admin",
            },
          },
          {
            path: "fulfilment-location/create",
            name: "admin_location_create",
            component: CreateLocation,
            meta: {
              breadcrumb: "Create Fulfilment Location",
              admin: "Create Fulfilment Location",
            },
          },
          {
            path: "location/:id/details",
            name: "admin_location_details",
            component: LocationDetails,
            meta: {
              breadcrumb: "Fulfilment Location Details",
              title: "Fulfilment Location Details",
            },
          },
        ],
      },
      {
        path: "shipping-zones",
        component: {
          render(component) {
            return component("router-view");
          },
        },
        meta: {
          breadcrumb: "Shipping Zones",
          title: "Shipping Zones",
        },
        children: [
          {
            path: "",
            name: "admin_shipping_zones",
            component: ShippingZones,
            meta: {
              title: "Shipping Zones",
            },
          },
          {
            path: "create",
            name: "admin_shipping_zone_create",
            component: CreateShippingZone,
            meta: {
              breadcrumb: "Create Shipping Zone",
              title: "Create Shipping Zone",
            },
          },
          {
            path: ":id/details",
            name: "admin_shipping_zone_details",
            component: ShippingDetails,
            meta: {
              breadcrumb: "Shipping Zone Details",
              title: "Shipping Zone Details",
            },
          },
        ],
      },
      // {
      //     path: 'channel-types',
      //     component: {
      //         render(component) {
      //             return component('router-view');
      //         }
      //     },
      //     meta: {
      //         breadcrumb: 'Channel Types',
      //         title: 'Channel Types'
      //     },
      //     children: [
      //         {
      //             path: '',
      //             name: 'admin_channel_types',
      //             component: ChannelTypes,
      //             meta: {
      //                 title: 'Channel Types'
      //             }
      //         },
      //     ]
      // },
      {
        path: "fulfilments",
        component: {
          render(component) {
            return component("router-view");
          },
        },
        meta: {
          breadcrumb: "Fulfilments",
          title: "Fulfilments Admin",
        },
        children: [
          {
            path: "",
            name: "admin_fulfilments",
            component: TeamsFulfilmentsAll,
            meta: {
              title: "Fulfilments Admin",
            },
          },
        ],
      },
      {
        path: "users",
        component: {
          render(component) {
            return component("router-view");
          },
        },
        meta: {
          breadcrumb: "Users",
          title: "Users Admin",
        },
        children: [
          {
            path: "",
            name: "admin_users",
            component: AdminUsers,
            meta: {
              title: "Users Admin",
            },
          },
          // {
          //     path: 'create',
          //     component: AdminUserCreate,
          //     meta: {
          //         breadcrumb: 'Invite New User',
          //         title: 'Invite New User'
          //     },
          // },
          {
            path: ":id/details",
            name: "admin_user_details",
            component: AdminUserDetails,
            meta: {
              title: "Users Admin",
            },
          },
        ],
      },
      {
        path: "products",
        component: {
          render(component) {
            return component("router-view");
          },
        },
        meta: {
          breadcrumb: "Print on Demand Product Types",
          title: "PoD Product Types",
        },
        children: [
          {
            path: "",
            name: "admin_products",
            component: PodProductTypes,
            meta: {
              title: "PoD Product Types",
            },
          },
          {
            path: ":id/details",
            name: "admin_pod_product_type_details",
            component: PodProductTypeDetails,
            meta: {
              breadcrumb: "Details",
              title: "PoD Product Type Details",
            },
          },
        ],
      },
      {
        path: "pod-review",
        component: {
          render(component) {
            return component("router-view");
          },
        },
        meta: {
          breadcrumb: "PoD Review",
          title: "PoD Review",
        },
        children: [
          {
            path: "",
            name: "admin_pod_review",
            component: podReview,
            meta: {
              title: "PoD Review",
            },
          },
        ],
      },
    ],
  },
];

export default routes;
