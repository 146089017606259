import Orders from "@orders/api";
import TeamUser from "@teams/api";
import User from "@users/api";

import Products from "@products/api";
import PoD from "@products/api/pod";
import Warehouses from "@products/api/warehouse";
import Fulfilments from "@products/api/fulfilments";

import Media from "./apiServiceModules/media";
import Client from "./apiServiceModules/client";
import Import from "./apiServiceModules/import";
import General from "./apiServiceModules/general";
import Channels from "./apiServiceModules/channels";
import Invitations from "./apiServiceModules/invitations";

import axios from "axios";
import authEvents from "@p/events/auth";
import teamEvents from "@teams/events";
import snackbars from "@p/events/snackbars";

const PTApi = require("print_trail");
const baseURL = process.env.VUE_APP_PT_API_BASE_URL;

axios.defaults.baseURL = baseURL;
let defaultClient = PTApi.ApiClient.instance;
defaultClient.basePath = baseURL;

const noSnackbarRequests = {
  orders: ["createOrder", "updateOrder"],
  channels: [],
  client: [],
  general: [],
  import: [],
  invitations: [],
  media: [],
  pod: [],
  products: [],
  teamUser: [],
  user: [],
  warehouses: [],
};

const apiService = {
  requestOpts: {
    accept: "application/json",
    xClientUuid: null,
  },
  axiosHeaders: {
    "x-client-uuid": null,
    authorization: null,
  },
  getAxiosHeaders() {
    return this.axiosHeaders;
  },
  getRequestOpts() {
    return Object.assign({}, this.requestOpts);
  },
  setClientUuidHeader(clientUuid) {
    this.requestOpts["xClientUuid"] = clientUuid;
  },
  getClientUuidHeader() {
    return this.requestOpts["xClientUuid"];
  },

  callApi(module, request, ...args) {
    let self = this;
    return new Promise(function(resolve, reject) {
      self[module][request]
        .call(null, ...args)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(err) {
          let url = err.response.error.url ?? null;
          let code = err.response.statusCode ?? null;

          if (url.endsWith("active-clients/last") && code === 404) {
            reject(err);
          }

          if (code === 500) {
            snackbars.$emit("snackbar", {
              message: "Unknown server error occurred. Please try again later.",
              type: "error",
              colour: "red",
            });
            reject(err);
          }

          if (code === 422) {
            if (!noSnackbarRequests[module].includes(request)) {
              snackbars.$emit("snackbar", {
                errorMessage: err,
                type: "error",
                colour: "red",
              });
            }
            reject(err);
          }

          if (code === 404) {
            snackbars.$emit("snackbar", {
              errorMessage: err,
              type: "error",
              colour: "red",
            });
            reject(err);
          }

          if (code === 401 || code === 403) {
            snackbars.$emit("snackbar", {
              errorMessage: err,
              type: "error",
              colour: "red",
            });
            reject(err);
          }

          reject(err);
        });
    });
  },

  orders: Orders,
  channels: Channels,
  client: Client,
  fulfilments: Fulfilments,
  general: General,
  import: Import,
  invitations: Invitations,
  media: Media,
  pod: PoD,
  products: Products,
  teamUser: TeamUser,
  user: User,
  warehouses: Warehouses,
};

export default apiService;

authEvents.$on("set-authentication-header", (token) => {
  defaultClient.authentications["bearerAuth"].accessToken = token;
  apiService.axiosHeaders.authorization = `bearer ${token}`;
});

teamEvents.$on("changed-active-team", (team) => {
  apiService.requestOpts.xClientUuid = team.uuid;
  apiService.axiosHeaders["x-client-uuid"] = team.uuid;
});
