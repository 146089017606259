export const Pricing = {
  namespaced: true,
  state: {
    records: [],
    recordsLoaded: false,

    currencies: [],

    record: {},
    recordLoaded: false,

    filters: null,
  },
  getters: {
    all: (state) => {
      return state.records;
    },
    byProductId: (state) => (productId) => {
      return state.records.find((record) => record.productId === productId);
    },
    allCurrencies: (state) => {
      return state.currencies;
    },
    currencyById: (state) => (id) => {
      return state.currencies.find((record) => record.id === id);
    },
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadRecords");
      dispatch("loadCurrencies");
    },
    loadRecords({ commit }) {
      let prices = [];
      commit("SAVE_RECORDS", prices);
    },
    loadCurrencies({ commit }) {
      let currencies = [];
      commit("SAVE_CURRENCIES", currencies);
    },
    setSelected({ commit }, record) {
      commit("SET_SELECTED", record);
    },
    resetSelected({ commit }) {
      commit("RESET_SELECTED");
    },
    update({ commit }, recordData) {
      commit("UPDATE_RECORD", recordData);
    },
    addCurrencyLine({ commit }, productId) {
      commit("ADD_CURRENCY_LINE", productId);
    },
  },
  mutations: {
    SAVE_RECORDS(state, records) {
      state.records = records;
    },
    SAVE_CURRENCIES(state, currencies) {
      state.currencies = currencies;
    },
    ADD_RECORD(state, record) {
      state.records.push(record);
    },
    SET_SELECTED(state, record) {
      state.record = record;
    },
    RESET_SELECTED(state) {
      state.record = {};
    },
    UPDATE_RECORD(state, recordData) {
      let prices = state.records;

      const priceId = parseInt(recordData[0]);
      const newPriceData = recordData[1];

      let ids = prices.map((price) => price.id);
      let index = ids.indexOf(priceId);

      let dataAsArrays = Object.entries(newPriceData);
      for (const [key, value] of dataAsArrays) {
        prices[index][key] = value;
        // TODO: Send prices[index] to the API for updates in the backend.
      }

      state.records = prices;
    },
    ADD_CURRENCY_LINE(state, productId) {
      let pricesForProduct = state.records.find(
        (record) => record.productId === productId
      );
      pricesForProduct.prices.push({ currency: 1, amount: 0 });
    },
  },
  modules: {},
};
