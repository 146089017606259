import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/scss/main.sass";

Vue.use(Vuetify);

const theme = {
  options: {
    customProperties: true,
  },
  themes: {
    light: {
      primary: "#37BFA7",
      secondary: "#0067B1",
      accent: "#FF5A59",
      navlink: "#F4F8F9",
      navlinkactive: "#FFFFFF",
      background: "#F4F6F6",
    },
    dark: {
      primary: "#37BFA7",
      secondary: "#0067B1",
      accent: "#FF5A59",
      navlink: "#F4F8F9",
      navlinkactive: "#FFFFFF",
    },
  },
};

if (process.env.VUE_APP_ENV === "staging") {
  theme.themes.light.primary = "#bf3737";
}

if (process.env.VUE_APP_ENV === "local") {
  theme.themes.light.primary = "#4e1c8f";
}

export default new Vuetify({
  icons: {
    iconfont: "fa",
  },
  theme: theme,
});
