const reportsNavItems = [
  {
    text: "All Reports",
    route: "/reports/",
    icon: "fas fa-chart-bar",
    createIcon: "",
    requiredPermission: "",
  },
];

export default reportsNavItems;
