var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"muli-font",staticStyle:{"font-family":"'Lato', sans-serif !important"}},[_c('link',{attrs:{"href":"https://fonts.googleapis.com/icon?family=Material+Icons","rel":"stylesheet"}}),_c('link',{attrs:{"href":"https://use.fontawesome.com/releases/v5.1.0/css/all.css","rel":"stylesheet"}}),(_vm.showLoadingSpinner)?_c('div',{staticClass:"spinner-parent"},[_c('div',{staticClass:"spinner-content"},[_c('div',{staticClass:"loader-wrapper"},[_c('p',{staticClass:"ml-n12 mb-16"},[_c('v-img',{attrs:{"src":"/pt-logo.svg","width":"200px"}})],1),_c('div',{staticClass:"loader"})])])]):_vm._e(),_c('snackbar'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('desktop-navigation',{attrs:{"navigation":_vm.navigation,"tabs":_vm.tabs}}):_c('mobile-navigation',{attrs:{"mobileDrawer":_vm.mobileDrawer,"navigation":_vm.navigation,"tabs":_vm.tabs},on:{"toggle-mobile-drawer":_vm.toggleMobileDrawer}}),(_vm.showDialog)?_c('confirm-dialog'):_vm._e(),_c('inactivity-dialog',{attrs:{"dialog":_vm.showInactivityDialog}}),_c('v-dialog',{attrs:{"width":"750"},model:{value:(_vm.showErrorsDialog),callback:function ($$v) {_vm.showErrorsDialog=$$v},expression:"showErrorsDialog"}},[_c('errors-dialog')],1),(!_vm.showLoadingSpinner)?_c('v-main',{staticClass:"consil-bg__light"},[(
        _vm.$route.path.includes('/settings') &&
        _vm.$isAuthenticated() &&
        _vm.$isTeamSelected()
      )?_c('side-menu',{attrs:{"navigation":_vm.navigation,"settingsDrawer":_vm.settingsDrawer,"tabs":_vm.tabs}}):_vm._e(),(
        _vm.$route.path.includes('/admin') &&
        _vm.$isAuthenticated() &&
        _vm.$isTeamSelected()
      )?_c('admin-side-menu',{attrs:{"adminDrawer":_vm.adminDrawer,"navigation":_vm.navigation,"tabs":_vm.tabs}}):_vm._e(),(_vm.$route.path.includes('/profile') && _vm.$isAuthenticated())?_c('profile-side-menu',{attrs:{"navigation":_vm.navigation,"profileDrawer":_vm.profileDrawer,"tabs":_vm.tabs}}):_vm._e(),_c('global-alerts'),_c('v-breadcrumbs',{attrs:{"items":_vm.breadCrumbs,"divider":"/"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('v-breadcrumbs-item',{staticClass:"grey--text lighten-1",on:{"mouseenter":function($event){return $event.target.classList.add('primary--text')},"mouseleave":function($event){return $event.target.classList.remove('primary--text')}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('router-link',{staticClass:"breadcrumb-link text-decoration-none",staticStyle:{"color":"inherit !important"},attrs:{"to":item.to}},[_vm._v(" "+_vm._s(item.text)+" ")])]},proxy:true}],null,true)})]}}],null,false,4242399347)}),(_vm.websocketsConnected)?_c('chat'):_vm._e(),_c('router-view',{on:{"toggle-admin-drawer":_vm.toggleAdminDrawer,"toggle-profile-drawer":_vm.toggleProfileDrawer,"toggle-settings-drawer":_vm.toggleSettingsDrawer}})],1):(!_vm.$isTeamSelected())?_c('v-main',[(_vm.$route.path.includes('/profile') && _vm.$isAuthenticated())?_c('profile-side-menu',{attrs:{"navigation":_vm.navigation,"profileDrawer":_vm.profileDrawer,"tabs":_vm.tabs}}):_vm._e(),(_vm.$route.path.split('/')[1] === 'profile')?_c('router-view',{on:{"toggle-admin-drawer":_vm.toggleAdminDrawer,"toggle-profile-drawer":_vm.toggleProfileDrawer,"toggle-settings-drawer":_vm.toggleSettingsDrawer}}):_c('no-team-selected')],1):(!_vm.$isAuthenticated())?_c('v-main',{staticClass:"consil-bg__light"},[_c('unauthenticated')],1):_vm._e(),(_vm.$route.path.split('/')[1] === 'profile')?_c('div',{staticStyle:{"position":"fixed","top":"93vh","left":"0.9rem","z-index":"99999"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleProfileDrawer.apply(null, arguments)}}},on),[(_vm.profileDrawer)?_c('v-icon',{staticStyle:{"font-size":"1rem"}},[_vm._v("fas fa-times")]):_c('v-icon',{staticStyle:{"font-size":"1rem"}},[_vm._v("fas fa-bars")])],1)]}}],null,false,1493509755)},[_c('span',[_vm._v("Toggle Menu")])])],1):_vm._e(),_c('v-footer',{staticClass:"white mt-8",staticStyle:{"background-color":"#f4f6f6 !important"},attrs:{"padless":""}},[_c('v-col',{staticClass:"text-center",staticStyle:{"font-size":"0.8em"},attrs:{"cols":"12"}},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"https://docs.printtrail.com/","target":"_blank"}},[_vm._v("User Guide")]),_c('span',{staticClass:"mx-2 grey--text"},[_vm._v("|")]),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"https://printtrail.com/terms-and-conditions/","target":"_blank"}},[_vm._v("Terms & Conditions")]),_c('span',{staticClass:"mx-2 grey--text"},[_vm._v("|")]),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"https://printtrail.com/privacy-policy/","target":"_blank"}},[_vm._v("Privacy Policy")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }