import store from "../store";

export class ShippingData {
  properties = {
    firstName: String,
    lastName: String,
    companyName: String,
    country: String,
    postcode: String,
    address1: String,
    address2: String,
    city: String,
    state: String,
    note: String,
    phone: String,
    mobile: String,
    email: String,
  };

  constructor(data) {
    let shippingData = this;
    for (const [key, dataType] of Object.entries(this.properties)) {
      switch (dataType) {
        case String:
          shippingData[key] = data[key] ?? null;
          break;
        default:
          shippingData[key] = data[key] ?? null;
          break;
      }
    }
  }
}
