import apiService from "@p/apiService";

import { InventoryLocation } from "@/models/inventoryLocation";
import { ShippingZone } from "@/models/shippingZone";
import { SupplierLocation } from "@/models/supplierLocation";

export const Warehouses = {
  namespaced: true,
  state: {
    productWarehouses: [],
    productWarehousesLoaded: false,

    shippingZones: [],
    shippingZonesLoaded: false,
    shippingZonesMeta: {},

    supplierLocations: [],
    supplierLocationsLoaded: false,
  },
  getters: {
    productWarehouses: (state) => {
      return state.productWarehouses;
    },
    shippingZones: (state) => {
      return state.shippingZones;
    },
    shippingZonesMeta: (state) => {
      return state.shippingZonesMeta;
    },
    supplierLocations: (state) => {
      return state.supplierLocations;
    },
  },
  actions: {
    initialise({ dispatch }) {
      dispatch("loadShippingZones", [null, null]);
      // dispatch("loadSupplierLocations", [null, null]);
    },
    loadProductWarehouses({ commit }, productUuid) {
      apiService
        .callApi("warehouses", "getWarehouses", productUuid)
        .then((response) => {
          return response.data.sort((a,b) => a.priority - b.priority)
        })
        .then((sortedList) => {
          commit("SAVE_WAREHOUSES", sortedList);
        });
    },
    loadShippingZones({ commit }, options) {
      apiService
        .callApi("warehouses", "shippingZones", options)
        .then((response) => {
          commit("SAVE_SHIPPING_ZONES", response.data);
          commit("SAVE_SHIPPING_ZONES_META", response.meta);
        });
    },
    loadSupplierLocations({ commit }, payload) {
      let productUuid = payload[0];
      let options = payload[1];

      apiService
        .callApi("warehouses", "supplierLocations", productUuid, options)
        .then((response) => {
          commit("SAVE_SUPPLIER_LOCATIONS", response.data);
        });
    },
    saveWarehouse({ dispatch }, data) {
      apiService
        .callApi("warehouses", "syncWarehouse", data[0], data[1])
        .finally(() => {
          dispatch("loadProductWarehouses", data[0]);
        });
    },
    saveWarehouses({ dispatch }, data) {
      apiService
        .callApi("warehouses", "syncWarehouse", data[0], data[1])
        .finally(() => {
          dispatch("loadProductWarehouses", data[0]);
        });
    },
  },
  mutations: {
    SAVE_WAREHOUSES(state, productWarehouses) {
      state.productWarehouses = productWarehouses.map(
        (warehouse) => new InventoryLocation(warehouse)
      );
      state.productWarehousesLoaded = true;
    },
    SAVE_SHIPPING_ZONES(state, shippingZones) {
      shippingZones = shippingZones.map(
        (shippingZone) => new ShippingZone(shippingZone)
      );
      let currentRecords = state.shippingZones;
      let merged = currentRecords.concat(shippingZones);
      merged = merged.filter((item, index) => {
        return merged.indexOf(item) === index;
      });
      state.shippingZones = merged;
      state.shippingZonesLoaded = true;
    },
    SAVE_SHIPPING_ZONES_META(state, metadata) {
      state.shippingZonesMeta = metadata;
    },
    SAVE_SUPPLIER_LOCATIONS(state, supplierLocations) {
      supplierLocations = supplierLocations.map(
        (supplierLocation) => new SupplierLocation(supplierLocation)
      );
      let currentRecords = state.supplierLocations;
      let merged = currentRecords.concat(supplierLocations);
      merged = merged.filter((item, index) => {
        return merged.indexOf(item) === index;
      });
      state.supplierLocations = merged;
      state.supplierLocationsLoaded = true;
    },
  },
  modules: {},
};
